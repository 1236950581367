import { useMemo, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton, Tooltip } from '@mui/material'
import { observer } from 'mobx-react-lite'

import {
  AcAvatar,
  AcBox,
  AcColumn,
  AcCustomerSettingsDropdown,
  AcCustomerSettingsMenu,
  AcCustomerSettingsModal,
  AcIconButton,
  AcTypography,
} from '@components'
import { useBreakpoints } from '@hooks'
import { useStore } from '@hooks'

export const AcCustomerSettings = observer(() => {
  const { user } = useStore()
  const { isMobile, isDesktop } = useBreakpoints()
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)

  const handleOpenAction = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchor(null)
  }

  const MenuRootEl = useMemo(() => {
    return isMobile ? AcCustomerSettingsModal : AcCustomerSettingsDropdown
  }, [isMobile])

  const renderDesktopElements = useMemo(() => {
    if (!isDesktop) return null
    return (
      <>
        <AcColumn gap={0}>
          <AcTypography
            size="sm"
            bold
            component="span">
            {user.current?.email}
          </AcTypography>
          <AcTypography
            size="sm"
            component="span">
            {user.user?.customer?.name}
          </AcTypography>
        </AcColumn>
        <ExpandMoreIcon
          style={{
            transform: anchor ? `rotate(180deg)` : undefined,
            transition: 'all 350ms ease',
          }}
        />
      </>
    )
  }, [isDesktop, user.current, anchor])

  if (!user.current) return null

  return (
    <AcBox
      component={'div'}
      onClick={handleOpenAction}
      display="flex"
      alignItems="center"
      flexGrow={0}
      gap={4}>
      {isMobile ? (
        <AcIconButton onClick={handleOpenAction}>
          <AcAvatar
            src={'.'}
            alt={user?.current?.first_name}
          />
        </AcIconButton>
      ) : (
        <Tooltip title="Open settings">
          <IconButton
            tabIndex={-1}
            sx={{ padding: 0 }}>
            <AcAvatar
              src={'.'}
              alt={user?.current?.first_name}
            />
          </IconButton>
        </Tooltip>
      )}
      <MenuRootEl
        open={anchor}
        handleClose={handleCloseUserMenu}>
        <AcCustomerSettingsMenu handleClose={handleCloseUserMenu} />
      </MenuRootEl>
      {renderDesktopElements}
    </AcBox>
  )
})
