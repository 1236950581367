import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcCard,
  AcFormInput,
  AcFormProvider,
  AcRadioGroup,
  AcRow,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useBreakpoints } from '@hooks'
import { useStore } from '@hooks'
import { IProductRating, ISimpleProduct } from '@typings'

interface IAcRateProductForm {
  product: ISimpleProduct
  updateRating?: (rating: IProductRating['value']) => void
}

const RATING_OPTIONS = [
  {
    id: 1,
    title: LABELS.RATE_PRODUCT_SUPER_TASTY,
    label: LABELS.RATE_PRODUCT_SUPER_TASTY_EXT,
    value: 6,
  },
  {
    id: 2,
    title: LABELS.RATE_PRODUCT_TASTY,
    label: LABELS.RATE_PRODUCT_TASTY_EXT,
    value: 5,
  },
  {
    id: 3,
    title: LABELS.RATE_PRODUCT_NEUTRAL,
    label: LABELS.RATE_PRODUCT_NEUTRAL_EXT,
    value: 3,
  },
  {
    id: 3,
    title: LABELS.RATE_PRODUCT_LESS,
    label: LABELS.RATE_PRODUCT_LESS_EXT,
    value: 1,
  },
  {
    id: 4,
    title: LABELS.RATE_PRODUCT_EXCLUDE,
    label: LABELS.RATE_PRODUCT_EXCLUDE_EXT,
    value: 0,
  },
]

export const AcRateProductForm = observer(
  ({ product, updateRating }: IAcRateProductForm) => {
    const { toaster, products, dialog } = useStore()
    const { isMobile } = useBreakpoints()
    const initial = {
      preference: null,
    }

    const onSubmit = async (data: any) => {
      if (!data.preference) {
        toaster.error({
          title: 'Let op',
          content: 'Selecteer een beoordeling voor het product',
        })
        return
      }

      try {
        const res = await products.setProductRating(product.id, data.preference)
        if (updateRating) updateRating(res.rating)
        toaster.success({
          title: 'Product beoordeeld',
          content: 'Bedankt voor je feedback',
        })
        dialog.close()
      } catch (e) {
        toaster.error({
          content: 'Er is iets misgegaan bij het beoordelen van het product',
        })
      }
    }

    return (
      <AcFormProvider
        initial={initial}
        onSubmit={onSubmit}
        flexGrow={1}>
        <AcCard
          cardColor="beige"
          sx={{ marginBottom: 5 }}>
          <AcTypography
            component="span"
            bold>
            {product.name}
          </AcTypography>
        </AcCard>
        <AcFormInput
          register="preference"
          Component={
            <AcRadioGroup
              fullWidth
              id="luncher-preferences"
              options={RATING_OPTIONS}
            />
          }
        />
        <AcRow
          alignItems="center"
          justifyContent="space-between"
          marginTop={isMobile ? 'auto' : 2}>
          <AcButton
            type="button"
            onClick={dialog.close}
            variant="text">
            {LABELS.CANCEL}
          </AcButton>
          <AcButton
            loading={products.productRatingLoading}
            type="submit">
            {LABELS.CONFIRM}
          </AcButton>
        </AcRow>
      </AcFormProvider>
    )
  },
)
