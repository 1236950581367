import { useState } from 'react'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import { AcButton, AcColumn, AcRow, AcTextarea } from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks'

import { TOASTERS } from '../../../constants/toasters.constants'

export const AcLunchReminderForm = observer(() => {
  const { dialog, toaster } = useStore()

  const [message, setMessage] = useState(
    'We willen je graag herinneren aan de lunch',
  )
  const [errorMessage, setErrorMessage] = useState('')

  const handleShare = async () => {
    if (!message) {
      setErrorMessage(LABELS.MESSAGE_REQUIRED)
      return
    }
    const shareUrl = `${window.location.origin}${NAVIGATE_TO.LUNCHER_OVERVIEW}`
    if (navigator?.share) {
      await navigator
        .share({
          text: message,
          url: shareUrl,
        })
        .then(() => toaster.success(TOASTERS.SHARE_SUCCESSFUL))
        .catch(error => {
          navigator.clipboard.writeText(`${message}: ${shareUrl}`)
          toaster.success(TOASTERS.COPIED_TO_CLIPBOARD)
        })
    } else {
      await navigator.clipboard
        .writeText(`${message}: ${shareUrl}`)
        .then(() => toaster.success(TOASTERS.COPIED_TO_CLIPBOARD))
    }
  }

  return (
    <>
      <AcColumn gap={4}>
        <AcTextarea
          error={errorMessage}
          label={LABELS.MESSAGE_TO_ALL_LUNCHERS}
          value={message}
          required
          onChange={event => setMessage(event.target.value)}
        />

        <AcRow
          justifyContent="space-between"
          alignItems="center">
          <AcButton
            onClick={() => dialog.close()}
            variant="text">
            {LABELS.CANCEL}
          </AcButton>
          <AcButton onClick={handleShare}>{LABELS.SHARE_MESSAGE}</AcButton>
        </AcRow>
      </AcColumn>
    </>
  )
})
