export const LABELS = {
  LUNCHER: 'Luncher',
  OFFICE_MANAGER: 'Office manager',
  'OFFICE-MANAGER': 'Office manager',
  'SUPER-ADMIN': 'Super admin',
  office_manager: 'Office manager',
  super_admin: 'Super admin',
  ADMIN: 'Administratie',
  SWITCH_ROLE: 'Verwissel van rol',
  USER_ROLES: 'Rollen',
  USER_ROLE: 'Rol',
  GOOD_MORNING: 'Goedemorgen',
  GOOD_AFTERNOON: 'Goedemiddag',
  GOOD_EVENING: 'Goedenavond',
  WHEN_WILL_YOU_ATTEND: 'Wanneer lunch je mee?',
  WHEN_WILL_YOU_ATTEND_SUBTITLE:
    'Geef door op welke dagen je meestal verwacht mee te lunchen.',
  DO_YOU_HAVE_ALLERGIES: 'Heb je allergieën?',
  DO_YOU_HAVE_ALLERGIES_SUBTITLE:
    'Zijn er nog andere dingen waar we rekening mee moeten houden?',
  WHAT_ARE_YOUR_PREFERENCES: 'Wat lunch jij het liefst?',
  PREFERENCES_MAY_CHANGE: 'Dit kan altijd nog wijzigen.',
  WHAT_ARE_YOUR_PREFERENCES_SUBTITLE:
    'Laat ons weten wat jij tijdens je lunch wilt eten.',
  PREFERENCES: 'Voorkeuren',
  PRFERENCES_EXTENDED: 'Voorkeuren (mix, vega, vegan)',
  CANCEL: 'Annuleren',
  SEND: 'Verstuur',
  SUBMIT: 'Verzenden',
  CONTINUE: 'Ga verder',
  VERIFIED: 'Geverifieerd',
  NEXT: 'Volgende',
  BACK: 'Terug',
  MIX: 'Mix',
  MIX_EXTENDED: 'Mix van vlees, vis en groente',
  MIX_EXPLAINER: 'Een mix van vlees, vis, vegetarische producten en zuivel',
  VEGETARIAN: 'Vegetarisch',
  VEGETARIAN_EXTENDED: 'Alleen vegetarisch',
  VEGETARIAN_EXPLAINER:
    'Veel vegetarische producten, zuivel en vleesvervangers',
  VEGAN: 'Veganistisch',
  VEGAN_SHORT: 'Vegan',
  VEGAN_EXTENDED: 'Alleen veganistisch',
  VEGAN_EXPLAINER: 'Uitsluitend plantaardige producten en vleesvervangers',
  ALLERGIES: 'Voedsel allergieën',
  ALLERGIES_NONE: 'Geen allergie',
  ALLERGIES_GLUTEN: 'Glutenallergie',
  ALLERGIES_NUTS: 'Notenallergie',
  ALLERGIES_PEANUTS: 'Pinda-allergie',
  ALLERGIES_FISH: 'Visallergie',
  ALLERGIS_LACTOSE: 'Lactosevrij',
  SAVED_ALLERGIES: 'Je allergieën zijn opgeslagen',
  SAVED_PREFERENCE: 'Je voorkeur is opgeslagen',
  SAVED_ATTENDANCE: 'Je aanwezigheid is bevestigd',
  UPDATED_ATTENDANCE: 'Je aanwezigheid is aangepast',
  SAVED_DEFAULT_ATTENDANCE: 'Je standaard lunchdagen zijn opgeslagen',
  OR: 'Of',
  ALLERGIES_NOT_LISTED: 'Andere allergie?',
  CONTACT_OFFICE_MANAGER:
    'Neem contact op met je office manager om dit aan te passen.',
  CONFIRM: 'Bevestigen',
  YOUR_LOGIN_DETAILS: 'Jouw inloggegevens',
  YOUR_LOGIN_DETAILS_TEXT: 'Log hier in met je e-mailadres en wachtwoord.',
  CREATE_ACCOUNT: 'Maak een account',
  WELCOME: 'Welkom!',
  REGISTER: 'Registreren',
  REGISTRATION_COMPLETED: 'Registratie voltooid',
  EMAIL_ADDRESS: 'E-mailadres',
  PASSWORD: 'Wachtwoord',
  CONFIRM_PASSWORD: 'Bevestig wachtwoord',
  SET_PASSWORD: 'Stel je wachtwoord in',
  CHANGE_PASSWORD: 'Wachtwoord wijzigen',
  CHANGE_PASSWORD_BUTTON: 'Wijzig wachtwoord',
  CHANGE_PASSWORD_TEXT: 'Stel een nieuw wachtwoord in.',
  PASSWORD_NO_MATCH: 'De wachtwoorden komen niet overeen',
  FORGOT_PASSWORD: 'Wachtwoord vergeten?',
  FORGOT_PASSWORD_TEXT:
    'We sturen een e-mail met link naar jouw e-mailadres. Hiermee kun je je wachtwoord opnieuw instellen.',
  REMEMBERED_PASSWORD: 'Ik weet mijn wachtwoord',
  BACK_TO_LOGIN: 'Terug naar inloggen',
  NO_ACCESS: 'Helaas geen toegang',
  NO_ACCESS_TEXT:
    'Je hebt helaas geen toegang meer tot De Buurtboer App. Neem contact op met jouw office manager.',
  CURRENT_PASSWORD: 'Huidig wachtwoord',
  NEW_PASSWORD: 'Nieuw wachtwoord',
  CONFIRM_NEW_PASSWORD: 'Bevestig nieuw wachtwoord',
  DEFAULT_ATTENDANCE: 'Standaard lunchdagen',
  YOUR_REGISTRATION_DETAILS_SUBTITLE:
    'Vertel ons je naam en het emailadres waarop je bereikbaar bent.',
  NAME: 'Naam',
  FIRST_NAME: 'Voornaam',
  MIDDLE_NAME: 'Tussenvoegsel',
  LAST_NAME: 'Achternaam',
  CONFIRM_ATTENDANCE_DEADLINE: 'Bevestigen kan tot',
  CONFIRM_ATTENDANCE_EDIT_DEADLINE: 'Aanpassen kan tot',
  WEEK: 'Week',
  SEND_REMINDER: 'Stuur herinnering',
  WEEK_MENU: 'Weekmenu',
  START_ORDERING: 'Start met bestellen',
  ORDER_WEEK: 'Bestelling week',
  ORDERS: 'Bestellingen',
  PREVIOUS_ORDERS: 'Eerdere bestellingen',
  EXTRAS: "Extra's",
  EXTRAS_TEXT: 'Een lekkere extra voor bij de lunch',
  LUNCH_PREFERENCES: 'Lunchvoorkeuren',
  FINALIZE_BY: 'Rond af voor',
  AMOUNT_REGISTERED: 'Aantal aangemeld',
  SHARE_MESSAGE: 'Deel bericht',
  COPIED_TO_CLIPBOARD: 'Succesvol gekopieerd naar je klembord',
  MESSAGE_TO_ALL_LUNCHERS: 'Jouw bericht aan alle lunchers',
  EXTRA_REMARK_LABEL: 'Laat hier je bijzonderheden weten...',
  AMOUNTS: 'Aantallen',
  REQUEST_NEW_PASSWORD:
    'Als dit account bestaat, sturen we een link om je wachtwoord te resetten',
  LOGIN_SUCCESSFUL: 'Succesvol ingelogd',
  LOGIN_FAILED: 'E-mailadres of wachtwoord is onjuist',
  CONFIRMED: 'Bevestigd',
  FULFILLED: 'Geleverd',
  CANCELLED: 'Geannuleerd',
  PENDING: 'In behandeling',
  NO_LUNCH: 'Geen lunch',
  NO_LUNCH_THIS_WEEK: 'Deze week geen lunch',
  PASSWORD_CHANGED: 'Wachtwoord succesvol gewijzigd',
  RATE_PRODUCT_SUPER_TASTY: 'Super leker',
  RATE_PRODUCT_SUPER_TASTY_EXT: 'Ontvang vaker dan gemiddeld',
  RATE_PRODUCT_TASTY: 'Lekker',
  RATE_PRODUCT_TASTY_EXT: 'Ontvang gemiddeld aantal keer',
  RATE_PRODUCT_NEUTRAL: 'Neutraal',
  RATE_PRODUCT_NEUTRAL_EXT: 'Neutraal',
  RATE_PRODUCT_LESS: 'Niet te vaak',
  RATE_PRODUCT_LESS_EXT: 'Ontvang minder vaak dan gemiddeld',
  RATE_PRODUCT_EXCLUDE: 'Uitsluiten',
  RATE_PRODUCT_EXCLUDE_EXT: 'Geen leveringen meer van dit product',
  SET_NEW_PASSWORD: 'Stel een nieuw wachtwoord in',
  ENTER_CURRENT_PASSWORD: 'Vul je huidige wachtwoord in',
  LOAD_MORE: 'Laad meer',
  OVERVIEW: 'Overzicht',
  SALAD_PREFERENCE: 'Salade voorkeur',
  SALAD_MENU: 'Salade menu',
  SOUP_PREFERENCE: 'Soep voorkeur',
  ORDER_COMMENTS: 'Bijzonderheden',
  MESSAGE_REQUIRED: 'Vul een bericht in.',
  SUBMIT_CHANGES: 'Aanpassing doorgeven',
  RATE_PRODUCT: 'Beoordeel dit product',
  DOWNLOAD_LUNCHER_LIST: 'Download lijst met lunchers',
  TOTAL: 'Totaal',
  EXCL_TAXES: 'excl. BTW',
  TOTAL_EXCL_TAXES: 'Totaal (excl. BTW)',
  SUBTOTAL_EXCL_TAXES: 'Subtotaal (excl. BTW)',
  NO_LONGER_EXCLUDE: 'Niet meer uitsluiten?',
  SEARCH: 'Zoeken',
  CATEGORIES: 'Categorieën',
  DELIVERY_DAYS: 'Leverdagen',
  LUNCH_DAYS: 'Lunchdagen',
  INVITE: 'Uitnodigen',
  INVITE_USER: 'Gebruiker uitnodigen',
  INVITED: 'Uitgenodigd',
  REGISTERED: 'Geregistreerd',
  LOGIN_DETAILS: 'Inlogdetails',
  SETTINGS: 'Instellingen',
  DELETE: 'Verwijderen',
  UPDATE: 'Aanpassen',
  DELIVERY_SLIP: 'Leveringsbon',
  RECEIVES_DELIVERY_SLIP: 'Ontvang wekelijks de leveringsbon',
  PERSON: 'Persoon',
  INVITATION_LINK: 'Uitnodigingslink',
  CLOSE: 'Sluiten',
  FIELD_REQUIRED: 'Dit veld is verplicht',
  FIELD_VALIDATION_EMAIL:
    'E-mail adres moet tenminste een @ en een . bevatten.',
  FIELD_VALIDATION_PW_REGEX:
    'Let op, een wachtwoord bevat: 8-16 tekens, ten minste 1 letter, 1 hoofdletter, ten minste 1 cijfer, ten minste 1 speciaal teken',
  RETURN_TO_DASHBOARD: 'Terug naar dashboard',
  RETURN_TO_OVERVIEW: 'Terug naar overzicht',
  ACCOUNT_CREATED: 'Account succesvol aangemaakt!',
  DELETE_USER: 'Gebruiker verwijderen',
  DELETE_USER_CONFIRM:
    'De medewerker zal geen toegang meer hebben tot De Buurtboer App.',
  USER_DELETED: 'Gebruiker verwijderd',
  USER_DELETED_MESSAGE:
    'Deze gebruiker heeft geen toegang meer tot de Buurtboer App',
  SHARE_LINK: 'Deel de volgende link',
  GENERATE_LINK: 'Klik hier om een uitnodiging te genereren',
  SHARE_LINK_COPIED: 'Link gekopieerd',
  SHARE_LINK_SEND_COLLEAGUES: 'Deel deze registratie-link met je collega',
  SHARE_LINK_NOTIFICATION:
    'I.v.m. de veiligheid is de link standaard 48 uur geldig. Deel opnieuw een link om je team uit te nodigen.',
  AVAILABILITY: 'Beschikbaarheid',
  AVAILABILITY_NOT_GUARANTEED:
    ' Let op: beschikbaarheid kan niet altijd worden gegarandeerd',
  WHAT_IS_YOUR_SOUP_PREFERENCE: 'Wat is je soep voorkeur?',
  WHAT_IS_YOUR_SALAD_PREFERENCE: 'Wat is je salade voorkeur?',
  COMMENTS: 'Bijzonderheden',
  ANY_COMMENTS: 'Nog bijzonderheden? Vertel het ons!',
  ANY_COMMENTS_HELPER:
    'BV: ik heb nog 2 stukken kaas liggen, dus dat hoeft volgende week niet.',
  ANY_COMMENTS_NONE: 'Deze week geen bijzonderheden',
  SIGNED_UP: 'Aangemeld',
  BACK_TO_DASHBOARD: 'Terug naar dashboard',
  SUBMIT_ORDER: 'Bestelling plaatsen',
  LUNCHER_COUNT: 'Aantal lunchers',
  INSUFFICIENT_LUNCHER_COUNT:
    'Je zit onder het minimum aantal lunchers per week.',
  NEXT_WEEK: 'Volgende week',
  VIEW_ORDER: 'Bestelling bekijken',
  INSTALL_APP: 'Installeer De Buurtboer App',
  CHOOSE_EXTRAS: "Bestel extra's voor deze week",
}
