import { useTheme } from '@mui/material'

import { AcCard, AcColumn, AcRow, AcTag, AcTypography } from '@components'
import { LABELS } from '@constants'
import { AcDateFromWeekAndYear } from '@helpers'
import { IWeeksSingle } from '@typings'

export interface IAcDayPreferences {
  preferences: IWeeksSingle['preferences'] | undefined
  week: number
  year: number
}

export const AcDayPreferences = ({
  preferences,
  week,
  year,
}: IAcDayPreferences) => {
  const theme = useTheme()

  const renderPreference = (label: string, count: number) => {
    return (
      <AcColumn gap={2}>
        <AcTypography
          removePadding
          variant="h3">
          {count}
        </AcTypography>
        <AcTypography removePadding>{label}</AcTypography>
      </AcColumn>
    )
  }

  const renderDay = (preference: any) => {
    return (
      <AcCard>
        <AcColumn gap={4}>
          <AcTypography
            removePadding
            color="green.main"
            component="h3">
            {AcDateFromWeekAndYear(week, year, preference.day, 'dddd D MMMM')}
          </AcTypography>
          <AcCard
            cardColor="beige"
            padding="lg"
            sx={{
              paddingLeft: '0 !important',
              paddingRight: '0 !important',
            }}>
            <AcRow
              sx={{
                textAlign: 'center',
                justifyContent: 'space-between',
                div: {
                  flexGrow: 1,
                  flexBasis: 0,
                  minWidth: 0,
                },
              }}>
              {renderPreference(LABELS.VEGAN_SHORT, preference.vegan)}
              {renderPreference(LABELS.VEGETARIAN, preference.vegetarian)}
              {renderPreference(LABELS.MIX, preference.mix)}
            </AcRow>
          </AcCard>

          {preference.allergies?.length > 0 && (
            <AcColumn gap={0}>
              <AcTypography color="green">{LABELS.ALLERGIES}</AcTypography>
              <AcRow
                gap={2}
                overflow={'auto'}>
                {preference?.allergies?.map((allergy: any) => (
                  <AcTag
                    color="white"
                    border={`1px solid ${theme.palette.green.main}`}
                    label={`${allergy.count} ${allergy.label}`}
                  />
                ))}
              </AcRow>
            </AcColumn>
          )}
        </AcColumn>
      </AcCard>
    )
  }

  return preferences?.map(preference => renderDay(preference))
}
