import { Button, styled } from '@mui/material'

export const PrimaryButton = styled(Button)(({ theme, ...props }) => ({
  display: 'relative',
  overflow: 'hidden',
  transition: `color 0.15s ${theme.transitions.easing.easeInOut}`,
  zIndex: 2,
  '> span': {
    zIndex: 3,
    display: 'block',
  },
  '&::before': {
    position: 'absolute',
    content: '""',
    left: '-50%',
    top: '50%',
    width: '200%',
    aspectRatio: 1,
    borderRadius: '100%',
    transition: `translate 0.5s ${theme.transitions.easing.easeInOut}, transform 0.5s ${theme.transitions.easing.easeInOut}`,
    transform: 'scale(0.5)',
    translate: '0 5%',
    zIndex: 2,
    clipPath: 'inset(0 0 0 0 round 0.25rem)',
  },
  '&::after': {
    position: 'absolute',
    content: '""',
    left: '-50%',
    top: '50%',
    width: '200%',
    aspectRatio: 1,
    borderRadius: '100%',
    transform: 'scale(0.5)',
    translate: '0 5%',
    zIndex: 1,
    transition: `translate 0.5s ${theme.transitions.easing.easeInOut}, transform 0.5s ${theme.transitions.easing.easeInOut}`,
  },
  '&:where(&:hover, &:focus-visible)': {
    color: theme.palette.white.main,
    '&::before': {
      backgroundColor: theme.palette.green.main,
      transform: 'scale(1.25)',
      translate: '0 -15%',
    },
    '&::after': {
      backgroundColor: theme.palette.orange.main,
      transform: 'scale(1.25)',
      translate: '0 -25%',
    },
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette['grey-20'].main,
    color: theme.palette['grey-20'].contrastText,
  },
  ...(props.loading && {
    backgroundColor: theme.palette.green.main,
    pointerEvents: 'none',
  }),
}))
