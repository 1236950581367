import { Input, styled } from '@mui/material'

export const AcStyledNumberInput = styled(Input)(({ theme, ...props }) => ({
  borderRadius: 50,
  background: theme.palette.white.main,
  color: theme.palette.green.main,
  fontSize: theme.typography.fontSize,
  paddingBlock: theme.spacing(2),
  maxHeight: 56,
  justifyContent: 'space-between',
  textAlign: 'center',
  width: props.fullWidth ? '100%' : 'auto',
  '&::placeholder': {
    fontSize: theme.typography.fontSize,
  },
  '&::before, &::after': {
    display: 'none',
  },
  '.MuiSvgIcon-root': {
    cursor: 'pointer',
  },
  '.MuiInputBase-input': {
    textAlign: 'center',
    maxWidth: 32,
    MozAppearance: 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      appearance: 'none',
      margin: 0,
    },
  },
}))
