import {
  AcCard,
  AcColumn,
  AcFormInput,
  AcNumberInput,
  AcRow,
  AcTypography,
} from '@components'
import { LABELS, WEEKDAYS_LABELS } from '@constants'
import { AcDateFromWeekAndYear, getSum } from '@helpers'
import { useBreakpoints, useStore } from '@hooks'

interface IAcOrderDayCard {
  weekDay: any
  weekIdentifier: string
}

export const AcOrderDayCard = ({
  weekDay,
  weekIdentifier,
}: IAcOrderDayCard) => {
  const { isMobile } = useBreakpoints()
  const { user } = useStore()

  if (!weekIdentifier) {
    return null
  }

  const [week, year] = weekIdentifier.split('-')

  return (
    <AcCard
      cardColor="grey-10"
      contentColor="green.main">
      <AcRow
        justifyContent="space-between"
        alignItems={isMobile ? 'flex-start' : 'center'}>
        <AcRow
          flexGrow={1}
          sx={{
            gap: isMobile ? 1 : '10rem',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
          }}>
          <AcColumn
            gap={0}
            sx={{ width: '25%' }}>
            <AcTypography
              bold
              removePadding>
              {
                WEEKDAYS_LABELS[
                  weekDay.day.toUpperCase() as keyof typeof WEEKDAYS_LABELS
                ]
              }
            </AcTypography>
            <AcTypography removePadding>
              {AcDateFromWeekAndYear(
                parseInt(week),
                parseInt(year),
                weekDay.day,
                'D MMMM',
              )}
            </AcTypography>
          </AcColumn>
          {user.hasApp && (
            <AcTypography
              justifySelf="flex-end"
              fontSize={isMobile ? 'small' : '1rem'}
              removePadding>
              {getSum([weekDay.mix, weekDay.vegan, weekDay.vegetarian])}x{' '}
              {LABELS.SIGNED_UP.toLowerCase()}
            </AcTypography>
          )}
        </AcRow>
        <AcColumn>
          <AcFormInput
            register={weekDay.day}
            Component={
              <AcNumberInput
                variant="styled"
                min={0}
              />
            }
          />
        </AcColumn>
      </AcRow>
    </AcCard>
  )
}
