import { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'

import {
  AcBox,
  AcButton,
  AcColumn,
  AcRow,
  AcSelect,
  AcTextInput,
} from '@components'
import { LABELS } from '@constants'
import { useBreakpoints, useStore } from '@hooks'
import { AcInviteUserModal } from '@partials'

export interface IAcUserManagementFiltersArgs {
  query: string
  roleFilters?: string[]
}
interface IAcUserManagementFilters {
  updateFilters: (args: IAcUserManagementFiltersArgs) => void
}

export const AcUserManagementFilters = ({
  updateFilters,
}: IAcUserManagementFilters) => {
  const formId = 'user-management-filters'
  const { dialog, users } = useStore()
  const { isMobile } = useBreakpoints()
  const [query, setQuery] = useState('')
  const [roleFilters, setRoleFilters] = useState<string[]>([])

  const inviteUserDialog = () => {
    dialog.open({
      title: LABELS.INVITE_USER,
      content: <AcInviteUserModal />,
      maxWidth: 448,
    })
  }

  useEffect(() => {
    if (updateFilters) updateFilters({ query, roleFilters })
  }, [query, roleFilters?.length])

  const ContainerEl = isMobile ? AcColumn : AcRow

  return (
    <ContainerEl
      width="100%"
      justifyContent="space-between"
      alignItems="center">
      <AcTextInput
        fullWidth
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Zoeken"
        InputProps={{
          endAdornment: <SearchIcon />,
        }}
        sx={{
          minWidth: 200,
        }}
      />
      <AcRow
        width="100%"
        justifyContent="space-between"
        alignItems={'center'}>
        {users.user_list_roles?.length && (
          <AcSelect
            fullWidth
            value={roleFilters}
            id={formId}
            label={users.user_list_roles.length > 1 ? 'Rollen' : 'Rol'}
            allowMultiple
            options={users.user_list_roles?.map(role => ({
              id: role.name,
              value: role.label,
              label: role.label,
            }))}
            onChange={val => setRoleFilters(val as any)}
          />
        )}
        <AcButton
          onClick={inviteUserDialog}
          fullWidth>
          {LABELS.INVITE}
        </AcButton>
      </AcRow>
    </ContainerEl>
  )
}
