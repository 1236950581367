import { AuthApi } from './auth.api'
import { ProductsApi } from './products.api'
import { SpecificationsApi } from './specifications.api'
import { UserApi } from './user.api'
import { UsersApi } from './users.api'
import { WeeksApi } from './weeks.api'

export interface IApi {
  auth: AuthApi
  user: UserApi
  users: UsersApi
  weeks: WeeksApi
  products: ProductsApi
  specifications: SpecificationsApi
}

export default (): IApi => {
  const auth = new AuthApi()
  const user = new UserApi()
  const users = new UsersApi()
  const weeks = new WeeksApi()
  const products = new ProductsApi()
  const specifications = new SpecificationsApi()
  return {
    auth,
    user,
    users,
    weeks,
    products,
    specifications,
  }
}
