import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { NAVIGATE_TO } from '@routes'
import type { IAxiosError, IErrorResponse } from '@typings'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcColumn,
  AcEmailInput,
  AcFormInput,
  AcFormProvider,
  AcPasswordInput,
  AcTextInput,
  AcTypography,
} from '@components'
import { LABELS, REGEX } from '@constants'
import { useBreakpoints, useInputCompare } from '@hooks'
import { useStore } from '@hooks'
import { IRegisterUserPayload } from '@typings'

interface IRegisterFormInputs {
  inviteSent?: boolean
}

const FormInputs = observer(({ inviteSent = false }: IRegisterFormInputs) => {
  const { auth, toaster } = useStore()
  const { isMobile } = useBreakpoints()
  const { setValue } = useFormContext()
  const { checkPasswordMatch } = useInputCompare(
    'password',
    'password_confirmation',
  )
  const [params] = useSearchParams()
  const [blankInvite, setBlankInvite] = useState(false)
  const navigate = useNavigate()

  const fetchRegistrationFields = async (
    codeFromParams: string,
    signatureFromParams: string,
  ) => {
    try {
      const res = await auth.getRegistrationFields(
        codeFromParams,
        signatureFromParams,
      )
      // Invite without account.
      if (res === undefined) {
        setBlankInvite(true)
        return
      }
      setValue('email', res.email)
      setValue('first_name', res.first_name)
      setValue('middle_name', res.middle_name)
      setValue('last_name', res.last_name)
    } catch (error) {
      const err = error as IErrorResponse
      toaster.error({
        content: err.message,
      })
      navigate(NAVIGATE_TO.LOGIN)
    }
  }

  useEffect(() => {
    const codeFromParams = params.get('code')
    const signatureFromParams = params.get('signature')
    if (!codeFromParams || !signatureFromParams) return
    fetchRegistrationFields(codeFromParams, signatureFromParams)
  }, [])

  return (
    <>
      <AcColumn spacing="xl">
        <AcColumn gap={0}>
          <AcTypography
            color="green.main"
            bold>
            {LABELS.CREATE_ACCOUNT}
          </AcTypography>
          <AcFormInput
            register="first_name"
            Component={
              <AcTextInput
                label={LABELS.FIRST_NAME}
                required
                disabled={!blankInvite}
              />
            }
          />
          <AcFormInput
            register="middle_name"
            Component={
              <AcTextInput
                label={LABELS.MIDDLE_NAME}
                disabled={!blankInvite}
              />
            }
          />
          <AcFormInput
            register="last_name"
            Component={
              <AcTextInput
                label={LABELS.LAST_NAME}
                required
                disabled={!blankInvite}
              />
            }
          />
          <AcFormInput
            register="email"
            rules={{
              required: 'required',
              pattern: {
                value: REGEX.EMAIL,
                message: LABELS.FIELD_VALIDATION_EMAIL,
              },
            }}
            Component={
              <AcEmailInput
                label={LABELS.EMAIL_ADDRESS}
                required
                disabled={!blankInvite}
              />
            }
          />
        </AcColumn>
        <AcColumn gap={0}>
          <AcTypography
            color="green.main"
            bold>
            Stel je wachtwoord in
          </AcTypography>
          <AcFormInput
            register="password"
            rules={{
              required: 'required',
              pattern: {
                value: REGEX.PASSWORD,
                message: LABELS.FIELD_VALIDATION_PW_REGEX,
              },
            }}
            Component={
              <AcPasswordInput
                label={LABELS.PASSWORD}
                required
                onBlur={checkPasswordMatch}
              />
            }
          />
          <AcFormInput
            lastChild
            register="password_confirmation"
            rules={{
              required: 'required',
              pattern: {
                value: REGEX.PASSWORD,
                message: LABELS.FIELD_VALIDATION_PW_REGEX,
              },
            }}
            Component={
              <AcPasswordInput
                label={LABELS.CONFIRM_PASSWORD}
                required
                onBlur={checkPasswordMatch}
              />
            }
          />
        </AcColumn>

        <AcButton
          type="submit"
          fullWidth
          disabled={inviteSent}
          loading={auth.watchLoading}
          endIcon={<ArrowForwardIcon />}
          sx={{ marginTop: isMobile ? 'auto' : 6 }}>
          {LABELS.NEXT}
        </AcButton>
      </AcColumn>
    </>
  )
})
export const AcRegistrationForm = observer(() => {
  const navigate = useNavigate()
  const { auth, toaster, user } = useStore()
  const [errors, setErrors] = useState<IAxiosError['errorFields'] | any>(null)
  const [params] = useSearchParams()

  useEffect(() => {
    if (!params.get('code') || !params.get('signature')) {
      toaster.error({ content: 'Invalid registration link.' })
      navigate(NAVIGATE_TO.LOGIN)
    }
  }, [])

  const onSubmit = async (data: IRegisterUserPayload) => {
    const codeFromParams = params.get('code') || ''
    const signatureFromParams = params.get('signature') || ''
    try {
      const res = await auth.register({
        ...data,
        signature: signatureFromParams,
        code: codeFromParams,
      })
      // 204, no response = should get email -> redirect to new view.
      if (!res) {
        navigate(NAVIGATE_TO.INVITED)
        return
      }

      // Skip onboarding if user is not a luncher.
      if (!user.isLuncher) {
        toaster.success({ content: LABELS.ACCOUNT_CREATED })
        navigate(NAVIGATE_TO.ADMIN_DASHBOARD)
        return
      }

      navigate(NAVIGATE_TO.ONBOARDING_PREFERENCES)
    } catch (error) {
      const err = error as IErrorResponse
      if (err.type === 'axios') {
        setErrors(err.errorFields)
        if (!err.errorFields) {
          toaster.error({ content: err.message })
        }
      } else {
        toaster.error({ content: err.message })
      }
    }
  }

  return (
    <AcFormProvider
      onSubmit={onSubmit}
      initial={[]}
      errors={errors}
      flexGrow={1}>
      <FormInputs />
    </AcFormProvider>
  )
})
