import { useEffect } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { usePWAInstall } from '@hooks'
import { useStore } from '@hooks'

import { ROUTER } from './routes.routes'

export const AcRouter = observer(() => {
  const { user, auth } = useStore()
  const { saveEvent } = usePWAInstall()
  const router = createBrowserRouter(ROUTER)

  const fetchProfile = async () => {
    if (auth.is_authenticated) {
      try {
        await user.whoAmI()
      } catch (error: any) {
        if (error?.status > 400) {
          auth.reset()
          user.reset()
          window.location.href = '/'
        }
      }
    }
  }

  useEffect(() => {
    fetchProfile()

    window.addEventListener('beforeinstallprompt', e => {
      console.log('PWA Install prompt available')
      e.preventDefault()
      saveEvent(e as any)
    })
  }, [])

  return <RouterProvider router={router} />
})
