export const removeDuplicatesIArray = (inputArray: any[], key?: string) => {
  if (!key) {
    return inputArray.reduce((unique, original) => {
      if (!unique.some((string: string) => string === original)) {
        unique.push(original)
      }
      return unique
    }, [])
  }

  return inputArray.reduce((unique, original) => {
    if (!unique.some((obj: any) => obj[key] === original[key])) {
      unique.push(original)
    }
    return unique
  }, [])
}
