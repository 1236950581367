import React from 'react'
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material'
import { ErrorBoundary, Provider } from '@rollbar/react'
import { render } from 'preact'
import { DEFAULT_THEME } from 'src/theme/index.theme'

import 'preact/debug'

import { AcRouter } from './routes/ac-router.routes'

import './styles/global.scss'

const root = document.getElementById('root') as HTMLElement
const globalStyles = (
  <GlobalStyles
    styles={{
      html: {
        height: '100%',
      },
      body: {
        height: '100%',
        maxHeight: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      },
    }}
  />
)

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_KEY,
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
}

render(
  <React.StrictMode>
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        {globalStyles}
        <ThemeProvider theme={DEFAULT_THEME}>
          <CssBaseline />
          <AcRouter />
        </ThemeProvider>
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  root,
)
