import { AcAnimatedFade } from '@animations'

import { AcBox, AcCard, AcTypography } from '@components'
import { AcLuncherAllergiesForm } from '@partials'

export const OnboardingAllergiesView = () => {
  return (
    <AcAnimatedFade
      enter={{ x: 20, y: 0 }}
      exit={{ x: -20, y: 0 }}
      style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <AcBox pb={5}>
        <AcTypography
          color="green.main"
          component="h2"
          padding="sm">
          Heb je allergieën?
        </AcTypography>
        <AcTypography padding="sm">
          Zijn er allergiën waar we rekening mee moeten houden?
        </AcTypography>
        <AcCard cardColor="beige">
          <AcTypography removePadding>
            Let op! Geef alleen allergieën door als het nodig is, niet als
            voorkeur. Zo kunnen we een gevarieerde lunch inplannen.
          </AcTypography>
        </AcCard>
      </AcBox>
      <AcLuncherAllergiesForm nextStep={true} />
    </AcAnimatedFade>
  )
}
