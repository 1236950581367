import { forwardRef, useEffect, useMemo, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { alpha } from '@mui/material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { observer } from 'mobx-react-lite'

import { AcRow, AcTypography } from '@components'
import { useBreakpoints } from '@hooks'
import { useStore } from '@hooks'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (props.in) {
      setTimeout(() => {
        setVisible(true)
      }, 250)
    } else {
      setVisible(false)
    }
  }, [props.in])
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      in={visible}
      timeout={{
        enter: 350,
        exit: 250,
      }}
    />
  )
})

export const AcDialog = observer(() => {
  const { dialog } = useStore()
  const { isMobile } = useBreakpoints()

  const handleClose = () => {
    dialog.close()
  }

  const fullScreen = useMemo(() => {
    return (dialog?.fullScreen && isMobile) || false
  }, [dialog?.fullScreen, isMobile])

  return (
    <Dialog
      fullScreen={fullScreen}
      open={dialog.is_open}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      slotProps={{
        backdrop: {
          sx: theme => ({
            backgroundColor: alpha(theme.palette.green.main, 0.8),
          }),
        },
      }}
      sx={() => ({
        '.MuiDialog-container': {
          '.MuiPaper-root': {
            width: '100%',
            maxWidth: dialog.maxWidth,
          },
          '.MuiDialog-paperFullScreen': {
            margin: 2,
            borderRadius: 2,
            maxHeight: 'calc(100vh - 16px)',
          },
        },
      })}>
      <DialogTitle>
        <AcRow
          justifyContent="space-between"
          alignItems="center">
          <AcTypography
            removePadding
            component="h2">
            {dialog.title}
          </AcTypography>
          <IconButton
            color="green"
            aria-label="close"
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </AcRow>
      </DialogTitle>

      <DialogContent>
        {dialog.subTitle && <AcTypography>{dialog.subTitle}</AcTypography>}
        {dialog.content}
      </DialogContent>
    </Dialog>
  )
})
