import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcCheckboxGroup,
  AcFormInput,
  AcFormProvider,
  AcIcon,
  AcRow,
} from '@components'
import { ICONS, LABELS, WEEKDAYS_LABELS } from '@constants'
import { useBreakpoints } from '@hooks'
import { useStore } from '@hooks'
import { IErrorResponse } from '@typings'

export interface IAcLuncherDefaultAttendanceForm {
  nextStep: boolean
}

export const AcLuncherDefaultAttendanceForm = observer(
  ({ nextStep }: IAcLuncherDefaultAttendanceForm) => {
    const { isMobile } = useBreakpoints()
    const navigate = useNavigate()
    const { user, toaster, dialog } = useStore()
    const [errors, setErrors] = useState<any>(null)

    const getWeekdayOptions = useMemo(() => {
      const keys = Object.entries(WEEKDAYS_LABELS)
      return keys.map(([key, value]) => ({
        id: key,
        value: key.toLowerCase(),
        title: value,
      }))
    }, [])

    const onSubmit = async (data: any) => {
      try {
        await user.patchDefaultAttendance(data.attendance)
        toaster.success({ content: LABELS.SAVED_DEFAULT_ATTENDANCE })
        if (nextStep) {
          navigate(NAVIGATE_TO.ONBOARDING_COMPLETED)
          return
        }
        dialog.close()
      } catch (error) {
        const err = error as IErrorResponse
        if (err.type === 'axios') {
          setErrors(err.errorFields)
          toaster.error({ content: err.message })
        } else {
          toaster.error({ content: err.message })
        }
      }
    }

    const getDefaultValues = async () => {
      return {
        attendance: user.current?.profile.days,
      }
    }

    return (
      <AcFormProvider
        initial={getDefaultValues}
        onSubmit={onSubmit}
        errors={errors}
        flexGrow={1}>
        <AcFormInput
          register="attendance"
          Component={
            <AcCheckboxGroup
              fullWidth
              id="attendance"
              options={getWeekdayOptions}
            />
          }
        />
        {!nextStep ? (
          <AcButton
            loading={user.loading}
            type="submit"
            fullWidth
            sx={{ marginTop: isMobile ? 'auto' : 2 }}>
            {LABELS.CONFIRM}
          </AcButton>
        ) : (
          <AcRow
            alignItems="center"
            justifyContent="space-between"
            marginTop={isMobile ? 'auto' : 2}>
            <AcButton
              disabled={user.loading}
              type="button"
              onClick={() => navigate(-1)}
              variant="text"
              startIcon={<AcIcon icon={ICONS.BACK} />}>
              {LABELS.BACK}
            </AcButton>
            <AcButton
              loading={user.loading}
              type="submit"
              endIcon={<AcIcon icon={ICONS.NEXT} />}>
              {LABELS.NEXT}
            </AcButton>
          </AcRow>
        )}
      </AcFormProvider>
    )
  },
)
