import { useMemo } from 'react'

import {
  AdminNavigation,
  IAcMenuItem,
  KEYS,
  LuncherNavigation,
} from '@constants'

import { useStore } from './use-store'

export const useRoleToggle = () => {
  const { user } = useStore()
  const navigation: IAcMenuItem[] = useMemo(() => {
    if (user.watchActiveRoleName === KEYS.OFFICE_MANAGER_ROLE) {
      if (user.isBasicSubscription)
        return AdminNavigation.filter(({ id }) => id !== 'ORDERS')
      return AdminNavigation
    }
    return LuncherNavigation
  }, [user.watchActiveRoleName])

  return { activeRole: user.watchActiveRoleName, navigation }
}
