import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import {
  AcAlert,
  AcButton,
  AcColumn,
  AcFormProvider,
  AcOrderDayCard,
  AcOrderFooter,
  AcRow,
} from '@components'
import {
  DIALOGS,
  LABELS,
  MINIMUM_LUNCHER_COUNT,
  WEEKDAYS_KEYS,
} from '@constants'
import { getDateRange } from '@helpers'
import { useStore } from '@hooks'
import { IOrderLunchersPayload, IWeekDay } from '@typings'

interface IFormInputs {
  weekIdentifier: string
}
const FormInputs = observer(({ weekIdentifier }: IFormInputs) => {
  if (!weekIdentifier) {
    return null
  }

  const [week, year] = weekIdentifier.split('-')

  const { weeks, dialog, currentOrder } = useStore()
  const { watch } = useFormContext()
  const formValues = watch(Object.values(WEEKDAYS_KEYS))

  const totalLunchers = useMemo(() => {
    return Object.values(formValues).reduce((acc, current) => {
      return parseInt(acc) + parseInt(current)
    })
  }, [formValues])

  return (
    <AcColumn gap={4}>
      <AcColumn gap={1}>
        {weeks.weekLunchers?.map((weekDay: any) => (
          <AcOrderDayCard
            weekIdentifier={weekIdentifier}
            weekDay={weekDay}
          />
        ))}
      </AcColumn>

      {totalLunchers < MINIMUM_LUNCHER_COUNT && (
        <AcAlert severity="warning">
          {LABELS.INSUFFICIENT_LUNCHER_COUNT}
        </AcAlert>
      )}

      <AcRow>
        <AcButton
          variant="secondary"
          onClick={() =>
            dialog.open({
              ...DIALOGS.NO_LUNCH_THIS_WEEK(weekIdentifier),
              subTitle: `Week ${week} - ${getDateRange(parseInt(week), parseInt(year))}`,
            })
          }>
          {LABELS.NO_LUNCH_THIS_WEEK}
        </AcButton>
      </AcRow>

      <AcOrderFooter loading={currentOrder.watchLoadingSubmitting} />
    </AcColumn>
  )
})

export const AcOrderLunchersForm = observer(
  ({ weekIdentifier }: { weekIdentifier: string }) => {
    const { user, currentOrder, toaster } = useStore()
    const navigate = useNavigate()

    const handleSubmit = async (body: IOrderLunchersPayload) => {
      try {
        const res = await currentOrder.submitLuncherCount(body)
        if (res) {
          navigate(NAVIGATE_TO.ORDER_PREFERENCES(weekIdentifier))
        }
      } catch (error: any) {
        if (error.type === 'axios') {
          // setErrors(error.errorFields)
        }
        toaster.error({
          title: 'Nog niet alle velden zijn juist ingevuld',
          content: error.message,
        })
      }
    }

    if (!weekIdentifier) {
      return null
    }

    const [week, year] = weekIdentifier.split('-')

    const weekDayCounts = useMemo(() => {
      if (
        typeof currentOrder.watchFormValues === 'object' &&
        currentOrder.watchFormValues !== null &&
        Object.keys(currentOrder.watchFormValues).length
      ) {
        return currentOrder.watchFormValues
      }

      const values = Object.values(WEEKDAYS_KEYS).map(weekDay => ({
        [weekDay]: user
          .getCustomerLunchDayCount(weekDay as IWeekDay)
          .toString(),
      }))

      return values.reduce((acc, current) => {
        const [key, value] = Object.entries(current)[0]
        acc[key] = value
        return acc
      }, {})
    }, [user.current, currentOrder.watchFormValues])

    return (
      <AcFormProvider
        initial={weekDayCounts}
        onSubmit={handleSubmit}>
        <FormInputs weekIdentifier={weekIdentifier} />
      </AcFormProvider>
    )
  },
)
