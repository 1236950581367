import { useMemo } from 'react'

import { AcTabs } from '@components'
import { AcInviteUserForm, AcInviteUserLinkForm } from '@partials'

export const AcInviteUserModal = () => {
  const renderTabs = useMemo(() => {
    return [
      {
        id: 1,
        title: 'Persoon',
        content: <AcInviteUserForm />,
      },
      {
        id: 2,
        title: 'Uitnodigingslink',
        content: <AcInviteUserLinkForm />,
      },
    ]
  }, [])

  return (
    <AcTabs
      small
      tabs={renderTabs}
    />
  )
}
