import { LABELS } from '@constants'
import { IProductRating } from '@typings'

export const mapProductRatingLabel = (ratingValue: IProductRating['value']) => {
  switch (ratingValue) {
    case 0:
      return LABELS.RATE_PRODUCT_EXCLUDE

    case 1:
      return LABELS.RATE_PRODUCT_LESS

    case 3:
      return LABELS.RATE_PRODUCT_NEUTRAL

    case 5:
      return LABELS.RATE_PRODUCT_TASTY

    case 6:
      return LABELS.RATE_PRODUCT_SUPER_TASTY
  }
}
