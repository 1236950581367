import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { alpha } from '@mui/material'

import {
  AcButton,
  AcCard,
  AcCheckbox,
  AcCheckboxGroup,
  AcColumn,
  AcFormInput,
  AcFormProvider,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { copyToClipboard, getFormRoleOptions } from '@helpers'
import { useStore } from '@hooks'
import { IAxiosError, IRoleNameEnum, ISinglePortalUser } from '@typings'

const FormInputs = ({ refreshLink }: { refreshLink: () => void }) => {
  const { user } = useStore()
  const { watch } = useFormContext()
  const roles = watch('roles')
  const tickets = watch('receives_tickets')

  useEffect(() => {
    refreshLink()
  }, [roles, tickets])

  return (
    <>
      <AcTypography
        fontWeight="bold"
        color="green.main">
        {LABELS.SETTINGS}
      </AcTypography>
      <AcTypography
        component="span"
        color="green.main">
        {LABELS.USER_ROLES}
      </AcTypography>
      <AcFormInput
        register="roles"
        Component={
          <AcCheckboxGroup
            variant="default"
            id="user-roles"
            options={getFormRoleOptions(user.hasApp).all}
          />
        }
      />
      <AcTypography
        component="span"
        color="green.main">
        {LABELS.DELIVERY_SLIP}
      </AcTypography>
      <AcFormInput
        register="receives_tickets"
        Component={
          <AcCheckbox
            variant="default"
            id="receives_tickets"
            trueValue={1}
            falseValue={0}
            label={LABELS.RECEIVES_DELIVERY_SLIP}
          />
        }
      />
    </>
  )
}

export const AcInviteUserLinkForm = () => {
  const { dialog, toaster, users } = useStore()
  const [errors, setErrors] = useState<IAxiosError['errorFields'] | any>(null)
  const [shareLink, setShareLink] = useState('')
  const [generating, setGenerating] = useState(false)

  const onSubmit = async (payload: {
    roles: IRoleNameEnum[]
    receives_tickets: ISinglePortalUser['receives_tickets']
  }) => {
    setGenerating(true)
    try {
      const data = await users.createInvitationLink(
        payload.roles,
        payload.receives_tickets,
      )
      if (data.link) {
        setShareLink(data.link)
      }
    } catch (error: any) {
      if (error.type === 'axios') {
        setErrors(error.errorFields)
      }
      toaster.error({
        content:
          error.message ||
          'Er is iets fout gegaan, probeer het later nog een keer',
      })
    } finally {
      setGenerating(false)
    }
  }

  const handleCopy = () => {
    copyToClipboard(shareLink)
    toaster.success({
      title: LABELS.SHARE_LINK_COPIED,
      content: LABELS.SHARE_LINK_SEND_COLLEAGUES,
    })
  }

  const refreshLink = () => {
    setShareLink('')
  }

  const initial = {
    roles: [],
    receives_tickets: false,
  }

  return (
    <AcColumn mt={2}>
      <AcFormProvider
        initial={initial}
        onSubmit={onSubmit}
        errors={errors}>
        <FormInputs refreshLink={refreshLink} />

        <AcColumn gap={6}>
          {shareLink ? (
            <AcColumn>
              <AcTypography
                component="span"
                fontWeight="bold"
                color="green.main">
                {LABELS.SHARE_LINK}
              </AcTypography>
              <AcButton
                onClick={handleCopy}
                endIcon={<ContentCopyIcon />}
                sx={theme => ({
                  bgcolor: alpha(theme.palette['light-green'].main, 0.3),
                  fontWeight: theme.typography.fontWeightRegular,
                  justifyContent: 'space-between',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  span: {
                    overflow: 'hidden',
                  },
                  '.MuiButton-icon': {
                    overflow: 'visible',
                  },
                })}>
                {shareLink}
              </AcButton>
            </AcColumn>
          ) : (
            <AcButton
              loading={generating}
              type="submit">
              {LABELS.GENERATE_LINK}
            </AcButton>
          )}

          <AcCard cardColor="beige">
            <AcTypography>{LABELS.SHARE_LINK_NOTIFICATION}</AcTypography>
          </AcCard>

          <AcButton onClick={dialog.close}>{LABELS.CLOSE}</AcButton>
        </AcColumn>
      </AcFormProvider>
    </AcColumn>
  )
}
