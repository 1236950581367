import { KEYS, LABELS } from '@constants'
export const getFormRoleOptions = (hasApp: boolean) => {
  const luncher = {
    id: KEYS.LUNCHR_ROLE_ID,
    value: KEYS.LUNCHER_ROLE,
    label: LABELS.LUNCHER,
  }

  const officeManager = {
    id: KEYS.OFFICE_MANAGER_ROLE_ID,
    value: KEYS.OFFICE_MANAGER_ROLE,
    label: LABELS.OFFICE_MANAGER,
  }

  return {
    luncher,
    officeManager,
    all: hasApp ? [luncher, officeManager] : [officeManager],
  }
}
