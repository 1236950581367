import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AcAnimatedFade } from '@animations'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import { AcColumn, AcContainer, AcOrderHeader, AcTypography } from '@components'
import { useStore } from '@hooks'
import { AcOrderPreferencesForm } from '@partials'

export const OrderPreferencesView = observer(() => {
  const { weekIdentifier } = useParams()
  const navigate = useNavigate()
  const { currentOrder, user } = useStore()

  if (!weekIdentifier) {
    return null
  }

  useEffect(() => {
    if (user.isBasicSubscription) {
      navigate(NAVIGATE_TO.ORDER_SUMMARY(weekIdentifier), { replace: true })
    } else if (!currentOrder.watchFormValues) {
      navigate(NAVIGATE_TO.ORDER(weekIdentifier), { replace: true })
    }
  }, [currentOrder.watchFormValues, user.isBasicSubscription])

  useEffect(() => {
    currentOrder.fetchAvailableMenus()
  }, [])

  return (
    <AcAnimatedFade
      enter={{ x: 20, y: 0 }}
      exit={{ x: -20, y: 0 }}>
      <AcContainer>
        <AcColumn gap={6}>
          <AcOrderHeader
            step={2}
            weekIdentifier={weekIdentifier}
          />
          <AcColumn gap={4}>
            <AcTypography
              component="h1"
              removePadding>
              Waar heb je trek in?
            </AcTypography>
            <AcOrderPreferencesForm weekIdentifier={weekIdentifier} />
          </AcColumn>
        </AcColumn>
      </AcContainer>
    </AcAnimatedFade>
  )
})
