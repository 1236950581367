import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { action, computed, makeAutoObservable } from 'mobx'

import { CURRENT_ORDER_KEYS, STORE_KEYS } from '@constants'
import {
  extractAPIFieldErrors,
  isEmptyObject,
  transformOrderSummaryExtras,
} from '@helpers'
import { Store } from '@stores'
import {
  IExtrasProduct,
  IOrderDetails,
  IOrderLunchersPayload,
  IOrderPreferencePayload,
  ISoupsSaladsResponse,
} from '@typings'

dayjs.extend(isoWeek)

export class CurrentOrderStore {
  store: Store
  loadingInitial: boolean
  loadingStep: boolean
  loadingSubmitting: boolean
  currentStep: number
  currentOrder: IOrderDetails | null
  availableMenus: ISoupsSaladsResponse | null
  formValues: any

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loadingInitial = false
    this.loadingStep = false
    this.loadingSubmitting = false
    this.currentStep = 1
    this.currentOrder = null
    this.formValues = {}
    this.availableMenus = null
  }

  @computed
  get watchLoadingInitial(): CurrentOrderStore['loadingInitial'] {
    return this.loadingInitial
  }

  @computed
  get watchLoadingStep(): CurrentOrderStore['loadingStep'] {
    return this.loadingStep
  }

  @computed
  get watchLoadingSubmitting(): CurrentOrderStore['loadingSubmitting'] {
    return this.loadingSubmitting
  }

  @computed
  get watchAvailableSalads(): ISoupsSaladsResponse['salad'] {
    return this.availableMenus?.salad
  }

  @computed
  get watchAvailableSoups(): ISoupsSaladsResponse['soup'] {
    return this.availableMenus?.soup
  }

  @computed
  get watchFormValues(): any {
    if (isEmptyObject(this.formValues)) return null
    return this.formValues
  }

  @action
  setWeekIdentifier = (weekIdentifier: string) => {
    this.formValues.week = weekIdentifier
  }

  @action
  setCurrentOrder = (order: IOrderDetails) => {
    this.currentOrder = order
  }

  @action
  fetchLunchersStepInitialValues = async () => {
    return {
      [CURRENT_ORDER_KEYS.MONDAY]:
        this.formValues?.[CURRENT_ORDER_KEYS.MONDAY] || null,
      [CURRENT_ORDER_KEYS.TUESDAY]:
        this.formValues?.[CURRENT_ORDER_KEYS.TUESDAY] || null,
      [CURRENT_ORDER_KEYS.WEDNESDAY]:
        this.formValues?.[CURRENT_ORDER_KEYS.WEDNESDAY] || null,
      [CURRENT_ORDER_KEYS.THURSDAY]:
        this.formValues?.[CURRENT_ORDER_KEYS.THURSDAY] || null,
      [CURRENT_ORDER_KEYS.FRIDAY]:
        this.formValues?.[CURRENT_ORDER_KEYS.FRIDAY] || null,
    }
  }

  @action
  fetchPreferenceStepInitialValues = async () => {
    return this.formValues
  }

  @action
  updateFormValues = (payload: any) => {
    for (const [key, value] of Object.entries(payload)) {
      this.formValues[key] = value
    }
  }

  @action
  submitLuncherCount = async (payload: IOrderLunchersPayload) => {
    this.updateFormValues(payload)

    try {
      this.loadingSubmitting = true
      await this.store.api.specifications.validate_order_luncher_count(payload)
      return Promise.resolve(true) // Precognition API does not return any values
    } catch (error) {
      const err = extractAPIFieldErrors(error as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.loadingSubmitting = false
    }
  }

  @action
  submitPreferences = async (payload: IOrderPreferencePayload) => {
    this.updateFormValues(payload)

    const transformSubmit = (body: IOrderPreferencePayload) => {
      const soup = []
      const salad = []
      const keysToDelete = []
      for (const [key, value] of Object.entries(body)) {
        if (key.includes('soup-')) {
          if (Array.isArray(value)) {
            value.forEach(v => soup.push(v))
          } else {
            soup.push(value)
          }
          keysToDelete.push(key)
        }
        if (key.includes('salad-')) {
          if (Array.isArray(value)) {
            value.forEach(v => salad.push(v))
          } else {
            salad.push(value)
          }
          keysToDelete.push(key)
        }
      }

      //@ts-ignore
      keysToDelete.forEach(key => delete body[key])
      this.updateFormValues({
        soup: soup.filter(Boolean),
        salad: salad.filter(Boolean),
      })

      return {
        ...body,
        soup: soup.filter(Boolean),
        salad: salad.filter(Boolean),
      }
    }

    try {
      this.loadingSubmitting = true
      const transformedPayload = transformSubmit(payload)
      await this.store.api.specifications.validate_order_preferences(
        transformedPayload,
      )
      return Promise.resolve(true) // Precognition API does not return any values
    } catch (error) {
      const err = extractAPIFieldErrors(error as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.loadingSubmitting = false
    }
  }

  @action
  createOrder = async (body: any, availableExtras: IExtrasProduct[]) => {
    try {
      this.loadingSubmitting = true

      const extrasPerDay = transformOrderSummaryExtras(body, availableExtras)
      const renderExtras = !extrasPerDay?.length
        ? null
        : extrasPerDay.map(extra => ({
            day: extra.day,
            product_id: extra.product?.id,
            quantity: extra.qty,
          }))

      this.updateFormValues({ extras: renderExtras })

      const orderBody = {
        week: this.formValues.week,
        monday: this.formValues.monday,
        tuesday: this.formValues.tuesday,
        wednesday: this.formValues.wednesday,
        thursday: this.formValues.thursday,
        friday: this.formValues.friday,
        soup: this.formValues.soup,
        salad: this.formValues.salad,
        comment: this.formValues.comment,
        extras: renderExtras,
      }

      const newOrder =
        await this.store.api.specifications.create_order(orderBody)
      if (newOrder) {
        return Promise.resolve(newOrder)
      }
    } catch (error) {
      const err = extractAPIFieldErrors(error as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.loadingSubmitting = false
    }
  }

  @action
  fetchOrderByIdentifier = async (identifier: string) => {
    this.loadingInitial = true
    try {
      const order =
        await this.store.api.specifications.get_single_by_identifier(identifier)
      if (order) this.setCurrentOrder(order)
      return Promise.resolve(order)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.loadingInitial = false
    }
  }

  @action
  fetchAvailableMenus = async () => {
    this.loadingStep = true
    try {
      const menus = await this.store.api.products.fetchMenus()
      if (menus) this.availableMenus = menus
      return Promise.resolve(menus)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      this.loadingStep = false
    }
  }

  @action
  reset = () => {
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'loadingInitial', false)
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'loadingStep', false)
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'loadingSubmitting', false)
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'currentStep', 1)
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'currentOrder', null)
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'formValues', null)
  }
}
