import { useState } from 'react'

import {
  AcButton,
  AcCheckbox,
  AcCheckboxGroup,
  AcColumn,
  AcFormInput,
  AcFormProvider,
  AcIcon,
  AcRow,
  AcTextInput,
  AcTypography,
} from '@components'
import { ICONS, LABELS } from '@constants'
import { getFormRoleOptions } from '@helpers'
import { useStore } from '@hooks'
import { AcDeleteUserModal } from '@partials'
import { IAxiosError, ISinglePortalUser } from '@typings'

interface IAcEditUserForm {
  user: ISinglePortalUser
}

const FormInputs = ({ disabled }: { disabled?: boolean }) => {
  const { user } = useStore()
  return (
    <>
      <AcTypography
        fontWeight="bold"
        color="green.main">
        {LABELS.LOGIN_DETAILS}
      </AcTypography>
      <AcFormInput
        register="first_name"
        Component={
          <AcTextInput
            label={LABELS.FIRST_NAME}
            disabled={disabled}
            required
          />
        }
      />
      <AcFormInput
        register="middle_name"
        Component={
          <AcTextInput
            label={LABELS.MIDDLE_NAME}
            disabled={disabled}
          />
        }
      />
      <AcFormInput
        register="last_name"
        Component={
          <AcTextInput
            label={LABELS.LAST_NAME}
            disabled={disabled}
            required
          />
        }
      />
      <AcFormInput
        register="email"
        Component={
          <AcTextInput
            label={LABELS.EMAIL_ADDRESS}
            disabled
            required
          />
        }
      />
      <AcTypography
        fontWeight="bold"
        color="green.main">
        {LABELS.SETTINGS}
      </AcTypography>
      <AcTypography
        component="span"
        color="green.main">
        {LABELS.USER_ROLES}
      </AcTypography>
      <AcFormInput
        register="roles"
        Component={
          <AcCheckboxGroup
            variant="default"
            id="user-roles"
            options={getFormRoleOptions(user.hasApp).all}
          />
        }
      />
      <AcTypography
        component="span"
        color="green.main">
        {LABELS.DELIVERY_SLIP}
      </AcTypography>
      <AcFormInput
        register="receives_tickets"
        Component={
          <AcCheckbox
            variant="default"
            id="receives_tickets"
            label={LABELS.RECEIVES_DELIVERY_SLIP}
            disabled={disabled}
          />
        }
      />
    </>
  )
}

export const ACEditUserForm = ({ user }: IAcEditUserForm) => {
  const { toaster, dialog, users } = useStore()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState<IAxiosError['errorFields'] | any>(null)

  const handleUserDelete = (e: any) => {
    e.preventDefault()
    dialog.open({
      title: LABELS.DELETE_USER,
      content: <AcDeleteUserModal userId={user.id} />,
    })
  }

  const onSubmit = async (payload: ISinglePortalUser) => {
    setIsSubmitting(true)

    try {
      await users.updateSingleUser(user.id, payload).then(() => {
        toaster.success({
          title: `Update doorgevoerd voor ${user.first_name}`,
          content: 'De update is met succes doorgevoerd.',
        })
        users.getUsersList()
        setTimeout(() => {
          dialog.close()
        }, 250)
      })
    } catch (error: any) {
      if (error.type === 'axios') {
        setErrors(error.errorFields)
      }
      toaster.error({
        title: 'Er is iets mis gegaan',
        content: error?.message,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const initial = {
    first_name: user.first_name,
    middle_name: user.middle_name,
    last_name: user.last_name,
    email: user.email,
    roles: user.roles.map(role => role.name),
    receives_tickets: user.receives_tickets,
  }

  return (
    <AcColumn mt={2}>
      <AcFormProvider
        initial={initial}
        onSubmit={onSubmit}
        errors={errors}>
        <FormInputs disabled={isSubmitting} />
        <AcRow
          justifyContent="space-between"
          alignItems="center">
          <AcButton
            disabled={isSubmitting}
            type="button"
            startIcon={<AcIcon icon={ICONS.BIN} />}
            variant="text"
            sx={{ color: 'error.main' }}
            onClick={handleUserDelete}>
            {LABELS.DELETE}
          </AcButton>
          <AcButton
            loading={isSubmitting}
            type="submit">
            {LABELS.UPDATE}
          </AcButton>
        </AcRow>
      </AcFormProvider>
    </AcColumn>
  )
}
