import { action, computed, makeAutoObservable } from 'mobx'

import { STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { IAllergy, IExtrasProduct, IPreferenceCategory } from '@typings'

export class ProductsStore {
  store: Store
  extras: Array<IExtrasProduct>
  allergies: Array<IAllergy>
  preferences: Array<IPreferenceCategory>
  loading: boolean
  productRatingLoading: boolean

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loading = false
    this.productRatingLoading = false
    this.extras = []
    this.allergies = []
    this.preferences = []
  }

  @computed
  get current_extras(): Array<IExtrasProduct> | null {
    return this.extras
  }

  @computed
  get current_allergies(): Array<IAllergy> | null {
    return this.allergies
  }

  @action
  setExtras = (extras: Array<IExtrasProduct>) => {
    this.extras = extras
  }

  @action
  resetExtras = () => {
    this.extras = []
  }

  @action
  setAllergies = (allergies: Array<IAllergy>) => {
    this.allergies = allergies
  }

  @action
  setPreferences = (preferences: Array<IPreferenceCategory>) => {
    this.preferences = preferences
  }

  @action
  getExtras = async (week: string) => {
    try {
      const { data } = await this.store.api.products.extras(week)
      this.setExtras(data)
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  @action
  getAllergies = async () => {
    try {
      const { data } = await this.store.api.products.allergies()
      this.setAllergies(data)
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  @action
  getPreferences = async () => {
    try {
      const { data } = await this.store.api.products.preferences()
      this.setPreferences(data)
      return Promise.resolve(data)
    } catch (e) {
      return Promise.reject(e)
    }
  }

  @action
  setProductRating = async (productId: number, rating: number) => {
    this.store.set(STORE_KEYS.PRODUCTS, 'productRatingLoading', true)
    try {
      const res = await this.store.api.products.setProductRating(
        productId,
        rating,
      )
      return Promise.resolve(res)
    } catch (e) {
      return Promise.reject(e)
    } finally {
      this.store.set(STORE_KEYS.PRODUCTS, 'productRatingLoading', false)
    }
  }

  @action
  reset = () => {
    this.extras = []
    this.allergies = []
  }
}
