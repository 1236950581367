import React from 'react'
import { Button } from '@mui/material'

import { THEME_COLORS } from '@constants'

interface IAcSwiperArrow {
  children: React.ReactNode
  onClick: () => void
  disabled?: boolean
}

export const AcSwiperArrow = ({
  children,
  onClick,
  disabled,
}: IAcSwiperArrow) => {
  return (
    <Button
      disabled={disabled}
      sx={{
        minWidth: 'unset',
        padding: 0,
        width: '32px',
        height: '32px',
        border: `1px solid ${THEME_COLORS.BRAND_GREEN}`,
        borderRadius: '4px',
        svg: {
          height: '24px',
        },
      }}
      onClick={onClick}>
      {children}
    </Button>
  )
}
