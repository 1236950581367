import { useMemo } from 'react'
import { Chip, useTheme } from '@mui/material'
import { calcFontSize } from 'src/theme/typography.theme'

export interface IAcTag {
  label: string
  color?: 'green' | 'pink' | 'yellow' | 'salmon' | 'white'
  large?: boolean
  border?: string
  onDelete?: (label: string) => void
}

export const AcTag = ({
  label,
  color = 'green',
  border = '0',
  large = false,
  onDelete,
}: IAcTag) => {
  const bgcolor = useMemo(() => {
    switch (color) {
      default:
      case 'green':
        return 'rgba(130, 173, 153, 0.30)'

      case 'pink':
        return 'rgba(243, 197, 194, 0.40)'

      case 'yellow':
        return 'rgba(254, 203, 68, 0.60)'

      case 'salmon':
        return 'rgba(232, 104, 68, 0.50)'

      case 'white':
        return '#fff'
    }
  }, [color])

  const styles = useMemo(() => {
    if (large) {
      return {
        borderRadius: '9px',
        paddingInline: '16px',
        paddingBlock: '8px',
        span: {
          padding: 0,
        },
      }
    }

    return {}
  }, [color])

  return (
    <Chip
      sx={{
        bgcolor,
        fontSize: calcFontSize(large ? 15 : 12),
        border,
        ...styles,
      }}
      label={label}
      onDelete={onDelete}
    />
  )
}
