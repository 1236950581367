import {
  AcCancelLunchForm,
  AcChangePasswordForm,
  AcLuncherPreferencesOverviewModal,
  AcLunchReminderForm,
} from '@partials'

import { LABELS } from './labels.constants'

export const DIALOGS = {
  SEND_REMINDER: {
    title: 'Herinnering sturen',
    subTitle:
      "Stuur een bericht om collega's te herinneren zich aan te melden voor de lunch",
    content: <AcLunchReminderForm />,
    maxWidth: 600,
  },
  LUNCH_PREFERENCES: {
    title: LABELS.LUNCH_PREFERENCES,
    content: <AcLuncherPreferencesOverviewModal />,
    maxWidth: 448,
  },
  CHANGE_PASSWORD: {
    title: LABELS.CHANGE_PASSWORD,
    subTitle: 'Stel een nieuw wachtwoord in.',
    content: <AcChangePasswordForm />,
    maxWidth: 448,
  },
  NO_LUNCH_THIS_WEEK: (weekIdentifier: string) => ({
    title: 'Deze week geen lunch',
    content: <AcCancelLunchForm weekIdentifier={weekIdentifier} />,
    maxWidth: 448,
  }),
}
