import { isEmptyObject } from '@helpers'

interface IMakeGroupArray {
  key: string
  data: any[]
  count?: number
}

export const makeGroupArray = (array: any, key: string) => {
  const grouped = array.reduce(function (r: any, a: any) {
    r[a[key]] = r[a[key]] || []
    r[a[key]].push(a)
    return r
  }, Object.create(null))

  if (isEmptyObject(grouped)) return []

  const newArray: IMakeGroupArray[] = Object.entries(grouped).map(
    ([key, value]) => ({
      key,
      data: value as any[],
      count: Array.isArray(value) ? value.length : 0,
    }),
  )
  return newArray
}
