import { useMemo } from 'react'
import Slide, { SlideProps } from '@mui/material/Slide'
import Snackbar from '@mui/material/Snackbar'
import { observer } from 'mobx-react-lite'

import { AcAlert } from '@components'
import { useBreakpoints } from '@hooks'
import { useStore } from '@hooks'

function SlideTransition(props: SlideProps) {
  const { isMobile } = useBreakpoints()

  return (
    <Slide
      {...props}
      direction={isMobile ? 'down' : 'up'}
    />
  )
}

export const AcToaster = observer(() => {
  const { toaster } = useStore()
  const { isMobile } = useBreakpoints()

  const snackbarPosition: {
    vertical: 'top' | 'bottom'
    horizontal: 'center' | 'right' | 'left'
  } = useMemo(() => {
    if (isMobile) {
      return {
        vertical: 'top',
        horizontal: 'center',
      }
    }
    return {
      vertical: 'bottom',
      horizontal: 'right',
    }
  }, [isMobile])

  return (
    <Snackbar
      open={toaster.is_open}
      autoHideDuration={toaster?.timeout || 6000}
      onClose={toaster.close}
      message={toaster.content}
      TransitionComponent={SlideTransition}
      anchorOrigin={snackbarPosition}>
      <AcAlert
        severity={toaster.severity}
        title={toaster.title}
        variant="standard"
        onClose={toaster.close}>
        {toaster.content}
      </AcAlert>
    </Snackbar>
  )
})
