import { useState } from 'react'
import type { IAxiosError, IErrorResponse } from '@typings'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcColumn,
  AcFormInput,
  AcFormProvider,
  AcPasswordInput,
  AcRow,
  AcTypography,
} from '@components'
import { LABELS, REGEX } from '@constants'
import { useInputCompare, useStore } from '@hooks'

import { TOASTERS } from '../../../constants/toasters.constants'

const FormInputs = () => {
  const { checkPasswordMatch } = useInputCompare(
    'password',
    'password_confirmation',
  )

  return (
    <>
      <AcTypography
        removePadding
        bold
        color="green.main">
        {LABELS.ENTER_CURRENT_PASSWORD}
      </AcTypography>
      <AcFormInput
        register="current_password"
        rules={{
          required: 'required',
          pattern: {
            value: REGEX.PASSWORD,
            message: LABELS.FIELD_VALIDATION_PW_REGEX,
          },
        }}
        Component={
          <AcPasswordInput
            label={LABELS.CURRENT_PASSWORD}
            required
          />
        }
      />
      <AcTypography
        removePadding
        bold
        color="green.main">
        {LABELS.SET_NEW_PASSWORD}
      </AcTypography>
      <AcFormInput
        register="password"
        rules={{
          required: 'required',
          pattern: {
            value: REGEX.PASSWORD,
            message: LABELS.FIELD_VALIDATION_PW_REGEX,
          },
        }}
        Component={
          <AcPasswordInput
            label={LABELS.NEW_PASSWORD}
            allowVisible={true}
            required
            onBlur={checkPasswordMatch}
          />
        }
      />
      <AcFormInput
        register="password_confirmation"
        rules={{
          required: 'required',
          pattern: {
            value: REGEX.PASSWORD,
            message: LABELS.FIELD_VALIDATION_PW_REGEX,
          },
        }}
        Component={
          <AcPasswordInput
            label={LABELS.CONFIRM_NEW_PASSWORD}
            allowVisible={true}
            required
            onBlur={checkPasswordMatch}
          />
        }
      />
    </>
  )
}

export const AcChangePasswordForm = observer(() => {
  const { dialog, auth, toaster } = useStore()
  const [errors, setErrors] = useState<IAxiosError['errorFields'] | any>(null)

  const onSubmit = async (values: any) => {
    try {
      await auth.postChangePassword(values)
      dialog.close()
      toaster.success(TOASTERS.AUTH.PASSWORD_CHANGED)
    } catch (error) {
      const err = error as IErrorResponse
      if (err.type === 'axios') {
        setErrors(err.errorFields)
        if (!err.errorFields) {
          toaster.error({ content: err.message })
        }
      } else {
        toaster.error({ content: err.message })
      }
    }
  }

  return (
    <AcFormProvider
      errors={errors}
      initial={[]}
      onSubmit={onSubmit}>
      <AcColumn gap={2}>
        <FormInputs />
        <AcRow
          justifyContent={'space-between'}
          alignItems={'center'}>
          <AcButton
            onClick={() => dialog.close()}
            variant="text">
            {LABELS.CANCEL}
          </AcButton>
          <AcButton
            loading={auth.loading}
            type="submit">
            {LABELS.CHANGE_PASSWORD}
          </AcButton>
        </AcRow>
      </AcColumn>
    </AcFormProvider>
  )
})
