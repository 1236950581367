import { AcColumn, AcTypography } from '@components'
import { LABELS } from '@constants'
import { AcLuncherPreferenceForm } from '@partials'

export const AcLuncherPreferencesModal = () => {
  return (
    <AcColumn
      height="100%"
      flexGrow={1}>
      <AcTypography>{LABELS.WHAT_ARE_YOUR_PREFERENCES_SUBTITLE}</AcTypography>
      <AcLuncherPreferenceForm nextStep={false} />
    </AcColumn>
  )
}
