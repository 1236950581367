import { LABELS } from './labels.constants'

export const TOASTERS = {
  COPIED_TO_CLIPBOARD: {
    content: LABELS.COPIED_TO_CLIPBOARD,
    timeout: 3000,
  },

  AUTH: {
    SUCCESS: {
      content: LABELS.LOGIN_SUCCESSFUL,
    },
    FAILED: {
      content: LABELS.LOGIN_FAILED,
    },

    PASSWORD_CHANGED: {
      content: LABELS.PASSWORD_CHANGED,
    },

    PASSWORD_RESET_REQUESTED: {
      content: 'Wachtwoord reset aangevraagd.',
    },
  },

  GENERIC_FAIL: {
    content: 'Er is iets fout gegaan, probeer opnieuw.',
  },

  SHARE_SUCCESSFUL: {
    content: 'Herinnering succesvol gedeeld',
  },
}
