import { AcTag } from '@components'
import { LABELS } from '@constants'
import { IOrderStatusEnum } from '@typings'

interface IAcOrderStatusChip {
  status?: IOrderStatusEnum
}

export const AcOrderStatusChip = ({ status }: IAcOrderStatusChip) => {
  if (!status) return null
  switch (status) {
    case 'pending':
      return <AcTag label={LABELS.PENDING} />

    case 'confirmed':
      return <AcTag label={LABELS.CONFIRMED} />

    case 'fulfilled':
      return <AcTag label={LABELS.FULFILLED} />

    case 'cancelled':
      return (
        <AcTag
          color="pink"
          label={LABELS.CANCELLED}
        />
      )

    default:
      return null
  }
}
