import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { AcColumn } from '@components'
import { IErrorField } from '@typings'

export interface IAcForm {
  children: React.ReactNode
  initial: object | Function
  onSubmit: (data: any) => void
  flexGrow?: number
  errors?: IErrorField[]
}

export const AcFormProvider = ({
  children,
  initial,
  onSubmit,
  flexGrow,
  errors,
}: IAcForm) => {
  const methods = useForm<typeof initial>({
    defaultValues: initial,
  })

  useEffect(() => {
    if (errors?.length) {
      errors.forEach(err =>
        // @ts-ignore
        methods.setError(err.field, { type: 'custom', message: err.value }),
      )
    }
  }, [errors])

  return (
    <FormProvider {...methods}>
      <AcColumn
        gap={0}
        component="form"
        onSubmit={methods.handleSubmit(onSubmit)}
        flexGrow={flexGrow}>
        {children}
      </AcColumn>
    </FormProvider>
  )
}
