import { FormControl, FormGroup } from '@mui/material'

import {
  AcCheckboxContained,
  AcCheckboxDefault,
  AcHelpertext,
} from '@components'

export interface IAcCheckbox {
  id: string
  label?: string
  value?: any
  onChange?: (value: IAcCheckbox['value']) => void
  trueValue?: any
  falseValue?: any
  variant?: 'contained' | 'default'
  fullWidth?: boolean
  error?: string
  [x: string]: any
}

export const AcCheckbox = ({
  id,
  label,
  value,
  onChange,
  trueValue = true,
  falseValue = false,
  variant = 'contained',
  fullWidth = false,
  error,
  ...props
}: IAcCheckbox) => {
  const CheckboxEl =
    variant === 'contained' ? AcCheckboxContained : AcCheckboxDefault

  const handleChange = () => {
    if (value !== trueValue) {
      if (onChange) onChange(trueValue)
    } else {
      if (onChange) onChange(falseValue)
    }
  }

  return (
    <FormControl fullWidth={fullWidth}>
      <FormGroup
        onChange={handleChange}
        aria-labelledby={id}>
        <CheckboxEl
          label={label}
          value={value}
          checked={value === trueValue}
          {...props}
        />
      </FormGroup>
      {error && (
        <AcHelpertext
          text={error}
          isError
        />
      )}
    </FormControl>
  )
}
