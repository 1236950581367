import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import type { IAxiosError, IErrorResponse } from '@typings'

import {
  AcButton,
  AcColumn,
  AcFormInput,
  AcFormProvider,
  AcRow,
  AcTextarea,
} from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks'

interface IAcCancelLunchForm {
  weekIdentifier: string
}

export const AcCancelLunchForm = ({ weekIdentifier }: IAcCancelLunchForm) => {
  const { dialog, weeks, toaster } = useStore()
  const navigate = useNavigate()
  const [errors, setErrors] = useState<IAxiosError['errorFields'] | any>(null)

  const onSubmit = async (data: any) => {
    try {
      await weeks.cancel(weekIdentifier, data.comment)
      toaster.success({
        content: 'Lunch is succesvol geannuleerd',
      })
      navigate(NAVIGATE_TO.ADMIN_DASHBOARD)
      dialog.close()
    } catch (error: any) {
      const err = error as IErrorResponse
      if (err.type === 'axios') {
        setErrors(err.errorFields)
        if (!err.errorFields) {
          toaster.error({ content: err.message })
        }
      } else {
        toaster.error({ content: err.message })
      }

      toaster.error({
        title: 'Let op',
        content: error?.message || 'Er is iets mis gegaan',
      })
    }
  }

  return (
    <AcFormProvider
      onSubmit={onSubmit}
      flexGrow={1}
      initial={{ comment: '' }}
      errors={errors}>
      <AcFormInput
        register="comment"
        Component={<AcTextarea label="Reden" />}
      />
      <AcColumn gap={6}>
        <AcRow
          justifyContent="space-between"
          alignItems="center">
          <AcButton
            onClick={() => dialog.close()}
            variant="text">
            {LABELS.CANCEL}
          </AcButton>
          <AcButton type="submit">{LABELS.SUBMIT}</AcButton>
        </AcRow>
      </AcColumn>
    </AcFormProvider>
  )
}
