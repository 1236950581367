import { cloneElement, useEffect } from 'react'
import { useLocation, useNavigate, useOutlet } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import { AnimatePresence } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import {
  AcAppBackground,
  IAcAppBackground,
} from 'src/components/core/ac-app-background/ac-app-background'
import { AcNavbarBottom } from 'src/components/features/ac-navbar-bottom/ac-navbar-bottom'

import { AcNavbar, AcNotifications, AcSection } from '@components'
import { useStore } from '@hooks'

export interface IAcDefaultLayout {
  variant: IAcAppBackground['variant']
}

const AnimatedOutlet = () => {
  const location = useLocation()
  const element = useOutlet()

  return (
    <AnimatePresence
      mode="wait"
      initial={true}>
      {element && cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  )
}

export const AcDefaultLayout = observer(({ variant }: IAcDefaultLayout) => {
  return (
    <AcAppBackground variant={variant}>
      <AcNavbar variant={variant} />
      <AcSection>
        <AnimatedOutlet />
      </AcSection>
      <AcNavbarBottom />
      <AcNotifications />
    </AcAppBackground>
  )
})
