import { useMemo, useState } from 'react'
import { TabProps } from '@mui/material/Tab/Tab'

import { useBreakpoints } from '@hooks'

import { AcTabPanel } from './ac-tab-panel'
import { Tab, Tabs } from './styledTabs'

export interface IAcMuiTab extends TabProps {
  small?: boolean
}

export interface IAcTab {
  title: string
  disabled?: boolean
  content: React.ReactNode
}

export interface IAcTabs {
  tabs: IAcTab[]
  initialActive?: number
  small?: boolean
  gap?: number
}

export const AcTabs = ({
  tabs,
  initialActive = 0,
  gap = 6,
  small,
}: IAcTabs) => {
  const { isMobile } = useBreakpoints()
  const [activeIndex, setActiveIndex] = useState(initialActive)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveIndex(newValue)
  }

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    }
  }

  const renderTabs = useMemo(() => {
    return tabs.map((tab, i) => (
      <Tab
        key={i}
        tabIndex={0}
        label={tab.title}
        disabled={tab.disabled}
        small={isMobile || small}
        {...a11yProps(i)}
      />
    ))
  }, [tabs, isMobile])

  const renderTabPanels = useMemo(() => {
    return tabs.map((tab, i) => (
      <AcTabPanel
        key={i}
        activeIndex={activeIndex}
        index={i}
        gap={gap}>
        {tab.content}
      </AcTabPanel>
    ))
  }, [tabs, activeIndex])

  return (
    <>
      <Tabs
        value={activeIndex}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={isMobile}>
        {renderTabs}
      </Tabs>
      {renderTabPanels}
    </>
  )
}
