import { useEffect, useMemo, useState } from 'react'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import { useTheme } from '@mui/material'
import type { Swiper as SwiperProps } from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { AcColumn, AcExtrasCard, AcRow } from '@components'
import { AcSwiperArrow } from '@components'
import { useBreakpoints } from '@hooks'

import 'swiper/css/pagination'
import 'swiper/css/navigation'

import './swiper-overrides.module.scss'
import 'swiper/css'

interface IAcExtrasSwiper {
  items: any[] | null
  withInputs?: boolean
  registerKey?: string
}

const COLORS = ['pink', 'green', 'orange', 'yellow']

const getCardColor = (index: number) => {
  const position = (index + 1) % 4
  return COLORS[position - 1] || 'yellow'
}

export const AcExtrasSwiper = ({
  items,
  withInputs = false,
  registerKey = '',
}: IAcExtrasSwiper) => {
  if (!items) return null
  const [swiperEl, setSwiperEl] = useState<SwiperProps | null>(null)
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)
  const { isMobile } = useBreakpoints()

  const handleNext = () => {
    swiperEl?.slideNext()
  }

  const handlePrev = () => {
    swiperEl?.slidePrev()
  }

  const theme = useTheme()

  const cards = useMemo(() => {
    return items?.map((item: any, index: number) => (
      <AcExtrasCard
        key={index}
        {...item}
        withInput={withInputs}
        registerKey={`${registerKey}-${item.id}`}
        cardColor={getCardColor(index)}
      />
    ))
  }, [items])

  const renderSlides = useMemo(() => {
    return cards.map((slide, i) => (
      <SwiperSlide
        style={{ width: 'auto' }}
        key={i}>
        {slide}
      </SwiperSlide>
    ))
  }, [handlePrev, handleNext, isMobile, items])

  return (
    <AcColumn>
      <AcRow>
        <Swiper
          spaceBetween={theme.spacing(4)}
          slidesPerView="auto"
          modules={[Navigation, Pagination]}
          pagination={{
            bulletClass: 'ac-swiper-pagination__bullet',
            bulletActiveClass: 'ac-swiper-pagination__bullet--active',
            clickable: true,
            el: '.ac-swiper-pagination',
          }}
          onSwiper={setSwiperEl}
          onSlideChange={swiper => {
            setIsBeginning(swiper.isBeginning)
            setIsEnd(swiper.isEnd)
          }}>
          {renderSlides}
        </Swiper>
      </AcRow>
      <AcRow justifyContent="space-between">
        <AcRow gap={2}>
          <AcSwiperArrow
            disabled={isBeginning}
            onClick={handlePrev}>
            <ArrowBackOutlinedIcon />
          </AcSwiperArrow>
          <AcSwiperArrow
            disabled={isEnd}
            onClick={handleNext}>
            <ArrowForwardOutlinedIcon />
          </AcSwiperArrow>
        </AcRow>
        <AcRow>
          <AcColumn className="ac-swiper-pagination"></AcColumn>
        </AcRow>
      </AcRow>
    </AcColumn>
  )
}
