import { useNavigate } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'

import { AcBox, AcButton, AcIcon, AcRow } from '@components'
import { ICONS, LABELS } from '@constants'
import { useBreakpoints, useStore } from '@hooks'

interface IAcOrderFooter {
  previousStep?: string
  islastStep?: boolean
  loading?: boolean
}

export const AcOrderFooter = ({
  previousStep,
  loading,
  islastStep,
}: IAcOrderFooter) => {
  const { isMobile } = useBreakpoints()
  const navigate = useNavigate()
  const { user } = useStore()

  return (
    <AcRow
      mt={4}
      sx={{
        flexDirection: isMobile ? 'column-reverse' : 'row',
        justifyContent: 'space-between',
        width: '100%',
      }}
      alignItems="center">
      <AcButton
        variant="text"
        disabled={loading}
        onClick={() => navigate(NAVIGATE_TO.ADMIN_DASHBOARD)}>
        {LABELS.CANCEL}
      </AcButton>
      <AcBox
        display="flex"
        gap={4}
        width="100%"
        justifyContent="flex-end"
        flexDirection={isMobile ? 'column-reverse' : 'row'}>
        {previousStep && !user?.isBasicSubscription && (
          <AcButton
            onClick={() => navigate(previousStep)}
            startIcon={<AcIcon icon={ICONS.BACK} />}
            variant="secondary"
            disabled={loading}
            fullWidth={isMobile}>
            {LABELS.BACK}
          </AcButton>
        )}
        <AcButton
          type="submit"
          variant="primary"
          loading={loading}
          fullWidth={isMobile}
          endIcon={!islastStep && <AcIcon icon={ICONS.NEXT} />}>
          {islastStep ? LABELS.SUBMIT_ORDER : LABELS.NEXT}
        </AcButton>
      </AcBox>
    </AcRow>
  )
}
