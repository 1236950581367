import { useMemo, useState } from 'react'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

import {
  AcButton,
  AcCard,
  AcCharts,
  AcColumn,
  AcOrderExtrasPerDay,
  AcRow,
  AcTable,
  AcTypography,
} from '@components'
import { LABELS, WEEKDAYS_LABELS } from '@constants'
import { formatPrice } from '@helpers'
import { useStore } from '@hooks'
import {
  IAcChartDataProps,
  IOrderDetails,
  IOrderDetailsOverviewSoupOrSalad,
} from '@typings'

export const AcOrderDetailsOverviewTab = (order: IOrderDetails) => {
  const { weeks, toaster, user } = useStore()

  const [isDownloading, setIsDownloading] = useState(false)

  const downloadList = async () => {
    const weekIdentifier = `${order.week}-${order.year}`
    setIsDownloading(true)
    try {
      await weeks.downloadList(weekIdentifier)
      toaster.success({
        content: `De luncherlijst voor week ${order.week} is gedownload`,
      })
    } catch (error: any) {
      toaster.error({
        title: 'Let op',
        content:
          error?.message || 'Er ging iets mis, probeer het later nog een keer',
      })
    } finally {
      setIsDownloading(false)
    }
  }

  const renderChart = useMemo(() => {
    const data = order.overview?.lunchers
    if (!data?.length) return null
    const chart: IAcChartDataProps = {
      type: 'single',
      legend: 'Leganda',
      categories: data.map(data => ({
        label:
          WEEKDAYS_LABELS[
            data.day.toUpperCase() as keyof typeof WEEKDAYS_LABELS
          ],
        subLabel: 'blabla',
      })),
      datasets: [
        {
          name: 'Aantallen',
          data: data.map(data => data.count),
        },
      ],
    }
    return chart
  }, [order.overview?.lunchers])

  const preferenceRow = (input: string | IOrderDetailsOverviewSoupOrSalad) => {
    return (
      <AcTable.Row>
        <AcTable.Cell>
          {typeof input === 'string' ? (
            <AcTypography component="span">{input}</AcTypography>
          ) : (
            <AcColumn gap={1}>
              <AcTypography
                fontWeight="bold"
                component="span"
                color="green.main">
                {input?.name}
              </AcTypography>
              <AcTypography component="span">{input?.description}</AcTypography>
            </AcColumn>
          )}
        </AcTable.Cell>
      </AcTable.Row>
    )
  }

  const renderSoupPreference = useMemo(() => {
    if (!order.overview?.soups?.length) return null
    return (
      <AcTable
        head={[LABELS.SOUP_PREFERENCE]}
        rows={order.overview.soups.map(pref => preferenceRow(pref))}
      />
    )
  }, [order.overview?.soups])

  const renderSaladsPreference = useMemo(() => {
    if (!order.overview?.salads?.length) return null
    return (
      <AcTable
        head={[LABELS.SALAD_PREFERENCE]}
        rows={order.overview.salads.map(pref => preferenceRow(pref))}
      />
    )
  }, [order.overview?.salads])

  const renderComment = useMemo(() => {
    if (!order?.overview?.comment) return null
    return (
      <AcTable
        head={[LABELS.ORDER_COMMENTS]}
        rows={[preferenceRow(order.overview.comment)]}
      />
    )
  }, [order.overview?.comment])

  const renderExtras = useMemo(() => {
    if (!order.overview?.extras?.days.length) return null
    return (
      <AcColumn>
        <AcTable
          head={[{ title: LABELS.EXTRAS, colSpan: 5 }]}
          rows={order.overview?.extras?.days.map(day => (
            <AcOrderExtrasPerDay {...day} />
          ))}
        />
        <AcCard>
          <AcRow justifyContent="space-between">
            <AcTypography
              component="span"
              color="green.main">
              <span style={{ fontWeight: 600 }}>{LABELS.TOTAL}</span> (
              {LABELS.EXCL_TAXES}){' '}
            </AcTypography>
            <AcTypography
              component="span"
              fontWeight="bold"
              color="green.main">
              {formatPrice(order.overview.extras.total)}
            </AcTypography>
          </AcRow>
        </AcCard>
      </AcColumn>
    )
  }, [order.overview?.extras])

  const renderDownloadButton = useMemo(() => {
    if (!user?.hasApp) return null
    return (
      <AcButton
        onClick={downloadList}
        startIcon={<FileDownloadOutlinedIcon />}
        variant="text"
        loading={isDownloading}>
        {LABELS.DOWNLOAD_LUNCHER_LIST}
      </AcButton>
    )
  }, [weeks.current?.download, isDownloading])

  return (
    <AcColumn>
      <AcCard>
        <AcRow justifyContent="space-between">
          <AcTypography
            removePadding
            component="h3"
            color="green.main">
            Aantallen
          </AcTypography>
          {order.download && renderDownloadButton}
        </AcRow>
        {renderChart && <AcCharts chartData={renderChart} />}
      </AcCard>
      {renderSoupPreference}
      {renderSaladsPreference}
      {renderComment}
      {renderExtras}
    </AcColumn>
  )
}
