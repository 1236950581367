import type { IAxiosError, IDefaultError } from '@typings'
import type { AxiosError } from 'axios'
import axios from 'axios'

export const extractAPIFieldErrors = (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    // Access to config, request, and response
    const errorData = error.response?.data
    const errorArray = []
    if (errorData.errors) {
      for (const [key, value] of Object.entries(errorData.errors)) {
        errorArray.push({ field: key, value: (value as string[]).join(',') })
      }
    }
    return {
      success: false,
      error: true,
      type: 'axios',
      status: error.response?.status,
      message: errorData?.message || error.message,
      errorFields: errorArray,
    } as IAxiosError
  } else {
    // Just a stock error
    return {
      success: false,
      error: true,
      type: 'error',
      message: error.message,
    } as IDefaultError
  }
}
