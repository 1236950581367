import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import { NAVIGATE_TO } from '@routes'

import { AcIcon } from '@components'

type AdminNavigationId = 'DASHBOARD' | 'ORDERS' | 'PROFILE'
type LucherNavigationId = 'ATTENDANCE' | 'PREFERENCES'

interface IBaseMenuItem {
  href: string
  label: string
  icon: React.ReactNode
}

interface IAdminMenuItem extends IBaseMenuItem {
  id: AdminNavigationId
}

interface ILuncherMenuItem extends IBaseMenuItem {
  id: LucherNavigationId
}

export type IAcMenuItem = IAdminMenuItem | ILuncherMenuItem

export const AdminNavigation: IAdminMenuItem[] = [
  {
    id: 'DASHBOARD',
    href: NAVIGATE_TO.ADMIN_DASHBOARD,
    label: 'Dashboard',
    icon: <DashboardOutlinedIcon />,
  },
  {
    id: 'ORDERS',
    href: NAVIGATE_TO.ADMIN_ORDERS,
    label: 'Bestellingen',
    icon: <ListAltRoundedIcon />,
  },
  {
    id: 'PROFILE',
    href: NAVIGATE_TO.ADMIN_PROFILE,
    label: 'Lunchprofiel',
    icon: <ManageAccountsOutlinedIcon />,
  },
]

export const LuncherNavigation: ILuncherMenuItem[] = [
  {
    id: 'ATTENDANCE',
    href: NAVIGATE_TO.LUNCHER_OVERVIEW,
    label: 'Aanwezigheid',
    icon: <AcIcon icon="PERSON_EDIT" />,
  },
  {
    id: 'PREFERENCES',
    href: NAVIGATE_TO.LUNCHER_PREFERENCES,
    label: 'Voorkeuren',
    icon: <ArticleOutlinedIcon />,
  },
]
