import type {
  IAllergy,
  IPreferenceEnum,
  IProfileResponse,
  IRole,
  IUser,
  IWeekAttendance,
  IWeekDay,
} from '@typings'
import { AxiosResponse } from 'axios'

import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'

export class UserApi extends AxiosBaseApi {
  // --- GET ---
  getSingle = (id: IUser['id']) =>
    this.api
      .get<AxiosResponse<{ data: IUser }>>(ENDPOINTS.USERS.SINGLE(id))
      .then(({ data }) => data.data)

  whoAmI = () =>
    this.api
      .get<AxiosResponse<{ data: IProfileResponse }>>(ENDPOINTS.USER.WHO_AM_I)
      .then(({ data }) => data.data)

  getPreference = () =>
    this.api
      .get<AxiosResponse<{ data: IPreferenceEnum }>>(ENDPOINTS.USER.PROFILE)
      .then(({ data }) => data.data)

  getRoles = () =>
    this.api
      .get<AxiosResponse<{ data: IRole[] }>>(ENDPOINTS.USER.ROLES)
      .then(({ data }) => data.data)

  getAllergies = () =>
    this.api
      .get<AxiosResponse<{ data: IAllergy['id'][] }>>(ENDPOINTS.USER.PROFILE)
      .then(({ data }) => data.data)

  getDefaultAttendance = () =>
    this.api
      .get<AxiosResponse<{ data: IWeekDay[] }>>(ENDPOINTS.USER.PROFILE)
      .then(({ data }) => data.data)

  // --- PATCH ---
  patchDefaultAttendance = (payload: IWeekDay[]) =>
    this.api
      .patch<
        AxiosResponse<{ data: IUser }>
      >(ENDPOINTS.USER.PROFILE, { days: payload })
      .then(({ data }) => data.data)

  patchPreference = (payload: IPreferenceEnum) =>
    this.api
      .patch<
        AxiosResponse<{ data: IUser }>
      >(ENDPOINTS.USER.PROFILE, { preference: payload })
      .then(({ data }) => data.data)

  patchAllergies = (payload: IAllergy['id'][]) =>
    this.api
      .patch<AxiosResponse<{ data: IUser }>>(ENDPOINTS.USER.PROFILE, payload)
      .then(({ data }) => data.data)

  // --- POST ---
  postWeeklyAttendance = (payload: {
    weekIdentifier: string
    days: IWeekDay[]
  }) =>
    this.api
      .post<AxiosResponse<{ data: IWeekAttendance }>>(ENDPOINTS.LUNCHER.WEEKS, {
        week_identifier: payload.weekIdentifier,
        days: payload.days,
      })
      .then(({ data }) => data.data)
}
