import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import { AcColumn, AcFormProvider, AcOrderFooter } from '@components'
import { useStore } from '@hooks'
import {
  AcOrdersummaryExtrasForminputs,
  AcOrdersummaryExtrasPricingDaily,
  AcOrdersummaryExtrasPricingSum,
} from '@partials'

export const AcOrderSummaryForm = observer(() => {
  const navigate = useNavigate()
  const { weekIdentifier } = useParams()
  const { currentOrder, products, toaster } = useStore()
  const [errors, setErrors] = useState()

  const onSubmit = async (body: any) => {
    try {
      const order = await currentOrder.createOrder(body, products.extras)
      if (order && weekIdentifier) {
        navigate(NAVIGATE_TO.ORDER_COMPLETED(weekIdentifier))
      }
    } catch (error: any) {
      if (error.type === 'axios') {
        setErrors(error.errorFields)
      }
      toaster.error({
        title: 'Nog niet alle velden zijn juist ingevuld',
        content: error.message,
      })
    }
  }

  return (
    <AcFormProvider
      initial={currentOrder.formValues}
      onSubmit={onSubmit}
      errors={errors}>
      <AcColumn>
        {products.current_extras && (
          <>
            <AcOrdersummaryExtrasForminputs />
            <AcOrdersummaryExtrasPricingDaily />
            <AcOrdersummaryExtrasPricingSum />
          </>
        )}
        <AcOrderFooter
          islastStep
          loading={currentOrder.watchLoadingSubmitting}
          previousStep={
            weekIdentifier && NAVIGATE_TO.ORDER_PREFERENCES(weekIdentifier)
          }
        />
      </AcColumn>
    </AcFormProvider>
  )
})
