import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowForward from '@mui/icons-material/ArrowForward'

import { ReactComponent as Bin } from '@assets/custom-icons/bin.svg'
import { ReactComponent as DayEdit } from '@assets/custom-icons/day-edit.svg'
import { ReactComponent as DeliveryTruck } from '@assets/custom-icons/delivery-truck.svg'
import { ReactComponent as KnifeFork } from '@assets/custom-icons/knife-fork.svg'
import { ReactComponent as PersonEdit } from '@assets/custom-icons/person-edit.svg'

export const ICON_ELEMENTS = {
  BACK: ArrowBack,
  NEXT: ArrowForward,
}

export const CUSTOM_ICON_ELEMENTS = {
  PERSON_EDIT: PersonEdit,
  DAY_EDIT: DayEdit,
  DELIVERY_TRUCK: DeliveryTruck,
  KNIFE_FORK: KnifeFork,
  BIN: Bin,
}

export const ICONS = {
  BACK: 'BACK',
  NEXT: 'NEXT',
  PERSON_EDIT: 'PERSON_EDIT',
  DAY_EDIT: 'DAY_EDIT',
  DELIVERY_TRUCK: 'DELIVERY_TRUCK',
  KNIFE_FORK: 'KNIFE_FORK',
  BIN: 'BIN',
}
