import { Box } from '@mui/material'

interface IAcImage {
  src: string
  srcset?: string
  alt?: string
  [x: string]: any
  maxWidth?: number | string
}

export const AcImage = ({
  src,
  alt = '',
  srcset,
  maxWidth,
  ...props
}: IAcImage) => {
  return (
    <Box
      component="img"
      alt={alt}
      src={src}
      srcSet={srcset}
      sx={{
        maxWidth: maxWidth || props?.sx?.maxWidth || '100%',
        ...props?.sx,
      }}
    />
  )
}
