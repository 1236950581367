import { AcTextInput, IAcTextInput } from '@components'

export const AcEmailInput = ({ ...props }: IAcTextInput) => {
  return (
    <AcTextInput
      type="email"
      {...props}
    />
  )
}
