import { Height } from '@mui/icons-material'
import type { ThemeOptions } from '@mui/material'

import { THEME_COLORS } from '@constants'
import { SPACING } from '@constants'

import PosteramaRegular from '../assets/fonts/PosteramaText-400.woff2'
import PosteramaBold from '../assets/fonts/PosteramaText-600.woff2'
// Fonts
import RocaTwoBold from '../assets/fonts/RocaTwo-600.woff2'

import {
  BASE_FONT_SIZE,
  calcFontSize,
  FONT_FAMILIES,
  FONT_WEIGHTS,
} from './typography.theme'

const applySpacing = (input: number) => {
  return SPACING[input]
}

export const COMPONENTS: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'RocaTwo';
        font-style: normal;
        font-display: swap;
        font-weight: 600;
        src: local('RocaTwo'), url(${RocaTwoBold}) format('woff2');
      }
      @font-face {
        font-family: 'Posterama';
        font-style: normal;
        font-display: swap;
        font-weight: 600;
        src: local('Posterama'), url(${PosteramaBold}) format('woff2');
      }
      @font-face {
        font-family: 'Posterama';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src: local('Posterama'), url(${PosteramaRegular}) format('woff2');
      }
    `,
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        fontSize: calcFontSize(16),
        a: {
          color: THEME_COLORS.BRAND_GREEN,
          textDecoration: 'underline',
        },
      },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        '&:focus-visible': {
          border: 'none !important',
        },
      },
    },
  },
  MuiContainer: {
    defaultProps: {},
    styleOverrides: {
      root: {
        paddingInline: applySpacing(4),
        '@media (min-width:768px)': {
          paddingInline: applySpacing(6),
        },
        '@media (min-width:1200px)': {
          paddingInline: applySpacing(7),
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textDecoration: 'none',
        fontFamily: FONT_FAMILIES.POSTERAMA,
        fontWeight: FONT_WEIGHTS.BOLD,
        fontSize: BASE_FONT_SIZE,
        lineHeight: 1.2,
        paddingBlock: 16,
        paddingInline: 24,
        borderRadius: 16,
        height: 56,
        alignItems: 'center',
        '.MuiButton-icon': {
          display: 'flex',
          alignItems: 'center',
          lineHeight: 1.2,
        },
        '&:focus-visible': {
          border: `1px solid ${THEME_COLORS.NEUTRAL_WHITE}`,
          outline: `2px solid ${THEME_COLORS.ALERT_BRAND_ORANGE}`,
        },
      },
      sizeSmall: {
        borderRadius: 8,
        height: 24,
        fontSize: calcFontSize(16),
      },
      sizeLarge: {
        height: 56,
        fontSize: calcFontSize(18),
      },
    },
  },
  MuiGrid: {},
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: {
        borderRadius: 8,
      },
    },
  },
  MuiAlert: {
    defaultProps: {
      severity: 'success',
      variant: 'filled',
    },
    styleOverrides: {
      root: {
        padding: applySpacing(4),
        '.MuiAlert-icon': {
          padding: 0,
          marginRight: applySpacing(4),
        },
        '.MuiAlert-message': {
          padding: 0,
        },
        '.MuiAlert-action': {
          transform: `translate(${applySpacing(1)}px, -${applySpacing(4)}px)`,
        },
      },
      filledWarning: {
        color: THEME_COLORS.ALERT_BRAND_GREEN,
      },
      standard: {
        border: '1px solid',
        borderLeft: '6px solid',
        borderRadius: 0,
        boxShadow:
          '0px 2px 2px 0px rgba(0, 0, 0, 0.16), 0px 8px 16px 0px rgba(0, 0, 0, 0.16)',
        color: THEME_COLORS.NEUTRAL_BLACK,
      },
      standardSuccess: {
        borderColor: THEME_COLORS.ALERT_BRAND_GREEN,
      },
      standardWarning: {
        borderColor: THEME_COLORS.ALERT_BRAND_YELLOW,
      },
      standardError: {
        borderColor: THEME_COLORS.ALERT_BRAND_ORANGE,
      },
      standardInfo: {
        borderColor: THEME_COLORS.ALERT_BRAND_INFO,
      },
    },
  },
  MuiAlertTitle: {
    defaultProps: {
      fontWeight: '600 !important',
      marginBottom: 0,
    },
  },
  // Por favor, no default styling for the Tabs and Tab components
  // These are used in two different ways in the app and are styled in the components folder
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: 32,
        '@media (max-width:768px)': {
          width: '100vw',
          transform: 'translateX(-16px)',
          '>.MuiButtonBase-root': {
            width: 24,
            height: 24,
            alignSelf: 'center',
            background: THEME_COLORS.NEUTRAL_GREY_20,
            borderRadius: '50%',
            color: THEME_COLORS.BRAND_GREEN,
          },
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: calcFontSize(16),
        zIndex: 2,
      },
    },
  },
  MuiAppBar: {
    defaultProps: {
      position: 'static',
      elevation: 0,
    },
    styleOverrides: {
      root: {
        position: 'relative',
        paddingTop: applySpacing(3),
        paddingBottom: applySpacing(3) + 16,
        borderRadius: 0,
        '&:after': {
          position: 'absolute',
          content: '""',
          bottom: -16,
          left: 0,
          right: 0,
          backgroundColor: THEME_COLORS.NEUTRAL_WHITE,
          borderRadius: 25,
          height: 32,
        },
      },
      colorTransparent: {
        color: THEME_COLORS.NEUTRAL_WHITE,
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        justifyContent: 'space-between',
      },
    },
  },
  MuiBottomNavigation: {
    styleOverrides: {
      root: {
        position: 'fixed',
        bottom: applySpacing(3),
        backgroundColor: THEME_COLORS.BRAND_GREEN,
        left: '50%',
        transform: 'translateX(-50%)',
        borderRadius: 50,
        paddingBlock: applySpacing(2),
        paddingInline: applySpacing(4),
        width: `calc(100% - calc(${applySpacing(4)}px * 2))`,
        height: 'auto',
        zIndex: 999,
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: THEME_COLORS.BRAND_PINK,
        color: THEME_COLORS.NEUTRAL_BLACK,
        fontFamily: FONT_FAMILIES.ROCA,
        fontSize: 20,
        paddingTop: applySpacing(2),
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        '.MuiPaper-root': {
          backgroundColor: THEME_COLORS.BRAND_GREEN,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        paddingBlock: applySpacing(4),
        '&:hover': {
          backgroundColor: THEME_COLORS.BRAND_LIGHT_GREEN,
        },
      },
    },
  },
  MuiListItemText: {
    defaultProps: {
      disableTypography: true,
    },
    styleOverrides: {
      root: {
        fontSize: calcFontSize(14),
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: THEME_COLORS.NEUTRAL_BLACK,
        paddingBottom: applySpacing(4),
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
      color: 'green',
    },
    styleOverrides: {
      root: {},
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        strokeWidth: 1,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        '&:focus-visible': {
          outline: '2px solid',
          outlineColor: THEME_COLORS.BRAND_ORANGE,
          outlineOffset: -10,
        },
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: 'outlined',
      color: 'green',
    },
    styleOverrides: {
      root: {
        outlineColor: THEME_COLORS.BRAND_LIGHT_GREEN,
        '.MuiSvgIcon-root': {
          color: THEME_COLORS.BRAND_GREEN,
        },
        '&.Mui-disabled': {
          outlineColor: THEME_COLORS.NEUTRAL_GREY_30,
          pointerEvents: 'none',
          '*': {
            color: `${THEME_COLORS.NEUTRAL_GREY_30} !important`,
          },
        },
      },
    },
  },
  // @ts-expect-error
  MuiMenuPaper: {
    styleOverrides: {
      root: {
        backgroundColor: THEME_COLORS.NEUTRAL_WHITE,
      },
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      notched: false,
      color: 'green',
    },
    styleOverrides: {
      root: {
        fontSize: calcFontSize(16),
        borderRadius: 4,
        minHeight: 56,
        outline: `1px solid ${THEME_COLORS.BRAND_LIGHT_GREEN}`,
        '&:hover': {
          outline: `2px solid ${THEME_COLORS.BRAND_LIGHT_GREEN}`,
        },
        '&.Mui-focused': {
          outline: `2px solid ${THEME_COLORS.BRAND_GREEN} !important`,
        },
        '.MuiInputBase-input': {
          transform: 'translateY(8px)',
          color: `${THEME_COLORS.BRAND_GREEN}`,
          paddingTop: 0,
          paddingBottom: 0,
        },
        fieldset: {
          border: 'none',
        },
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {},
    styleOverrides: {
      root: {
        transition: 'all 0.35s ease',
        '&.MuiInputLabel-shrink': {
          top: 14,
        },
        '&.MuiFormLabel-colorGreen, &.MuiFormLabel-colorPrimary': {
          color: THEME_COLORS.BRAND_GREEN,
        },
        '&.Mui-disabled': {
          color: THEME_COLORS.NEUTRAL_GREY_30,
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        '&:has(.MuiInputBase-adornedStart)': {
          '.MuiFormLabel-root': {
            marginLeft: 36,
          },
        },
        '&:has(.Mui-disabled)': {
          '.MuiFormLabel-root': {
            color: THEME_COLORS.NEUTRAL_GREY_30,
          },
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        fontSize: `${calcFontSize(16)} !important`,
      },
    },
  },
  MuiFormGroup: {
    styleOverrides: {
      root: {
        '>:last-child': {
          marginBottom: 0,
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        color: THEME_COLORS.BRAND_GREEN,
        fontSize: calcFontSize(24),
        fontFamily: FONT_FAMILIES.ROCA,
        lineHeight: 1.2,
        padding: applySpacing(6),
        paddingBottom: 0,
        marginBottom: applySpacing(2),
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: applySpacing(6),
        paddingTop: 0,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '.MuiDialogTitle-root': {
          padding: applySpacing(4),
          margin: 0,
          '@media (min-width:768px)': {
            padding: applySpacing(6),
            paddingBottom: 0,
          },
        },
        '.MuiDialogContent-root': {
          padding: applySpacing(4),
          paddingTop: '0 !important',
          '@media (min-width:768px)': {
            paddingTop: 0,
            padding: applySpacing(6),
          },
        },
        '> .MuiDialog-container > .MuiDialog-paper': {
          border: 0,
        },
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        border: `1px solid #C1D6CC`,
        borderRadius: 8,
        borderCollapse: 'separate',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        th: {
          borderBottom: '1px solid #C1D6CC !important',
          borderCollapse: 'separate',
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderBottom: '1px solid #C1D6CC',
        '&:last-child td, &:last-child th': { border: 0 },
        '@media (max-width:768px)': {
          paddingBlock: applySpacing(3),
        },
        // @ts-ignore
        '&.MuiTableRow-hover:hover': {
          backgroundColor: THEME_COLORS.NEUTRAL_GREY_10,
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        border: 0,
        '@media (min-width:768px)': {
          borderBottom: '1px solid #C1D6CC',
        },
        '@media (max-width:768px)': {
          '&:not(th)': {
            marginBlock: applySpacing(3),
          },
        },
      },
    },
  },
}
