import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcColumn,
  AcLoaderPulse,
  AcRow,
  AcTable,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks'
import { IMetaPaginationResponse, IOrderSummary } from '@typings'

import { AcOrdersTableRow } from '../ac-orders-table-row/ac-orders-table-row'

export interface IOrdersTable {
  year: number
}

export const AcOrdersTable = observer(({ year }: IOrdersTable) => {
  const PER_PAGE = 10
  const { orders, toaster } = useStore()
  const [loading, setLoading] = useState(true)
  const [loadingPagination, setLoadingPagination] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [rows, setRows] = useState<IOrderSummary[] | null>(null)
  const [meta, setMeta] = useState<IMetaPaginationResponse | null>(null)

  const handleLazyLoadOrders = async () => {
    setLoadingPagination(true)
    const { data, meta } = await orders.fetchOrdersByYear({
      year,
      page: currentPage + 1,
      per_page: PER_PAGE,
    })
    if (data?.length) {
      setRows(prev => (prev?.length ? [...prev, ...data] : data))
    }
    setCurrentPage(prev => prev + 1)
    setMeta(meta)
    setLoadingPagination(false)
  }

  const fetchOrders = async () => {
    try {
      const { data, meta } = await orders.fetchOrdersByYear({
        year,
        page: currentPage,
        per_page: PER_PAGE,
      })
      setRows(data)
      setMeta(meta)
    } catch (error: any) {
      toaster.warning({ content: error?.message })
    } finally {
      setLoading(false)
    }
  }

  const renderTableRows = useMemo(() => {
    if (!rows?.length) return []
    return rows.map(row => <AcOrdersTableRow {...row} />)
  }, [rows])

  useEffect(() => {
    fetchOrders()
  }, [])

  if (loading) {
    return (
      <AcRow justifyContent="center">
        <AcLoaderPulse />
      </AcRow>
    )
  }

  if (!rows?.length) {
    return (
      <AcColumn>
        <AcTypography>
          We hebben geen bestellingen gevonden voor {year}.
        </AcTypography>
      </AcColumn>
    )
  }

  return (
    <AcColumn alignItems={'center'}>
      <AcTable rows={renderTableRows} />
      {meta?.last_page && currentPage <= meta?.last_page && (
        <AcButton
          loading={loadingPagination}
          variant="secondary"
          onClick={handleLazyLoadOrders}
          sx={{ width: 'fit-content' }}>
          {LABELS.LOAD_MORE}
        </AcButton>
      )}
    </AcColumn>
  )
})
