import BottomNavigation from '@mui/material/BottomNavigation'
import { observer } from 'mobx-react-lite'

import { AcMenuItem } from '@components'
import { useBreakpoints, useRoleToggle } from '@hooks'

export const AcNavbarBottom = observer(() => {
  const { isMobile } = useBreakpoints()

  const { navigation } = useRoleToggle()

  if (!isMobile) return null
  return (
    <BottomNavigation sx={{ justifyContent: 'space-evenly' }}>
      {navigation.map(page => (
        <AcMenuItem {...page} />
      ))}
    </BottomNavigation>
  )
})
