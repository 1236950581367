import { IExtrasProduct } from '@typings'

export const transformOrderSummaryExtras = (
  formValues: any,
  availableProducts: IExtrasProduct[],
) => {
  const formValuesList = Object.entries(formValues).map(([key, value]) => ({
    key,
    value,
  }))
  const extrasList = formValuesList.filter(
    item => item.key.includes('extras-') && item.value,
  )

  const productTotalPerDay = extrasList.map(item => {
    const day = item.key.split('-')[1]
    const productId = item.key.split('-')[2]
    const product = availableProducts.find(
      prod => prod.id.toString() === productId,
    )
    return {
      day,
      product,
      qty: parseInt(item.value as string),
      total: !product?.price
        ? 0
        : product?.price * parseInt(item.value as string),
    }
  })

  return productTotalPerDay
}
