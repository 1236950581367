import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import type { IAxiosError, IErrorResponse } from '@typings'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcEmailInput,
  AcFormInput,
  AcFormProvider,
  AcPasswordInput,
} from '@components'
import { LABELS, REGEX } from '@constants'
import { useBreakpoints } from '@hooks'
import { useInputCompare, useStore } from '@hooks'

const FormInputs = () => {
  const { checkPasswordMatch } = useInputCompare(
    'password',
    'password_confirmation',
  )

  return (
    <>
      <AcFormInput
        register={'email'}
        rules={{
          required: 'required',
          pattern: {
            value: REGEX.EMAIL,
            message: LABELS.FIELD_VALIDATION_EMAIL,
          },
        }}
        Component={
          <AcEmailInput
            required
            label={LABELS.EMAIL_ADDRESS}
          />
        }
      />
      <AcFormInput
        register={'password'}
        rules={{
          required: 'required',
          pattern: {
            value: REGEX.PASSWORD,
            message: LABELS.FIELD_VALIDATION_PW_REGEX,
          },
        }}
        Component={
          <AcPasswordInput
            allowVisible
            label={LABELS.NEW_PASSWORD}
            onBlur={checkPasswordMatch}
            required
          />
        }
      />
      <AcFormInput
        lastChild
        register={'password_confirmation'}
        rules={{
          required: 'required',
          pattern: {
            value: REGEX.PASSWORD,
            message: LABELS.FIELD_VALIDATION_PW_REGEX,
          },
        }}
        Component={
          <AcPasswordInput
            allowVisible
            label={LABELS.CONFIRM_PASSWORD}
            onBlur={checkPasswordMatch}
            required
          />
        }
      />
    </>
  )
}

export const AcResetPasswordForm = observer(() => {
  const navigate = useNavigate()
  const { token } = useParams()
  const { toaster, auth } = useStore()
  const { isMobile } = useBreakpoints()

  const [errors, setErrors] = useState<IAxiosError['errorFields'] | any>(null)

  const onSubmit = async (values: any) => {
    try {
      await auth.postResetPassword({ ...values, token })
      toaster.success({ content: LABELS.PASSWORD_CHANGED })
      navigate(NAVIGATE_TO.LOGIN)
    } catch (error) {
      const err = error as IErrorResponse
      if (err.type === 'axios') {
        setErrors(err.errorFields)
        toaster.error({ content: err.message })
      } else {
        toaster.error({ content: err.message })
      }
    }
  }

  return (
    <AcFormProvider
      initial={{
        password: '',
        password_confirmation: '',
      }}
      errors={errors}
      onSubmit={onSubmit}
      flexGrow={1}>
      <FormInputs />
      <AcButton
        type="submit"
        variant="primary"
        loading={auth.loading}
        sx={{ marginTop: isMobile ? 'auto' : 6 }}
        fullWidth>
        {LABELS.CHANGE_PASSWORD_BUTTON}
      </AcButton>
    </AcFormProvider>
  )
})
