import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import {
  AcCheckbox,
  AcCheckboxGroup,
  AcColumn,
  AcFormInput,
  AcFormProvider,
  AcGridContainer,
  AcGridItem,
  AcLoaderPulse,
  AcOrderFooter,
  AcRow,
  AcTextarea,
  AcTypography,
} from '@components'
import { CURRENT_ORDER_KEYS, LABELS } from '@constants'
import { useBreakpoints, useStore } from '@hooks'
import { IOrderPreferencePayload } from '@typings'

const FormInputs = ({ loading }: { loading: boolean }) => {
  const { currentOrder } = useStore()
  const { isMobile } = useBreakpoints()
  const { watch, setValue } = useFormContext()
  const watchNoComment = watch(CURRENT_ORDER_KEYS.NO_COMMENT)

  useEffect(() => {
    if (watchNoComment) {
      setValue(CURRENT_ORDER_KEYS.COMMENT, '')
    }
  }, [watchNoComment])

  const renderSoups = useMemo(() => {
    if (loading) return <AcLoaderPulse />
    if (!currentOrder.watchAvailableSoups?.length) return null
    return (
      <AcColumn gap={1}>
        <AcTypography
          removePadding
          color="green.main"
          bold>
          {LABELS.WHAT_IS_YOUR_SOUP_PREFERENCE}
        </AcTypography>
        <AcTypography>{LABELS.AVAILABILITY_NOT_GUARANTEED}</AcTypography>
        <AcGridContainer>
          {currentOrder.watchAvailableSoups?.map((item, i) => (
            <AcGridItem
              key={i}
              mobile={12}
              tablet={6}>
              <AcColumn>
                <AcTypography removePadding>
                  {LABELS.AVAILABILITY}: {item.group}
                </AcTypography>
                <AcFormInput
                  register={`${CURRENT_ORDER_KEYS.SOUP}-${i}`}
                  Component={
                    <AcCheckboxGroup
                      fullWidth
                      useInt
                      id={`${CURRENT_ORDER_KEYS.SOUP}-${i}`}
                      options={item.products.map(prod => ({
                        id: prod.id,
                        value: prod.id,
                        title: prod.title,
                        label: prod.subtitle,
                      }))}
                    />
                  }
                />
              </AcColumn>
            </AcGridItem>
          ))}
        </AcGridContainer>
      </AcColumn>
    )
  }, [currentOrder.watchAvailableSoups, loading])

  const renderSalads = useMemo(() => {
    if (loading) return <AcLoaderPulse />
    if (!currentOrder.watchAvailableSalads?.length) return null
    return (
      <AcColumn gap={1}>
        <AcTypography
          removePadding
          color="green.main"
          bold>
          {LABELS.WHAT_IS_YOUR_SALAD_PREFERENCE}
        </AcTypography>
        <AcTypography>{LABELS.AVAILABILITY_NOT_GUARANTEED}</AcTypography>
        <AcGridContainer>
          {currentOrder.watchAvailableSalads?.map((item, i) => (
            <AcGridItem
              key={i}
              mobile={12}
              tablet={6}>
              <AcColumn>
                <AcTypography removePadding>
                  {LABELS.AVAILABILITY} {item.group}
                </AcTypography>
                <AcFormInput
                  register={`${CURRENT_ORDER_KEYS.SALAD}-${i}`}
                  Component={
                    <AcCheckboxGroup
                      fullWidth
                      useInt
                      id={`${CURRENT_ORDER_KEYS.SALAD}-${i}`}
                      options={item.products.map(prod => ({
                        id: prod.id,
                        value: prod.id,
                        title: prod.title,
                        label: prod.subtitle,
                      }))}
                    />
                  }
                />
              </AcColumn>
            </AcGridItem>
          ))}
        </AcGridContainer>
      </AcColumn>
    )
  }, [currentOrder.watchAvailableSalads, loading])

  const renderRemarks = useMemo(() => {
    return (
      <AcColumn gap={3}>
        <AcColumn gap={1}>
          <AcTypography
            removePadding
            color="green.main"
            bold>
            {LABELS.ANY_COMMENTS}
          </AcTypography>
          <AcTypography>{LABELS.ANY_COMMENTS_HELPER}</AcTypography>
          <AcFormInput
            register={CURRENT_ORDER_KEYS.COMMENT}
            Component={
              <AcTextarea
                label={LABELS.EXTRA_REMARK_LABEL}
                disabled={watchNoComment}
              />
            }
          />
        </AcColumn>
        <AcRow>
          <AcFormInput
            register={CURRENT_ORDER_KEYS.NO_COMMENT}
            Component={
              <AcCheckbox
                id={CURRENT_ORDER_KEYS.NO_COMMENT}
                variant="default"
                label={LABELS.ANY_COMMENTS_NONE}
              />
            }
          />
        </AcRow>
      </AcColumn>
    )
  }, [isMobile, watchNoComment])

  return (
    <AcColumn gap={6}>
      {renderSoups}
      {renderSalads}
      {renderRemarks}
    </AcColumn>
  )
}

export const AcOrderPreferencesForm = observer(
  ({ weekIdentifier }: { weekIdentifier: string }) => {
    const navigate = useNavigate()
    const { currentOrder, toaster } = useStore()

    const [errors, setErrors] = useState()

    const handleSubmit = async (body: IOrderPreferencePayload) => {
      try {
        const res = await currentOrder.submitPreferences(body)
        if (res) {
          navigate(NAVIGATE_TO.ORDER_SUMMARY(weekIdentifier))
        }
      } catch (error: any) {
        if (error.type === 'axios') {
          setErrors(error.errorFields)
        }
        toaster.error({
          title: 'Nog niet alle velden zijn juist ingevuld',
          content: error.message,
        })
      }
    }

    return (
      <AcFormProvider
        initial={currentOrder.fetchPreferenceStepInitialValues}
        onSubmit={handleSubmit}
        errors={errors}>
        <FormInputs loading={currentOrder.watchLoadingStep} />
        <AcOrderFooter
          loading={currentOrder.watchLoadingSubmitting}
          previousStep={NAVIGATE_TO.ORDER(weekIdentifier)}
        />
      </AcFormProvider>
    )
  },
)
