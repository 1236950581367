import { AcDefaultLayout, AcOnboardingLayout, AcOrderLayout } from '@layouts'
import {
  AcNotFoundView,
  CustomerProfileView,
  DashboardView,
  LoginView,
  LuncherAttendanceView,
  LuncherPreferencesOverviewView,
  OnboardingAllergiesView,
  OnboardingAttendanceView,
  OnboardingCompletedView,
  OnboardingInvitedView,
  OnboardingPreferencesView,
  OnboardingRegisterView,
  OnboardingVerifyView,
  OnboardingWelcomeView,
  OrderCompletedView,
  OrderDetailView,
  OrderLunchersView,
  OrderPreferencesView,
  OrderSummaryView,
  OrdersView,
  RequestNewPasswordView,
  ResetPasswordView,
} from '@views'

import { KEYS } from '@constants'

import { AcPrivateRoute } from '../components/core/ac-private-route/ac-private-route'
import { NoAccessView } from '../views/default/no-access.view'

export const ROUTE_KEYS = {
  ROOT: '/',
  DASHBOARD_INDEX: '/dashboard',
  LUNCHER_INDEX: '/lunchers',
  ONBOARDING_INDEX: '/onboarding',
  AUTH_INDEX: '/auth',
  LOGIN: 'login',
  RESET_PASSWORD: 'reset-password/:token',
  REQUEST_NEW_PASSWORD: 'request-new-password',
  NO_ACCESS: 'no-access',
  REGISTER: 'register',
  ATTENDANCE: 'attendance',
  PREFERENCES: 'preferences',
  OVERVIEW: 'overview',
  ALLERGIES: 'allergies',
  ORDERS: 'orders',
  PROFILE: 'profile',
  ONBOARDING_COMPLETED: 'onboarding-completed',
  REGISTER_INDEX: '/registreer',
  VERIFY: 'verify/:userId/:hash',
  INVITED: 'invited',
  ORDERS_INDEX: '/orders',
  ORDER_INDEX: '/order',
  ORDER_LUNCHERS: ':weekIdentifier/lunchers',
  ORDER_PREFERENCES: ':weekIdentifier/preferences',
  ORDER_SUMMARY: ':weekIdentifier/summary',
  ORDER_COMPLETED: ':weekIdentifier/completed',
}

const {
  ROOT,
  DASHBOARD_INDEX,
  LUNCHER_INDEX,
  ONBOARDING_INDEX,
  AUTH_INDEX,
  REGISTER,
  LOGIN,
  ATTENDANCE,
  PREFERENCES,
  OVERVIEW,
  ALLERGIES,
  ORDERS,
  PROFILE,
  ONBOARDING_COMPLETED,
  RESET_PASSWORD,
  REQUEST_NEW_PASSWORD,
  NO_ACCESS,
  VERIFY,
  INVITED,
  REGISTER_INDEX,
  ORDER_INDEX,
  ORDERS_INDEX,
  ORDER_LUNCHERS,
  ORDER_SUMMARY,
  ORDER_PREFERENCES,
  ORDER_COMPLETED,
} = ROUTE_KEYS

export const NAVIGATE_TO = {
  ONBOARDING: `${ONBOARDING_INDEX}`,
  ONBOARDING_WELCOME: `${ONBOARDING_INDEX}`,
  ONBOARDING_REGISTER: `${ONBOARDING_INDEX}/${REGISTER}`,
  ONBOARDING_PREFERENCES: `${ONBOARDING_INDEX}/${PREFERENCES}`,
  ONBOARDING_ALLERGIES: `${ONBOARDING_INDEX}/${ALLERGIES}`,
  ONBOARDING_ATTENDANCE: `${ONBOARDING_INDEX}/${ATTENDANCE}`,
  ONBOARDING_COMPLETED: `/${ONBOARDING_COMPLETED}`,
  ADMIN_DASHBOARD: `${DASHBOARD_INDEX}/${OVERVIEW}`,
  ADMIN_ORDERS: `${DASHBOARD_INDEX}/${ORDERS}`,
  ADMIN_PROFILE: `${DASHBOARD_INDEX}/${PROFILE}`,
  LUNCHER_OVERVIEW: `${LUNCHER_INDEX}/${OVERVIEW}`,
  LUNCHER_PREFERENCES: `${LUNCHER_INDEX}/${PREFERENCES}`,
  LUNCHER_ALLERGIES: `${LUNCHER_INDEX}/${ALLERGIES}`,
  LOGIN: `${AUTH_INDEX}/${LOGIN}`,
  RESET_PASSWORD: `${AUTH_INDEX}/${RESET_PASSWORD}`,
  REQUEST_NEW_PASSWORD: `${AUTH_INDEX}/${REQUEST_NEW_PASSWORD}`,
  NO_ACCESS: `${AUTH_INDEX}/${NO_ACCESS}`,
  INVITED: `${ONBOARDING_INDEX}/${INVITED}`,
  VIEW_ORDER: (weekIdentifier: string) =>
    `${DASHBOARD_INDEX}${ORDERS_INDEX}/${weekIdentifier}`,
  ORDER: (weekIdentifier: string) =>
    `${ORDER_INDEX}/${ORDER_LUNCHERS.replace(':weekIdentifier', weekIdentifier)}`,
  ORDER_PREFERENCES: (weekIdentifier: string) =>
    `${ORDER_INDEX}/${ORDER_PREFERENCES.replace(':weekIdentifier', weekIdentifier)}`,
  ORDER_SUMMARY: (weekIdentifier: string) =>
    `${ORDER_INDEX}/${ORDER_SUMMARY.replace(':weekIdentifier', weekIdentifier)}`,
  ORDER_COMPLETED: (weekIdentifier: string) =>
    `${ORDER_INDEX}/${ORDER_COMPLETED.replace(':weekIdentifier', weekIdentifier)}`,
}

export const ROUTER = [
  {
    id: 'NOT_FOUND',
    path: '*',
    element: <AcNotFoundView />,
  },
  {
    id: 'ROOT',
    path: ROOT,
    element: (
      <AcPrivateRoute
        roles={[]}
        checkAuth
        component={<AcOnboardingLayout variant="branded_table" />}
      />
    ),
    children: [
      {
        index: true,
        element: <LoginView />,
      },
    ],
  },
  {
    id: 'ONBOARDING',
    path: ONBOARDING_INDEX,
    element: <AcOnboardingLayout />,
    children: [
      {
        index: true,
        element: (
          <AcPrivateRoute
            checkAuth
            roles={[]}
            component={<OnboardingWelcomeView />}
          />
        ),
      },
      {
        path: REGISTER,
        element: (
          <AcPrivateRoute
            checkAuth
            roles={[]}
            component={<OnboardingRegisterView />}
          />
        ),
      },
      {
        path: VERIFY,
        element: <OnboardingVerifyView />,
      },
      {
        path: INVITED,
        element: (
          <AcPrivateRoute
            checkAuth
            roles={[]}
            component={<OnboardingInvitedView />}
          />
        ),
      },
      {
        path: ALLERGIES,
        element: <OnboardingAllergiesView />,
      },
      {
        path: ATTENDANCE,
        element: <OnboardingAttendanceView />,
      },
      {
        path: PREFERENCES,
        element: <OnboardingPreferencesView />,
      },
    ],
  },
  {
    id: 'ONBOARDING_COMPLETED',
    path: ROOT,
    element: (
      <AcPrivateRoute
        component={<AcOnboardingLayout withCard={false} />}
        roles={[KEYS.LUNCHER_ROLE]}
      />
    ),
    children: [
      {
        path: ONBOARDING_COMPLETED,
        element: <OnboardingCompletedView />,
      },
    ],
  },
  {
    id: 'LUNCHERS',
    path: LUNCHER_INDEX,
    element: (
      <AcPrivateRoute
        component={<AcDefaultLayout variant="branded" />}
        roles={[KEYS.LUNCHER_ROLE]}
      />
    ),
    protected: true,
    children: [
      {
        path: PREFERENCES,
        element: <LuncherPreferencesOverviewView />,
      },
      {
        path: OVERVIEW,
        element: <LuncherAttendanceView />,
      },
    ],
  },
  {
    id: 'REGISTER',
    path: REGISTER_INDEX,
    element: <AcOnboardingLayout />,
    children: [
      {
        index: true,
        element: (
          <AcPrivateRoute
            checkAuth
            roles={[]}
            component={<OnboardingWelcomeView />}
          />
        ),
      },
    ],
  },
  {
    id: 'DASHBOARD',
    path: DASHBOARD_INDEX,
    element: (
      <AcPrivateRoute
        component={<AcDefaultLayout variant="default" />}
        roles={[KEYS.OFFICE_MANAGER_ROLE]}
      />
    ),
    protected: true,
    children: [
      {
        path: OVERVIEW,
        element: <DashboardView />,
      },
      {
        path: ORDERS,
        children: [
          {
            index: true,
            element: <OrdersView />,
          },
          {
            path: ':id',
            children: [
              {
                index: true,
                element: <OrderDetailView />,
              },
            ],
          },
        ],
      },
      {
        path: PROFILE,
        element: <CustomerProfileView />,
      },
    ],
  },
  {
    path: AUTH_INDEX,
    element: (
      <AcPrivateRoute
        component={<AcOnboardingLayout variant="branded_table" />}
        roles={[]}
        checkAuth={true}
      />
    ),
    children: [
      {
        path: LOGIN,
        element: <LoginView />,
      },
      {
        path: RESET_PASSWORD,
        element: <ResetPasswordView />,
      },
      {
        path: REQUEST_NEW_PASSWORD,
        element: <RequestNewPasswordView />,
      },
      {
        path: NO_ACCESS,
        element: <NoAccessView />,
      },
      {
        path: VERIFY,
        element: <OnboardingVerifyView />,
      },
    ],
  },
  {
    path: ORDER_INDEX,
    element: (
      <AcPrivateRoute
        component={<AcOrderLayout variant={'default'} />}
        roles={['office-manager']}
      />
    ),
    children: [
      {
        path: ORDER_LUNCHERS,
        element: <OrderLunchersView />,
      },
      {
        path: ORDER_PREFERENCES,
        element: <OrderPreferencesView />,
      },
      {
        path: ORDER_SUMMARY,
        element: <OrderSummaryView />,
      },
    ],
  },
  {
    id: 'ORDER_COMPLETED',
    path: ORDER_INDEX,
    element: (
      <AcPrivateRoute
        component={
          <AcOnboardingLayout
            variant={'default_mobile'}
            withCard={'desktop'}
          />
        }
        roles={[KEYS.OFFICE_MANAGER_ROLE]}
      />
    ),
    children: [
      {
        path: ORDER_COMPLETED,
        element: <OrderCompletedView />,
      },
    ],
  },
  /** [ ROUTES ]
  /** [ ROUTE_KEYS ]
   * You can add ROUTE_KEYS by doing:
   * `hygen make route`
   */
]
