import { AcAnimatedFade } from '@animations'

import { AcBox, AcTypography } from '@components'
import { LABELS } from '@constants'
import { AcLuncherPreferenceForm } from '@partials'

export const OnboardingPreferencesView = () => {
  return (
    <AcAnimatedFade
      enter={{ x: 20, y: 0 }}
      exit={{ x: -20, y: 0 }}
      style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <AcBox pb={5}>
        <AcTypography
          color="green.main"
          component="h2"
          padding="sm">
          {LABELS.WHAT_ARE_YOUR_PREFERENCES}
        </AcTypography>
        <AcTypography removePadding>
          {LABELS.WHAT_ARE_YOUR_PREFERENCES_SUBTITLE}{' '}
          {LABELS.PREFERENCES_MAY_CHANGE}
        </AcTypography>
      </AcBox>
      <AcLuncherPreferenceForm nextStep={true} />
    </AcAnimatedFade>
  )
}
