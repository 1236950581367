import { Button, styled } from '@mui/material'

export const WhiteButton = styled(Button)(({ theme, ...props }) => ({
  display: 'relative',
  overflow: 'hidden',
  transition: `color 0.15s ${theme.transitions.easing.easeInOut}`,
  backgroundColor: theme.palette.white.main,
  color: theme.palette.green.main,
  zIndex: 2,
  '> span': {
    zIndex: 3,
    display: 'block',
  },
  '&:hover': {
    backgroundColor: theme.palette.orange.main,
    color: theme.palette.orange.contrastText,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette['grey-20'].main,
    color: theme.palette['grey-20'].contrastText,
  },
  ...(props.loading && {
    pointerEvents: 'none',
  }),
}))
