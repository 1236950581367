import React from 'react'
import TableRow, { TableRowProps } from '@mui/material/TableRow'

import { AcIcon, AcIconButton } from '@components'
import { ICONS } from '@constants'

export interface IAcTableRow extends TableRowProps {
  children: React.ReactNode
  onClick?: () => void
  icon?: React.ReactNode
  to?: string
  direction?: 'row' | 'column'
}

export const AcTableRow = ({
  children,
  onClick,
  icon,
  direction = 'row',
  ...props
}: IAcTableRow) => {
  return (
    <TableRow
      onClick={onClick}
      hover={onClick || props.to ? true : false}
      sx={{
        width: '100%',
        position: 'relative',
        cursor: onClick ? 'pointer' : 'initial',
        display: direction === 'column' ? 'flex' : 'table-row',
        flexDirection: direction,
      }}
      {...props}>
      {children}
      {onClick && (
        <AcIconButton
          color="green"
          sx={{
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
          }}>
          {icon || <AcIcon icon={ICONS.NEXT} />}
        </AcIconButton>
      )}
    </TableRow>
  )
}
