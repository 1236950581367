import { useState } from 'react'

import { AcButton, AcCard, AcColumn, AcRow, AcTypography } from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks'
import { ISimpleProduct } from '@typings'

interface IAcNoLongerExcludedModal {
  product: ISimpleProduct
  onSuccess: () => void
}

export const AcNoLongerExcludedModal = ({
  product,
  onSuccess,
}: IAcNoLongerExcludedModal) => {
  const { dialog, products, toaster } = useStore()
  const [submitting, setSubmitting] = useState(false)

  const confirmPatch = async () => {
    setSubmitting(true)
    try {
      const res = await products.setProductRating(product.id, 3)
      if (res) {
        toaster.success({
          title: 'Product weer toegelaten',
          content: `${product.name} staat niet meer als uitgesloten geregistreerd.`,
        })
        dialog.close()
        if (onSuccess) onSuccess()
      }
    } catch (error) {
      toaster.error({
        title: 'Er is iets mis gegaan',
        content: (error as any).message || '',
      })
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <AcColumn>
      <AcCard cardColor="beige">
        <AcTypography component="span">{product.name}</AcTypography>
      </AcCard>
      <AcRow
        alignItems="center"
        justifyContent="space-between">
        <AcButton
          variant="text"
          disabled={submitting}
          onClick={dialog.close}>
          {LABELS.CANCEL}
        </AcButton>
        <AcButton
          loading={submitting}
          onClick={confirmPatch}>
          {LABELS.CONFIRM}
        </AcButton>
      </AcRow>
    </AcColumn>
  )
}
