import { useMemo } from 'react'

import { AcColumn, AcImage, AcRow, AcTable, AcTypography } from '@components'
import { LABELS, WEEKDAYS_LABELS } from '@constants'
import { formatPrice, getSum } from '@helpers'
import { useBreakpoints } from '@hooks'
import {
  IOrderDetailsOverviewExtraDay,
  IOrderDetailsOverviewExtraDayProduct,
} from '@typings'

const AcOrderExtrasPerDayDesktop = (
  product: IOrderDetailsOverviewExtraDayProduct,
) => {
  return (
    <AcTable.Row>
      <AcTable.Cell
        align="left"
        style={{ width: 72 }}>
        {product.image?.url && (
          <AcImage
            src={product.image.url}
            srcset={product.image.srcset}
            maxWidth={72}
          />
        )}
      </AcTable.Cell>
      <AcTable.Cell align="left">
        <AcTypography component="span">{product.name}</AcTypography>
      </AcTable.Cell>
      <AcTable.Cell
        style={{ width: 100 }}
        align="right">
        <AcTypography component="span">{product.quantity}x</AcTypography>
      </AcTable.Cell>
      <AcTable.Cell
        style={{ width: 100 }}
        align="right">
        <AcTypography component="span">
          {formatPrice(product.price)}
        </AcTypography>
      </AcTable.Cell>
      <AcTable.Cell
        style={{ width: 100 }}
        align="right">
        <AcTypography component="span">
          {formatPrice(product.price * product.quantity)}
        </AcTypography>
      </AcTable.Cell>
    </AcTable.Row>
  )
}

const AcOrderExtrasPerDayMobile = (
  product: IOrderDetailsOverviewExtraDayProduct,
) => {
  return (
    <AcTable.Row>
      <AcRow
        flexGrow={1}
        p={2}>
        {product.image?.url && (
          <AcImage
            src={product.image.url}
            srcset={product.image.srcset}
            maxWidth={72}
          />
        )}
        <AcColumn>
          <AcTypography
            component="span"
            fontWeight="bold"
            color="green.main">
            {product.name}
          </AcTypography>
          <AcRow justifyContent="space-between">
            <AcTypography component="span">{product.quantity}x</AcTypography>
            <AcTypography component="span">
              {formatPrice(product.price)}
            </AcTypography>
            <AcTypography component="span">
              {formatPrice(product.price * product.quantity)}
            </AcTypography>
          </AcRow>
        </AcColumn>
      </AcRow>
    </AcTable.Row>
  )
}

export const AcOrderExtrasPerDay = ({
  day,
  products,
}: IOrderDetailsOverviewExtraDay) => {
  const { isMobile } = useBreakpoints()

  const renderRows = useMemo(() => {
    if (!products?.length) return null

    return products.map(product =>
      isMobile ? (
        <AcOrderExtrasPerDayMobile {...product} />
      ) : (
        <AcOrderExtrasPerDayDesktop {...product} />
      ),
    )
  }, [products.length, isMobile])

  const renderSum = useMemo(() => {
    const dailyTotals = products.map(({ price, quantity }) => price * quantity)
    const dailySum = getSum(dailyTotals)
    const rowStyle = isMobile ? { borderTop: '1px solid #C1D6CC' } : undefined
    return (
      <>
        <AcTable.Row>
          <AcTable.Cell
            align="left"
            colSpan={4}
            style={rowStyle}>
            <AcTypography
              color="green.main"
              component="span">
              {LABELS.SUBTOTAL_EXCL_TAXES}
            </AcTypography>
          </AcTable.Cell>
          <AcTable.Cell
            align="right"
            style={{ width: 100, ...rowStyle }}>
            <AcTypography
              color="green.main"
              component="span">
              {formatPrice(dailySum)}
            </AcTypography>
          </AcTable.Cell>
        </AcTable.Row>
      </>
    )
  }, [products?.length, isMobile])

  return (
    <>
      <AcTable.Row>
        <AcTable.Cell colSpan={5}>
          <AcTypography
            color="green.main"
            component="span"
            bold>
            {WEEKDAYS_LABELS[day.toUpperCase() as keyof typeof WEEKDAYS_LABELS]}
          </AcTypography>
        </AcTable.Cell>
      </AcTable.Row>
      {renderRows}
      {renderSum}
    </>
  )
}
