import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AcAnimatedFade } from '@animations'
import type { IAxiosError, IErrorResponse } from '@typings'
import { observer } from 'mobx-react-lite'

import { AcButton, AcColumn, AcTypography } from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks'

export const OnboardingInvitedView = observer(() => {
  const [searchParams] = useSearchParams()
  const [email, setEmail] = useState<string>('')
  const { auth, toaster } = useStore()
  const [errors, setErrors] = useState<IAxiosError['errorFields'] | any>(null)

  useEffect(() => {
    setEmail(searchParams.get('email') || '')
  }, [])

  const onSubmit = async () => {
    if (!searchParams.get('email')) return

    try {
      await auth.resendVerificationEmail(email)
      toaster.success({ content: LABELS.REQUEST_NEW_PASSWORD })
    } catch (error) {
      const err = error as IErrorResponse
      if (err.type === 'axios') {
        setErrors(err.errorFields)
      } else {
        toaster.error({ content: err.message })
      }
    }
  }

  return (
    <AcAnimatedFade
      enter={{ x: 0, y: 0 }}
      exit={{ x: -20, y: 0 }}
      style={{ display: 'flex', flexGrow: 1 }}>
      <AcColumn
        alignItems="center"
        textAlign="center"
        gap={0}>
        <AcTypography
          removePadding
          component="h2"
          color="green.main">
          E-mailadres bevestigen
        </AcTypography>
        <AcTypography padding="lg">
          We hebben je een e-mail gestuurd naar het opgegeven e-mailadres.
          Controleer je e-mail en bevestig om door te gaan.
        </AcTypography>
        {email && (
          <AcButton
            loading={auth.watchLoading}
            variant="secondary"
            onClick={onSubmit}>
            E-mail opnieuw versturen
          </AcButton>
        )}
      </AcColumn>
    </AcAnimatedFade>
  )
})
