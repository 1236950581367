import { styled, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material'

import { IAcMuiTab } from './ac-tabs'

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  '.MuiTabs-indicator': {
    display: 'none',
  },
}))

export const Tab = styled(MuiTab)<IAcMuiTab>(({ theme, ...props }) => ({
  fontSize: props.small ? 14 : theme.typography.fontSize,
  color: theme.palette.green.main,
  outline: `1px solid ${theme.palette['light-green'].main}`,
  borderRadius: 25,
  marginBlock: theme.spacing(1),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
  fontWeight: theme.typography.fontWeightRegular,
  paddingBlock: props.small ? theme.spacing(1) : theme.spacing(3),
  paddingInline: theme.spacing(4),
  minHeight: 32,
  [theme.breakpoints.up('tablet')]: {
    minHeight: 48,
  },
  minWidth: 0,
  '&:hover': {
    outline: `2px solid ${theme.palette.green.main}`,
  },
  '&:focus-visible': {
    borderRadius: 25,
    border: `2px solid ${theme.palette.orange.main}`,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.orange.main,
    outlineColor: theme.palette.orange.main,
    color: theme.palette.white.main,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      outlineColor: theme.palette.green.main,
    },
    '&:focus-visible': {
      border: `1px solid ${theme.palette.white.main} !important`,
      outline: `2px solid ${theme.palette.orange.main} !important`,
    },
  },
}))
