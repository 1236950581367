import { cloneElement, useMemo } from 'react'
import { useLocation, useOutlet } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import {
  AcAppBackground,
  IAcAppBackground,
} from 'src/components/core/ac-app-background/ac-app-background'

import { ReactComponent as BuurtboerLogo } from '@assets/logos/buurtboer-logo-horizontal.svg'
import { ReactComponent as BuurtboerLogoDark } from '@assets/logos/buurtboer-logo-horizontal-dark.svg'
import {
  AcBox,
  AcCard,
  AcColumn,
  AcContainer,
  AcNotifications,
} from '@components'
import { useBreakpoints } from '@hooks'

interface IAcOnboardingLayout {
  withCard?: boolean | 'desktop'
  variant?: IAcAppBackground['variant']
}

const AnimatedOutlet = () => {
  const location = useLocation()
  const element = useOutlet()

  return (
    <AnimatePresence
      mode="wait"
      initial={true}>
      {element && cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  )
}

export const AcOnboardingLayout = observer(
  ({
    withCard = true,
    variant = 'branded',
  }: IAcOnboardingLayout): JSX.Element => {
    const { isMobile } = useBreakpoints()
    const cardHeight = isMobile ? `calc(100vh - 112px)` : 0
    const hasCard = useMemo(() => {
      return withCard === true || (withCard === 'desktop' && !isMobile)
    }, [withCard, isMobile])

    const renderLogo = useMemo(() => {
      if (withCard === 'desktop' && isMobile) return <BuurtboerLogoDark />
      return <BuurtboerLogo />
    }, [withCard, isMobile])

    return (
      <AcAppBackground variant={variant}>
        <AcBox p={5}>{renderLogo}</AcBox>
        <AcContainer>
          <AcColumn
            justifyContent={'center'}
            sx={{ minHeight: isMobile ? 0 : '80vh' }}>
            {hasCard ? (
              <AcCard
                padding="lg"
                maxWidth={448}
                center
                sx={{
                  width: '100%',
                  minHeight: cardHeight,
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <AnimatedOutlet />
              </AcCard>
            ) : (
              <AnimatedOutlet />
            )}
          </AcColumn>
        </AcContainer>
        <AcNotifications />
      </AcAppBackground>
    )
  },
)
