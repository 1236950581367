import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { AcTable, AcTypography } from '@components'
import { LABELS } from '@constants'
import {
  formatPrice,
  isEmptyObject,
  transformOrderSummaryExtras,
} from '@helpers'
import { useStore } from '@hooks'

export const AcOrdersummaryExtrasPricingSum = () => {
  const { products } = useStore()

  const { watch } = useFormContext()
  const formValues = watch()

  const calcTotal = useMemo(() => {
    if (isEmptyObject(formValues)) return 'Geen extras geselecteerd'
    const productTotalPerDay = transformOrderSummaryExtras(
      formValues,
      products.current_extras || [],
    )

    const sum = productTotalPerDay
      .map(item => item.total)
      .reduce((acc, curr) => acc + curr, 0)
    return formatPrice(sum || 0)
  }, [formValues, products.current_extras])

  return (
    <AcTable
      head={[]}
      rows={[
        <AcTable.Row>
          <AcTable.Cell>
            <AcTypography
              color="green.main"
              component="span"
              removePadding>
              <span style={{ fontWeight: 600 }}>{LABELS.TOTAL}</span> (
              {LABELS.EXCL_TAXES})
            </AcTypography>
          </AcTable.Cell>
          <AcTable.Cell>
            <AcTypography
              color="green.main"
              removePadding
              bold
              align="right">
              {calcTotal}
            </AcTypography>
          </AcTable.Cell>
        </AcTable.Row>,
      ]}
    />
  )
}
