import { useState } from 'react'

import {
  AcButton,
  AcCheckbox,
  AcCheckboxGroup,
  AcColumn,
  AcFormInput,
  AcFormProvider,
  AcTextInput,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { getFormRoleOptions } from '@helpers'
import { useStore } from '@hooks'
import { IAxiosError, ISinglePortalUserInvitation } from '@typings'

const FormInputs = ({ isSubmitting }: { isSubmitting: boolean }) => {
  const { user } = useStore()
  return (
    <>
      <AcTypography
        fontWeight="bold"
        color="green.main">
        {LABELS.LOGIN_DETAILS}
      </AcTypography>
      <AcFormInput
        register="first_name"
        Component={
          <AcTextInput
            label={LABELS.FIRST_NAME}
            disabled={isSubmitting}
            required
          />
        }
      />
      <AcFormInput
        register="middle_name"
        Component={
          <AcTextInput
            label={LABELS.MIDDLE_NAME}
            disabled={isSubmitting}
          />
        }
      />
      <AcFormInput
        register="last_name"
        Component={
          <AcTextInput
            label={LABELS.LAST_NAME}
            disabled={isSubmitting}
            required
          />
        }
      />
      <AcFormInput
        register="email"
        Component={
          <AcTextInput
            label={LABELS.EMAIL_ADDRESS}
            disabled={isSubmitting}
            required
          />
        }
      />
      <AcTypography
        fontWeight="bold"
        color="green.main">
        {LABELS.SETTINGS}
      </AcTypography>
      <AcTypography
        component="span"
        color="green.main">
        {LABELS.USER_ROLES}
      </AcTypography>
      <AcFormInput
        register="roles"
        Component={
          <AcCheckboxGroup
            variant="default"
            id="user-roles"
            options={getFormRoleOptions(user.hasApp).all}
            disabled={isSubmitting}
          />
        }
      />
      <AcTypography
        component="span"
        color="green.main">
        {LABELS.DELIVERY_SLIP}
      </AcTypography>
      <AcFormInput
        register="receives_tickets"
        Component={
          <AcCheckbox
            variant="default"
            id="receives_tickets"
            label={LABELS.RECEIVES_DELIVERY_SLIP}
          />
        }
      />
    </>
  )
}

export const AcInviteUserForm = () => {
  const { dialog, toaster, users } = useStore()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState<IAxiosError['errorFields'] | any>(null)

  const onSubmit = async (payload: ISinglePortalUserInvitation) => {
    setIsSubmitting(true)

    try {
      const res = await users.create(payload)
      if (res) {
        toaster.success({
          title: 'Gebruiker uitgenodigd!',
          content: `Je uitnodiging is verstuurd naar ${payload.email}`,
        })
        users.getUsersList()
        setTimeout(() => {
          dialog.close()
        }, 250)
      }
    } catch (error: any) {
      if (error.type === 'axios') {
        setErrors(error.errorFields)
      }
      toaster.error({
        title: 'Er is iets fout gegaan',
        content: `We hebben geen uitnodiging kunnen versturen naar ${payload.email}`,
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const initial = {
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    roles: [],
    receives_tickets: false,
  }

  return (
    <AcColumn mt={2}>
      <AcFormProvider
        initial={initial}
        onSubmit={onSubmit}
        errors={errors}>
        <FormInputs isSubmitting={isSubmitting} />

        <AcButton
          loading={isSubmitting}
          type="submit">
          {LABELS.INVITE}
        </AcButton>
      </AcFormProvider>
    </AcColumn>
  )
}
