import { useMemo } from 'react'

import { AcTag, IAcTag } from '@components'
import { mapProductRatingLabel } from '@helpers'
import { IProductRating } from '@typings'

interface IAcRatingTag {
  rating: IProductRating['value']
}

export const AcRatingTag = ({ rating }: IAcRatingTag) => {
  if (!rating && rating !== 0) return null
  if (rating < 0 || rating > 6) return null

  const renderChipColor: IAcTag['color'] = useMemo(() => {
    switch (rating) {
      case 0:
      case 1:
        return 'pink'

      case 3:
        return 'white'

      case 5:
      case 6:
        return 'green'

      default:
        break
    }
  }, [rating])

  const renderRatingLabel = useMemo(
    () => mapProductRatingLabel(rating),
    [rating],
  )

  return (
    <AcTag
      label={renderRatingLabel || ''}
      color={renderChipColor}
    />
  )
}
