import { useMemo } from 'react'
import { useTheme } from '@mui/material'
import { motion } from 'framer-motion'

interface IAcOnboardingSuccessSVG {
  color?: string
  checkColor?: string
  style?: React.CSSProperties
}

export const AcOnboardingSuccessSVG = ({
  color,
  checkColor,
  style = {},
}: IAcOnboardingSuccessSVG) => {
  const theme = useTheme()

  const getColor = (checkColor: any) => {
    switch (checkColor) {
      case 'green':
        return theme.palette.green.main
      case 'white':
        return theme.palette.white.main
      default:
        return theme.palette.orange.main
    }
  }

  const fill = useMemo(() => {
    return getColor(color)
  }, [color])

  const checkFill = useMemo(() => {
    return getColor(checkColor)
  }, [checkColor])

  const drawCircle = {
    initial: { y: 25, opacity: 0, fill },
    animate: {
      y: 0,
      opacity: 1,
      fill,
      transition: { duration: 0.5 },
    },
  }

  const spring = {
    type: 'spring',
    damping: 5,
    stiffness: 100,
    delay: 0.25,
  }

  return (
    <motion.svg
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial="hidden"
      animate="visible"
      style={style}>
      <motion.circle
        cx="55"
        cy="55"
        r="55"
        variants={drawCircle}
        initial="initial"
        animate="animate"
      />
      <motion.path
        fill={checkFill}
        d="M47.0511 64.688L36.6183 54.2552L33.1406 57.7328L47.0511 71.6433L76.8594 41.8351L73.3818 38.3574L47.0511 64.688Z"
        initial={{ opacity: 0, scale: 0.25 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={spring}
      />
    </motion.svg>
  )
}
