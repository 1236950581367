import { useStore } from './use-store'

export const usePWAInstall = () => {
  const { user } = useStore()

  const checkInstalledApps = async () => {
    if ('navigator' in window && (navigator as any)?.getInstalledRelatedApps) {
      const apps = await (navigator as any)?.getInstalledRelatedApps()
      console.log('Installed apps: ', apps)
    }
  }

  return {
    pwaAlreadyInstalled: !user.has_saved_pwa_prompt ? true : false,
    checkInstalledApps,
    attemptPWAInstall: user.attemptPWAInstall,
    saveEvent: user.saveDeferredPWAEvent,
  }
}
