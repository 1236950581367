import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { AcColumn, AcTable, AcTypography } from '@components'
import { LABELS, WEEKDAYS_LABELS } from '@constants'
import {
  formatPrice,
  isEmptyObject,
  transformOrderSummaryExtras,
} from '@helpers'
import { useStore } from '@hooks'

export const AcOrdersummaryExtrasPricingDaily = () => {
  const { products } = useStore()

  const { watch } = useFormContext()
  const formValues = watch()

  const dailyExtraArray = useMemo(() => {
    if (isEmptyObject(formValues)) return null
    const productTotalPerDay = transformOrderSummaryExtras(
      formValues,
      products.current_extras || [],
    )

    const groupByDay = Object.groupBy(productTotalPerDay, ({ day }) => day)
    return Object.entries(groupByDay).map(([key, value]) => ({
      day: key,
      data: value,
    }))
  }, [formValues, products.current_extras])

  if (!dailyExtraArray?.length) return null

  return (
    <AcColumn>
      {dailyExtraArray.map(({ day, data }) => (
        <AcTable
          head={[
            {
              title:
                WEEKDAYS_LABELS[
                  day?.toUpperCase() as keyof typeof WEEKDAYS_LABELS
                ],
              colSpan: 4,
            },
          ]}
          rows={
            data?.map(prod => (
              <AcTable.Row>
                <AcTable.Cell>
                  <AcTypography
                    color="green.main"
                    component="span"
                    removePadding>
                    {prod.product?.title}
                  </AcTypography>
                </AcTable.Cell>
                <AcTable.Cell>
                  <AcTypography
                    color="green.main"
                    removePadding
                    align="right">
                    {prod.qty}x
                  </AcTypography>
                </AcTable.Cell>
                <AcTable.Cell>
                  <AcTypography
                    color="green.main"
                    removePadding
                    align="right">
                    {formatPrice(prod.product?.price)}
                  </AcTypography>
                </AcTable.Cell>
                <AcTable.Cell>
                  <AcTypography
                    color="green.main"
                    removePadding
                    align="right">
                    {formatPrice((prod.product?.price || 0) * prod.qty)}
                  </AcTypography>
                </AcTable.Cell>
              </AcTable.Row>
            )) || []
          }
        />
      ))}
    </AcColumn>
  )
}
