import { useState } from 'react'
import type { IRadioGroupOption } from '@components'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Radio } from '@mui/material'

import { AcColumn, AcFormlabelWrapper, AcTypography } from '@components'

const CustomLabel = ({
  title,
  label,
}: {
  title: string | undefined
  label: string
}) => {
  return (
    <AcColumn gap={0}>
      {title && (
        <AcTypography
          bold
          component="span"
          data-name="ac-radio-title">
          {title}
        </AcTypography>
      )}
      <AcTypography component="span">{label}</AcTypography>
    </AcColumn>
  )
}

export const AcRadioContained = ({
  title,
  label,
  value,
}: IRadioGroupOption) => {
  const [focus, setFocus] = useState(false)
  return (
    <AcFormlabelWrapper
      value={value}
      isFocused={focus}
      control={
        <Radio
          onFocusVisible={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          checkedIcon={<CheckCircleIcon />}
        />
      }
      label={
        <CustomLabel
          title={title}
          label={label}
        />
      }
    />
  )
}
