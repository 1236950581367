import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { AcAnimatedFade } from '@animations'
import { NAVIGATE_TO } from '@routes'
import type { IErrorResponse } from '@typings'
import { observer } from 'mobx-react-lite'

import {
  AcBox,
  AcButton,
  AcColumn,
  AcLoaderPulse,
  AcOnboardingSuccessSVG,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks'
import { AcResendVerifyEmailForm } from '@partials'

export const OnboardingVerifyView = observer(() => {
  const [params] = useSearchParams()
  const { userId, hash } = useParams()
  const { auth, toaster } = useStore()
  const navigate = useNavigate()

  const [success, setSuccess] = useState<boolean | null>(null)

  useEffect(() => {
    const expires = params.get('expires')
    const signature = params.get('signature')
    if (!expires || !signature || !userId || !hash) return

    const verifyEmail = async () => {
      try {
        await auth.verifyEmail({
          expires,
          signature,
          userId,
          hash,
        })
        setSuccess(true)
      } catch (error) {
        const err = error as IErrorResponse
        setSuccess(false)
        toaster.error({ content: err.message })
      }
    }

    verifyEmail()
  }, [])

  const renderContent = useMemo(() => {
    if (success === null) {
      return <AcLoaderPulse />
    }

    if (!success) {
      return (
        <>
          <AcColumn>
            <AcTypography
              component="h2"
              removePadding
              color="green.main"
              padding="sm">
              Verifiëren mislukt...
            </AcTypography>
            <AcTypography padding="lg">
              Er is iets misgegaan tijdens het verifiëren, probeer het opnieuw.
            </AcTypography>
          </AcColumn>
          <AcResendVerifyEmailForm />
        </>
      )
    }

    return (
      <AcColumn
        alignItems="center"
        textAlign="center"
        gap={0}>
        <AcOnboardingSuccessSVG
          color="green"
          checkColor="white"
          style={{
            scale: 0.5,
          }}
        />
        <AcTypography
          component="h2"
          color="green.main"
          padding="sm">
          Gelukt! E-mailadres geverifieerd
        </AcTypography>
        <AcTypography padding="lg">
          Je e-mailadres is succesvol geverifieerd.
        </AcTypography>
        <AcButton
          sx={{ marginTop: 'auto' }}
          fullWidth
          onClick={() => navigate(NAVIGATE_TO.ONBOARDING_PREFERENCES)}>
          {LABELS.CONTINUE}
        </AcButton>
      </AcColumn>
    )
  }, [success, auth.watchLoading])

  return (
    <AcAnimatedFade
      enter={{ x: 0, y: 0 }}
      exit={{ x: -20, y: 0 }}
      style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      {renderContent}
    </AcAnimatedFade>
  )
})
