import { AcColumn, AcDayPreferences } from '@components'
import { useStore } from '@hooks'

export const AcLuncherPreferencesOverviewModal = () => {
  const { weeks } = useStore()

  return (
    <AcColumn
      height="100%"
      flexGrow={1}>
      <AcDayPreferences
        week={weeks.current?.week}
        year={weeks.current?.year}
        preferences={weeks.current?.preferences}
      />
    </AcColumn>
  )
}
