import { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'

import {
  AcColumn,
  AcIcon,
  AcLoaderPulse,
  AcRow,
  AcTable,
  AcTag,
  AcTypography,
  AcUserManagementFilters,
  IAcTag,
  IAcUserManagementFiltersArgs,
} from '@components'
import { ICONS, KEYS, LABELS } from '@constants'
import { useBreakpoints, useStore } from '@hooks'
import { ACEditUserForm } from '@partials'
import { IRole, ISinglePortalUser } from '@typings'

export const AcUserManagementTab = observer(() => {
  const { dialog } = useStore()
  const { isMobile } = useBreakpoints()
  const [filters, setFilters] = useState<IAcUserManagementFiltersArgs | null>(
    null,
  )
  const { users } = useStore()

  const renderUserDialog = (user: ISinglePortalUser) => {
    dialog.open({
      title: 'Gebruiker aanpassen',
      content: <ACEditUserForm user={user} />,
      maxWidth: 448,
    })
  }

  const updateFilters = (args: IAcUserManagementFiltersArgs) => {
    setFilters(args)
  }

  useEffect(() => {
    users.getUsersList()
  }, [])

  const renderTag = (role: IRole) => {
    const color: IAcTag['color'] =
      role.name === KEYS.LUNCHER_ROLE ? 'green' : 'pink'
    return (
      <AcTag
        color={color}
        label={role.label}
      />
    )
  }

  const renderRow = useCallback(
    (user: ISinglePortalUser) => {
      return (
        <AcTable.Row
          icon={<AcIcon icon={ICONS.PERSON_EDIT} />}
          onClick={() => renderUserDialog(user)}
          direction={isMobile ? 'column' : 'row'}>
          <AcTable.Cell
            colSpan={isMobile ? 3 : 0}
            style={{
              paddingBlock: isMobile ? 0 : 16,
              marginBlock: 4,
            }}>
            <AcTypography
              color="green.main"
              fontWeight="bold"
              component="span">
              {user.full_name}
            </AcTypography>
          </AcTable.Cell>
          <AcTable.Cell
            colSpan={isMobile ? 3 : 0}
            style={{
              paddingBlock: isMobile ? 0 : 16,
              marginBlock: 4,
            }}>
            <AcTypography
              color="green.main"
              component="span">
              {user.email}
            </AcTypography>
          </AcTable.Cell>
          <AcTable.Cell
            style={{
              paddingBlock: isMobile ? 0 : 16,
              marginBlock: 4,
            }}>
            <AcRow
              alignItems="center"
              mr={6}>
              {user.roles.map(role => renderTag(role))}
            </AcRow>
          </AcTable.Cell>
        </AcTable.Row>
      )
    },
    [isMobile],
  )

  const applyFilters = (
    list: ISinglePortalUser[],
    args: IAcUserManagementFiltersArgs | null,
  ) => {
    if (!args) return list
    const query = args?.query?.toLowerCase()
    const matchQuery = list.filter(
      ({ email, full_name }) =>
        email?.toLowerCase()?.includes(query) ||
        full_name?.toLowerCase()?.includes(query),
    )
    if (!args.roleFilters?.length) {
      return matchQuery
    }
    return matchQuery.filter(user =>
      user.roles.some(role =>
        args.roleFilters?.some(roleName => role.label === roleName),
      ),
    )
  }

  const renderRegisteredUsersList = useMemo(() => {
    if (!users.registered_users) return null
    const list = applyFilters(users.registered_users, filters)
    return (
      <AcTable
        head={[{ title: LABELS.REGISTERED, colSpan: 3 }]}
        rows={!list?.length ? null : list?.map(user => renderRow(user))}
      />
    )
  }, [
    users.registered_users,
    filters?.query,
    filters?.roleFilters?.length,
    isMobile,
  ])

  const renderInvitedUsersList = useMemo(() => {
    if (!users.invited_users) return null
    const list = applyFilters(users.invited_users, filters)
    return (
      <AcTable
        head={[{ title: LABELS.INVITED, colSpan: 3 }]}
        rows={!list?.length ? null : list?.map(user => renderRow(user))}
      />
    )
  }, [
    users.invited_users,
    filters?.query,
    filters?.roleFilters?.length,
    isMobile,
  ])

  if (users.loading) return <AcLoaderPulse />

  return (
    <AcColumn>
      <AcUserManagementFilters updateFilters={updateFilters} />
      {renderInvitedUsersList}
      {renderRegisteredUsersList}
    </AcColumn>
  )
})
