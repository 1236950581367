import { useTheme } from '@mui/material'
import { motion } from 'framer-motion'

import { AcRow } from '../../core/ac-row/ac-row'

interface IAcProgressBar {
  color: string
  delay: number
}
const AcProgressBar = ({ color, delay }: IAcProgressBar) => {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: '100%' }}
      transition={{
        delay,
        duration: 0.2,
      }}
      style={{
        width: 0,
        height: 4,
        background: color,
        borderRadius: 0,
      }}></motion.div>
  )
}

interface IAcProgress {
  steps: number
  current: number
}

export const AcProgress = ({ steps, current }: IAcProgress) => {
  const theme = useTheme()
  return (
    <AcRow>
      {Array.from({ length: steps }).map((_, i) => {
        return (
          <div
            style={{
              width: '100%',
            }}>
            <AcProgressBar
              delay={0.25 * i}
              color={current > i ? theme.palette.orange.main : '#C1D6CC'}
            />
          </div>
        )
      })}
    </AcRow>
  )
}
