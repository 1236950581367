import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'

import { ReactComponent as BuurtboerLogo } from '@assets/logos/buurtboer-logo-horizontal.svg'
import { AcRow } from '@components'

interface IAcCustomerSettingsModal {
  open: null | HTMLElement
  children: React.ReactNode
  handleClose: () => void
}

export const AcCustomerSettingsModal = ({
  open,
  children,
  handleClose,
}: IAcCustomerSettingsModal) => {
  return (
    <Dialog
      fullScreen
      open={Boolean(open)}>
      <DialogTitle
        sx={theme => ({
          bgcolor: 'green.main',
          padding: theme.spacing(4),
          marginBottom: 0,
        })}>
        <AcRow
          justifyContent="space-between"
          alignItems={'center'}>
          <BuurtboerLogo
            width={116}
            height={24}
          />
          <IconButton
            sx={{ paddingRight: 0 }}
            onClick={handleClose}>
            <CloseIcon sx={theme => ({ color: theme.palette.white.main })} />
          </IconButton>
        </AcRow>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: 'green.main', padding: '0 !important' }}>
        {children}
      </DialogContent>
    </Dialog>
  )
}
