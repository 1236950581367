import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import dayjs from 'dayjs'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcCheckboxGroup,
  AcFormInput,
  AcFormProvider,
  AcTypography,
} from '@components'
import { LABELS, WEEKDAYS_LABELS } from '@constants'
import { useStore } from '@hooks'
import { useDateHelpers } from '@hooks'
import { IWeekDay } from '@typings'

export interface IAcLuncherWeeklyAttendanceForm {
  weekIdentifier: string
  deadline: string
  confirmed: boolean
  days: IWeekDay[]
}

interface IAcLuncherWeeklyAttendanceFormInputs {
  deadline: IAcLuncherWeeklyAttendanceForm['deadline']
  confirmed: IAcLuncherWeeklyAttendanceForm['confirmed']
  initial: {
    attendance: IWeekDay[] | undefined
  }
}

const FormInputs = observer(
  ({ deadline, confirmed, initial }: IAcLuncherWeeklyAttendanceFormInputs) => {
    const { watch } = useFormContext()
    const { user } = useStore()
    const attendance = watch('attendance')

    const deadlinePassed = useMemo(() => {
      return dayjs(deadline).isBefore(dayjs())
    }, [deadline])

    const getWeekdayOptions = useMemo(() => {
      const keys = Object.entries(WEEKDAYS_LABELS)
      return keys.map(([key, value]) => ({
        id: key,
        value: key.toLowerCase(),
        title: value,
      }))
    }, [user.current])

    const renderButton = useMemo(() => {
      // if deadline is passed.
      if (deadlinePassed) return null
      if (
        confirmed &&
        JSON.stringify(initial.attendance) === JSON.stringify(attendance)
      )
        return null

      const label = confirmed ? LABELS.SUBMIT_CHANGES : LABELS.CONFIRM

      return (
        <AcButton
          loading={user.isLoading}
          fullWidth
          type="submit">
          {label}
        </AcButton>
      )
    }, [user.isLoading, confirmed, deadlinePassed, initial, attendance])

    const renderDeadline = useMemo(() => {
      if (!deadline || deadlinePassed) return null
      const { prettyDateTime } = useDateHelpers(deadline)
      return prettyDateTime
    }, [deadline])

    return (
      <>
        <AcFormInput
          register="attendance"
          Component={
            <AcCheckboxGroup
              fullWidth
              id="attendance"
              options={getWeekdayOptions}
              disabled={deadlinePassed}
            />
          }
        />
        {renderDeadline && (
          <AcTypography
            size="sm"
            textAlign="center"
            pt={2}>
            {confirmed
              ? LABELS.CONFIRM_ATTENDANCE_EDIT_DEADLINE
              : LABELS.CONFIRM_ATTENDANCE_DEADLINE}{' '}
            {renderDeadline}.
          </AcTypography>
        )}
        {renderButton}
      </>
    )
  },
)

export const AcLuncherWeeklyAttendanceForm = observer(
  ({
    weekIdentifier,
    deadline,
    confirmed,
    days,
  }: IAcLuncherWeeklyAttendanceForm) => {
    const { user, weeks, toaster } = useStore()

    const initial = {
      attendance: confirmed ? days : user.current?.profile.days,
    }

    const onSubmit = async (data: any) => {
      try {
        await user.updateWeekAttendance(weekIdentifier, data.attendance)
        toaster.success({
          content: confirmed
            ? LABELS.UPDATED_ATTENDANCE
            : LABELS.SAVED_ATTENDANCE,
          timeout: 2500,
        })
        weeks.getWeekAttendance()
      } catch (e) {
        toaster.error({
          content: 'Er is iets misgegaan, probeer opnieuw',
        })
      }
    }

    return (
      <AcFormProvider
        initial={initial}
        onSubmit={onSubmit}>
        <FormInputs
          deadline={deadline}
          confirmed={confirmed}
          initial={initial}
        />
      </AcFormProvider>
    )
  },
)
