import { ReactElement, useMemo } from 'react'
import { useLocation } from 'react-router'
import { Navigate } from 'react-router-dom'
import { NAVIGATE_TO, ROUTE_KEYS } from '@routes'
import { observer } from 'mobx-react-lite'

import { KEYS } from '@constants'
import { getFromStorage } from '@helpers'
import { useStore } from '@hooks'
import { IRole, IRoleNameEnum } from '@typings'

interface IAcPrivateRoute {
  component: ReactElement<any, any>
  roles: IRoleNameEnum[]
  checkAuth?: boolean
}

export const AcPrivateRoute = observer(
  ({ component: Component, roles, checkAuth, ...rest }: IAcPrivateRoute) => {
    const { user } = useStore()
    const location = useLocation()

    const defaultRoutePerRole = (roleName: IRoleNameEnum) => {
      switch (roleName) {
        case KEYS.OFFICE_MANAGER_ROLE:
          return NAVIGATE_TO.ADMIN_DASHBOARD
        default:
          return NAVIGATE_TO.LUNCHER_OVERVIEW
      }
    }

    const onboardingStep = () => {
      if (!user.current?.profile) {
        return null
      }
      // First step: Submit preferences
      if (!user.current?.profile.preference) {
        return NAVIGATE_TO.ONBOARDING_PREFERENCES
      }

      // Second step: Submit allergies
      if (!user.current?.profile.allergies?.length) {
        return NAVIGATE_TO.ONBOARDING_ALLERGIES
      }

      // Third step: Submit default attendance
      if (!user.current?.profile.days.length) {
        return NAVIGATE_TO.ONBOARDING_ATTENDANCE
      }
      return null
    }

    return useMemo(() => {
      // Redirect to log in if user is not authenticated.
      if (
        !getFromStorage('access_token') &&
        location.pathname !== NAVIGATE_TO.LOGIN &&
        !checkAuth
      ) {
        return (
          <Navigate
            to={{
              pathname: NAVIGATE_TO.LOGIN,
            }}
            state={{ from: location }}
            replace
          />
        )
      }

      if (!getFromStorage('access_token')) {
        return Component
      }

      if (!user.current) {
        return null
      }

      // Lunchers should complete the onboarding process.
      if (
        !user.onboarded &&
        onboardingStep() !== location.pathname &&
        user.isLuncher
      ) {
        return (
          <Navigate
            to={{
              pathname: onboardingStep() || NAVIGATE_TO.LUNCHER_OVERVIEW,
            }}
            state={{ from: location }}
            replace
          />
        )
      }

      // Move away from onboarding when onboarded
      if (
        user.current?.profile?.completed_at &&
        location.pathname.includes(ROUTE_KEYS.ONBOARDING_INDEX) &&
        !location.pathname.includes(ROUTE_KEYS.ONBOARDING_COMPLETED)
      ) {
        return (
          <Navigate
            to={{
              pathname: defaultRoutePerRole(user.watchActiveRoleName),
            }}
            state={{ from: location }}
            replace
          />
        )
      }

      const hasRole = roles.some((role: IRoleNameEnum) =>
        user.userRolesEnum?.includes(role),
      )

      // Redirect to default route if user doesn't have the required role or if the route is only for non-logged in users
      if (checkAuth || !hasRole || !roles.includes(user.watchActiveRoleName)) {
        return (
          <Navigate
            to={{
              pathname: defaultRoutePerRole(user.watchActiveRoleName),
            }}
            state={{ from: location }}
            replace
          />
        )
      }

      return Component
    }, [Component, rest, user.current])
  },
)
