import type { AxiosError } from 'axios'
import { action, computed, makeAutoObservable } from 'mobx'

import { STORE_KEYS } from '@constants'
import { extractAPIFieldErrors, removeDuplicatesIArray } from '@helpers'
import { Store } from '@stores'
import {
  IRole,
  IRoleNameEnum,
  ISinglePortalUser,
  ISinglePortalUserInvitation,
} from '@typings'

export class UsersStore {
  store: Store
  users: ISinglePortalUser[] | null
  loading: boolean

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.users = null
    this.loading = false
  }

  @computed
  get registered_users(): ISinglePortalUser[] | null {
    if (!this.users) return null
    return this.users.filter(user => user.email_verified_at)
  }

  @computed
  get invited_users(): ISinglePortalUser[] | null {
    if (!this.users) return null
    return this.users.filter(user => !user.email_verified_at)
  }

  @computed
  get user_list_roles(): IRole[] | null {
    if (!this.users) return null
    const extractedRoles = this.users.map(({ roles }) => roles)
    if (!extractedRoles?.length) return null
    const allRoles = extractedRoles.flat()
    return removeDuplicatesIArray(allRoles, 'name')
  }

  @action
  setUsers = (payload: ISinglePortalUser[]) => {
    this.users = payload
  }

  @action
  getUsersList = async () => {
    this.store.set(STORE_KEYS.USERS, 'loading', true)
    try {
      const { data } = await this.store.api.users.get_list()
      this.setUsers(data)
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USERS, 'loading', false)
    }
  }

  @action
  getSingleUser = async (id: ISinglePortalUser['id']) => {
    this.store.set(STORE_KEYS.USERS, 'loading', true)
    try {
      const { data } = await this.store.api.users.get_single(id)
      return Promise.resolve(data)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USERS, 'loading', false)
    }
  }

  @action
  updateSingleUser = async (
    id: ISinglePortalUser['id'],
    payload: ISinglePortalUser,
  ) => {
    this.store.set(STORE_KEYS.USERS, 'loading', true)
    try {
      const { data } = await this.store.api.users.update_single(id, payload)
      return Promise.resolve(data)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USERS, 'loading', false)
    }
  }

  @action
  create = async (payload: ISinglePortalUserInvitation) => {
    this.store.set(STORE_KEYS.USERS, 'loading', true)
    try {
      const { data } = await this.store.api.users.create(payload)
      return Promise.resolve(data)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USERS, 'loading', false)
    }
  }

  @action
  createInvitationLink = async (
    roles: IRoleNameEnum[],
    receives_tickets: ISinglePortalUser['receives_tickets'],
  ) => {
    const query = `?${roles.map(role => `roles[]=${role}`).join('&')}&receives_tickets=${receives_tickets}`
    try {
      const { data } = await this.store.api.users.createInvitationLink(query)
      return Promise.resolve(data)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    }
  }

  @action
  deleteSingleUser = async (id: ISinglePortalUser['id']) => {
    try {
      const { data } = await this.store.api.users.delete_single(id)
      return Promise.resolve(data)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
    }
  }

  @action
  reset = () => {
    this.users = null
  }
}
