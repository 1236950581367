import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { LABELS } from '@constants'

export const useInputCompare = (original: string, compare: string) => {
  const [isMatch, setIsMatch] = useState(true)
  const { watch, setError, clearErrors } = useFormContext()
  const inputValue = watch(original)
  const compareValue = watch(compare)

  const checkPasswordMatch = () => {
    if (!inputValue || !compareValue) return null
    if (inputValue !== compareValue) {
      setError(original, {
        type: 'custom',
        message: LABELS.PASSWORD_NO_MATCH,
      })
      setError(compare, {
        type: 'custom',
        message: LABELS.PASSWORD_NO_MATCH,
      })
    } else {
      clearErrors()
      setIsMatch(true)
    }
  }

  return {
    isMatch,
    checkPasswordMatch,
  }
}
