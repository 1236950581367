import { ReactNode } from 'react'
import { action, computed, makeAutoObservable } from 'mobx'

import { STORE_KEYS } from '@constants'
import { Store } from '@stores'

export interface IAcDialogArgs {
  content: ReactNode
  title?: string
  subTitle?: string
  maxWidth?: number
  fullScreen?: boolean
}

export class DialogStore {
  store: Store
  isOpen: boolean
  title: IAcDialogArgs['title']
  subTitle?: IAcDialogArgs['subTitle']
  content: IAcDialogArgs['content']
  maxWidth?: IAcDialogArgs['maxWidth'] | null
  fullScreen?: IAcDialogArgs['fullScreen'] | null

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.isOpen = false
    this.title = ''
    this.subTitle = ''
    this.content = null
    this.maxWidth = null
    this.fullScreen = false
  }

  @computed
  get is_open(): boolean {
    return this.isOpen
  }

  @action
  close = () => {
    this.store.set(STORE_KEYS.DIALOG, 'isOpen', false)
    setTimeout(() => {
      this.store.set(STORE_KEYS.DIALOG, 'content', null)
      this.store.set(STORE_KEYS.DIALOG, 'title', '')
      this.store.set(STORE_KEYS.DIALOG, 'maxWidth', null)
      this.store.set(STORE_KEYS.DIALOG, 'fullScreen', false)
    }, 200)
  }

  @action
  open = ({
    title,
    subTitle,
    content,
    maxWidth,
    fullScreen,
  }: IAcDialogArgs) => {
    this.store.set(STORE_KEYS.DIALOG, 'title', title)
    this.store.set(STORE_KEYS.DIALOG, 'content', content)
    this.store.set(STORE_KEYS.DIALOG, 'subTitle', subTitle)
    if (maxWidth) {
      this.store.set(STORE_KEYS.DIALOG, 'maxWidth', maxWidth)
    }
    if (fullScreen) {
      this.store.set(STORE_KEYS.DIALOG, 'fullScreen', fullScreen)
    }
    this.store.set(STORE_KEYS.DIALOG, 'isOpen', true)
  }

  @action
  reset = () => {
    this.isOpen = false
    this.title = ''
    this.subTitle = ''
    this.content = null
    this.maxWidth = null
    this.fullScreen = false
  }
}
