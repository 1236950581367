import { useMemo } from 'react'

import { AcOrderProductsPerDay } from '@components'
import { AcCard, AcColumn, AcTypography } from '@components'
import { LABELS } from '@constants'
import { IOrderDetails } from '@typings'

export const AcOrderDetailsMenuTab = (order: IOrderDetails) => {
  const renderDailyProducts = useMemo(() => {
    if (!order.menu?.days?.length) return null
    return order.menu.days.map(day => <AcOrderProductsPerDay {...day} />)
  }, [])

  return (
    <AcColumn>
      {order.menu?.salad_menu && (
        <AcCard
          cardColor="beige"
          sx={{ textAlign: 'center' }}>
          <AcTypography component="h2">{LABELS.SALAD_MENU}</AcTypography>
          <AcTypography>{order.menu?.salad_menu}</AcTypography>
        </AcCard>
      )}
      {renderDailyProducts}
    </AcColumn>
  )
}
