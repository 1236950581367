import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import { ISinglePortalUser, ISinglePortalUserInvitation } from '@typings'

export class UsersApi extends AxiosBaseApi {
  get_list = () =>
    this.api
      .get<{
        data: { data: ISinglePortalUser[] }
      }>(ENDPOINTS.USERS.INDEX)
      .then(response => response.data)
      .catch(err => err)

  get_single = (id: ISinglePortalUser['id']) =>
    this.api
      .get<{
        data: { data: ISinglePortalUser }
      }>(ENDPOINTS.USERS.SINGLE(id))
      .then(response => response.data)

  create = (payload: ISinglePortalUserInvitation) =>
    this.api
      .post<{
        data: { data: ISinglePortalUserInvitation }
      }>(ENDPOINTS.USERS.CREATE, payload)
      .then(response => response.data)

  createInvitationLink = (query: string) =>
    this.api
      .get<{
        data: { data: { link: string } }
      }>(ENDPOINTS.USERS.INVITE(query))
      .then(response => response.data)

  update_single = (id: ISinglePortalUser['id'], payload: ISinglePortalUser) =>
    this.api
      .put<{
        data: { data: ISinglePortalUser }
      }>(ENDPOINTS.USERS.SINGLE(id), payload)
      .then(response => response.data)

  delete_single = (id: ISinglePortalUser['id']) =>
    this.api
      .delete<{
        data: { data: null }
      }>(ENDPOINTS.USERS.DELETE(id))
      .then(response => response.data)
}
