import { Link, useNavigate } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import {
  AcButton,
  AcColumn,
  AcEmailInput,
  AcFormInput,
  AcFormProvider,
  AcPasswordInput,
  AcTypography,
} from '@components'
import { LABELS, REGEX } from '@constants'
import { useStore } from '@hooks'

import { TOASTERS } from '../../../constants/toasters.constants'

export const AcLoginForm = observer(() => {
  const { auth, toaster, user } = useStore()
  const navigate = useNavigate()

  const onSubmit = async (data: any) => {
    try {
      await auth.login(data)
      toaster.success(TOASTERS.AUTH.SUCCESS)
      if (user.isLuncher) {
        navigate(NAVIGATE_TO.LUNCHER_OVERVIEW)
        return
      }
      navigate(NAVIGATE_TO.ADMIN_DASHBOARD)
    } catch (error) {
      toaster.error({ content: 'E-mailadres of wachtwoord is onjuist' })
    }
  }

  return (
    <AcFormProvider
      onSubmit={onSubmit}
      flexGrow={1}
      initial={{ email: '', password: '' }}>
      <AcFormInput
        register="email"
        rules={{
          required: 'required',
          pattern: {
            value: REGEX.EMAIL,
            message: LABELS.FIELD_VALIDATION_EMAIL,
          },
        }}
        Component={
          <AcEmailInput
            label={LABELS.EMAIL_ADDRESS}
            required
          />
        }
      />
      <AcFormInput
        register="password"
        Component={
          <AcPasswordInput
            hideHelperText
            allowVisible
            label={LABELS.PASSWORD}
            required
          />
        }
      />

      <AcColumn
        spacing="xl"
        pt={3}
        flexGrow={1}>
        <AcTypography removePadding>
          <Link to={NAVIGATE_TO.REQUEST_NEW_PASSWORD}>
            {LABELS.FORGOT_PASSWORD}
          </Link>
        </AcTypography>

        <AcButton
          variant="primary"
          type="submit"
          fullWidth
          sx={{ marginTop: 'auto' }}
          loading={auth.loading}>
          {LABELS.NEXT}
        </AcButton>
      </AcColumn>
    </AcFormProvider>
  )
})
