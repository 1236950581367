import { useMemo } from 'react'
import type { IOrderDetailsMenuDay, ISimpleProduct } from '@typings'

import { AcColumn, AcTable, AcTypography } from '@components'
import { WEEKDAYS_LABELS } from '@constants'
import { makeGroupArray } from '@helpers'
import { useStore } from '@hooks'

import { AcProductRowWithRating } from '../ac-product-row-with-rating/ac-product-row-with-rating'

export const AcOrderProductsPerDay = ({
  day,
  products,
}: IOrderDetailsMenuDay) => {
  const { dialog } = useStore()

  const renderGroupedProducts = useMemo(() => {
    if (!day) return null
    if (!products?.length)
      return (
        <AcTypography removePadding>
          Geen producten beschikbaar voor{' '}
          {WEEKDAYS_LABELS[
            day.toUpperCase() as keyof typeof WEEKDAYS_LABELS
          ].toLowerCase()}
        </AcTypography>
      )
    const grouped = makeGroupArray(products, 'category')
    return grouped.map(category => (
      <AcTable
        head={[{ title: `${category.key} (${category.count})`, colSpan: 3 }]}
        rows={category.data.map((item: ISimpleProduct) => (
          <AcProductRowWithRating {...item} />
        ))}
      />
    ))
  }, [products])

  return (
    <AcColumn
      mt={4}
      spacing="xl">
      <AcTypography
        component="h3"
        removePadding
        color="green.main">
        {WEEKDAYS_LABELS[day.toUpperCase() as keyof typeof WEEKDAYS_LABELS]}
      </AcTypography>
      {renderGroupedProducts}
    </AcColumn>
  )
}
