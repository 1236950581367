import { useMemo, useState } from 'react'

import { AcIcon, AcRatingTag, AcTable, AcTypography } from '@components'
import { ICONS, LABELS } from '@constants'
import { useStore } from '@hooks'
import { AcRateProductForm } from '@partials'
import { IProductRating, ISimpleProduct } from '@typings'

export const AcProductRowWithRating = (product: ISimpleProduct) => {
  const { dialog } = useStore()
  const [rating, setRating] = useState<IProductRating['value'] | undefined>(
    product.rating?.value,
  )

  const openDialog = () => {
    dialog.open({
      title: LABELS.RATE_PRODUCT,
      content: (
        <AcRateProductForm
          product={product}
          updateRating={setRating}
        />
      ),
      maxWidth: 448,
    })
  }

  const renderRatingChip = useMemo(() => {
    if (!rating && rating !== 0) return null
    return (
      <AcTable.Cell
        align="left"
        style={{ width: 150 }}>
        <AcRatingTag rating={rating} />
      </AcTable.Cell>
    )
  }, [rating])

  return (
    <AcTable.Row
      onClick={openDialog}
      icon={<AcIcon icon={ICONS.DAY_EDIT} />}>
      <AcTable.Cell>
        <AcTypography
          component="span"
          style={{ wordBreak: 'break-all' }}>
          {product.name}
        </AcTypography>
      </AcTable.Cell>
      {renderRatingChip}
    </AcTable.Row>
  )
}
