import { motion } from 'framer-motion'

import { AcRow } from '@components'
import { THEME_COLORS } from '@constants'

const LoadingDot = ({ delay, color }: { delay: number; color: string }) => {
  return (
    <motion.div
      initial={{ scale: 1 }}
      animate={{ scale: 0.2 }}
      transition={{
        delay,
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 0.2,
        duration: 0.2,
        ease: 'easeInOut',
      }}
      style={{
        width: 12,
        height: 12,
        borderRadius: '50%',
        background: color,
      }}></motion.div>
  )
}

export const AcLoaderPulse = ({
  color = THEME_COLORS.ALERT_BRAND_GREEN,
  py,
  px,
  p,
}: {
  color?: string
  py?: number
  px?: number
  p?: number
}) => {
  return (
    <AcRow
      gap={1}
      py={py}
      px={px}
      p={p}>
      <LoadingDot
        color={color}
        delay={0}
      />
      <LoadingDot
        color={color}
        delay={0.15}
      />
      <LoadingDot
        color={color}
        delay={0.3}
      />
    </AcRow>
  )
}
