import { useState } from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Checkbox } from '@mui/material'

import {
  AcColumn,
  AcFormlabelWrapper,
  AcTypography,
  ICheckboxGroupOption,
} from '@components'

const CustomLabel = ({
  title,
  label,
}: {
  title: string | undefined
  label: string | undefined
}) => {
  return (
    <AcColumn gap={0}>
      {title && (
        <AcTypography
          bold
          component="span"
          data-name="ac-radio-title">
          {title}
        </AcTypography>
      )}
      {label && <AcTypography component="span">{label}</AcTypography>}
    </AcColumn>
  )
}

export const AcCheckboxContained = ({
  title,
  label,
  value,
  checked,
}: ICheckboxGroupOption) => {
  const [focus, setFocus] = useState(false)
  return (
    <AcFormlabelWrapper
      value={value}
      isFocused={focus}
      control={
        <Checkbox
          checked={checked}
          checkedIcon={<CheckBoxIcon />}
          sx={{ padding: 0 }}
          onFocusVisible={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      }
      label={
        <CustomLabel
          title={title}
          label={label}
        />
      }
    />
  )
}
