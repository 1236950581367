import type { AxiosError } from 'axios'
import { action, computed, makeAutoObservable } from 'mobx'

import { KEYS, LABELS, STORE_KEYS } from '@constants'
import { extractAPIFieldErrors, getFromStorage, saveToStorage } from '@helpers'
import { Store } from '@stores'
import {
  BeforeInstallPromptEvent,
  IAllergy,
  ICustomer,
  IErrorResponse,
  IPreferenceEnum,
  IProfileResponse,
  IRole,
  IRoleNameEnum,
  IUser,
  IWeekDay,
} from '@typings'

export interface IUserStore {
  user: IUser
  activeRoleName: IRoleNameEnum
}

export class UserStore {
  store: Store
  loading: boolean
  pwa_event: BeforeInstallPromptEvent | null
  user: IUserStore['user'] | null
  activeRoleName: IRoleNameEnum

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.user = null
    this.loading = false
    this.pwa_event = null
    this.activeRoleName =
      (getFromStorage('activeRoleName') as IRoleNameEnum) || KEYS.LUNCHER_ROLE
  }

  @computed
  get isBasicSubscription(): boolean {
    return this.user?.customer?.type === 'basic'
  }

  @computed
  get hasApp(): boolean {
    if (this.user?.customer?.type === 'basic') return false
    return this.user?.customer?.has_app ? true : false
  }

  @computed
  get watchUserRoles(): IRole[] | undefined {
    return this.user?.roles
  }

  @computed
  get userRolesEnum(): IRoleNameEnum[] {
    return this.user?.roles?.map(role => role.name as IRoleNameEnum) || []
  }

  @computed
  get isLuncher(): boolean {
    return !!this.user?.roles?.some(role => role.name === KEYS.LUNCHER_ROLE)
  }

  @computed
  get onboarded(): boolean {
    return !!this.user?.profile?.completed_at
  }

  @computed
  get isLoading(): boolean {
    return this.loading
  }

  @computed
  get watchActiveRoleName(): IRoleNameEnum {
    return this.activeRoleName
  }

  @computed
  get watchPWAEvent(): BeforeInstallPromptEvent | null {
    return this.pwa_event
  }

  @computed
  get current(): IUser | null {
    return this.user
  }

  @computed
  get customer(): ICustomer | undefined {
    return this.user?.customer
  }

  @computed
  get hasSoup() {
    return !!this.user?.customer?.has_soup
  }

  @computed
  get hasSalad() {
    return !!this.user?.customer?.has_salad
  }

  @computed
  get isOfficeManager(): boolean {
    return (
      this.user?.roles?.some(role => role.name === KEYS.OFFICE_MANAGER_ROLE) ||
      false
    )
  }

  @computed
  get has_saved_pwa_prompt() {
    return this.pwa_event
  }

  @action
  attemptPWAInstall = async () => {
    if (this.pwa_event) {
      this.pwa_event.prompt()
      const { outcome } = await this.pwa_event.userChoice
      // The deferredPrompt can only be used once.
      this.store.set(STORE_KEYS.USER, 'pwa_event', null)
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt.')
      } else if (outcome === 'dismissed') {
        console.log('User dismissed the install prompt')
      }
    }
  }

  @action
  saveDeferredPWAEvent = (event: BeforeInstallPromptEvent) => {
    this.store.set(STORE_KEYS.USER, 'pwa_event', event)
  }

  @action
  whoAmI = async () => {
    try {
      this.store.set(STORE_KEYS.USER, 'loading', true)
      const data = await this.store.api.user.whoAmI()
      this.setUser(data)
    } catch (e) {
      this.store.auth.postLogout()
      const err: IErrorResponse = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USER, 'loading', false)
    }
  }

  @action
  setUser = (user: IProfileResponse) => {
    this.store.set(STORE_KEYS.USER, 'user', user)
    if (!this.isLuncher) {
      this.setRole(KEYS.OFFICE_MANAGER_ROLE)
    }
  }

  @action
  setRole = (roleName: IRoleNameEnum) => {
    // Don't show toaster on initial page load
    if (this.store.user.activeRoleName !== roleName) {
      this.store.toaster.warning({
        content: `Je gebruikt de app nu als ${LABELS[roleName.toUpperCase() as keyof typeof LABELS]}`,
      })
    }
    this.store.set(STORE_KEYS.USER, 'activeRoleName', roleName)
    saveToStorage('activeRoleName', roleName)
  }

  @action
  patchAllergies = async (allergies: IAllergy['id'][]) => {
    this.store.set(STORE_KEYS.USER, 'loading', true)
    try {
      const data = await this.store.api.user.patchAllergies(allergies)
      this.setUser(data)
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USER, 'loading', false)
    }
  }

  @action
  patchDefaultAttendance = async (days: any) => {
    this.store.set(STORE_KEYS.USER, 'loading', true)
    try {
      const data = await this.store.api.user.patchDefaultAttendance(days)
      this.setUser(data)
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USER, 'loading', false)
    }
  }

  @action
  patchPreference = async (preference: IPreferenceEnum) => {
    this.store.set(STORE_KEYS.USER, 'loading', true)
    try {
      const data = await this.store.api.user.patchPreference(preference)
      this.setUser(data)
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USER, 'loading', false)
    }
  }

  @action
  updateWeekAttendance = async (weekIdentifier: any, days: IWeekDay[]) => {
    this.store.set(STORE_KEYS.USER, 'loading', true)
    try {
      await this.store.api.user.postWeeklyAttendance({
        weekIdentifier,
        days,
      })
      return Promise.resolve()
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.store.set(STORE_KEYS.USER, 'loading', false)
    }
  }

  @action
  reset = () => {
    this.user = null
    this.loading = false
    this.pwa_event = null
    this.activeRoleName = KEYS.LUNCHER_ROLE
  }

  @action
  getCustomerLunchDayCount = (day: IWeekDay): number => {
    const foundDay = this.user?.customer?.lunch_days?.find(lunchDay => {
      return lunchDay.hasOwnProperty(day)
    })

    if (!foundDay) {
      return 0
    }

    return Object.values(foundDay)[0]
  }
}
