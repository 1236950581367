import { useMemo } from 'react'
import { BoxProps } from '@mui/material'

import { AcBox } from '@components'
import { GAP_SPACING } from '@constants'
import { useBreakpoints } from '@hooks'

type Omitted = 'display' | 'flexDirection'

export interface AcRow extends Omit<BoxProps, Omitted> {
  spacing?: 'sm' | 'md' | 'lg'
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
}

export const AcRow = ({
  spacing = 'md',
  children,
  justifyContent,
  ...props
}: AcRow) => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const gapSpacing = useMemo(() => {
    if (isMobile) return GAP_SPACING.MOBILE
    if (isTablet) return GAP_SPACING.TABLET
    return GAP_SPACING.DESKTOP
  }, [isMobile, isTablet, isDesktop])

  return (
    <AcBox
      display="flex"
      gap={gapSpacing[spacing]}
      sx={{
        justifyContent,
        ...props.sx,
      }}
      {...props}>
      {children}
    </AcBox>
  )
}
