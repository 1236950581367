export const WEEKDAYS_LABELS = {
  MONDAY: 'Maandag',
  TUESDAY: 'Dinsdag',
  WEDNESDAY: 'Woensdag',
  THURSDAY: 'Donderdag',
  FRIDAY: 'Vrijdag',
}

export const WEEKDAYS_LABELS_SHORT = {
  MONDAY: 'Ma',
  TUESDAY: 'Di',
  WEDNESDAY: 'Wo',
  THURSDAY: 'Do',
  FRIDAY: 'Vr',
}

export const WEEKDAYS_KEYS = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
}
