export const CURRENT_ORDER_KEYS = {
  WEEK: 'week',
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SALAD: 'salad',
  SOUP: 'soup',
  COMMENT: 'comment',
  NO_COMMENT: 'no_comment',
  EXTRAS: 'extras',
}
