import { styled, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material'
import { calcFontSize } from 'src/theme/typography.theme'

const TAB_HEIGHT = 40

export const Tabs = styled(MuiTabs)(({ theme }) => ({
  border: `1px solid ${theme.palette['light-green'].main}`,
  borderRadius: 30,
  padding: 3,
  height: TAB_HEIGHT,
  minHeight: TAB_HEIGHT,
  width: 'auto !important',
  transform: 'none !important',
  '.MuiTabs-indicator': {
    height: '100%',
    borderRadius: 25,
    backgroundColor: theme.palette.orange.main,
  },
}))

export const Tab = styled(MuiTab)(({ theme }) => ({
  color: theme.palette.white.main,
  fontWeight: theme.typography.fontWeightRegular,
  flexGrow: 1,
  height: 30,
  minHeight: 30,
  padding: 0,
  fontSize: calcFontSize(14),
  '&.Mui-selected': {
    color: theme.palette.white.main,
  },
}))
