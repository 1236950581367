import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { AcTypography } from '@components'

import { AcTableCell } from '../ac-table-cell/ac-table-cell'
import { AcTableRow } from '../ac-table-row/ac-table-row'

export interface IAcTableHeadCell {
  title: string
  colSpan: number
}

export interface IAcTable {
  head?: string[] | IAcTableHeadCell[]
  rows: React.ReactNode[] | null
}

const AcTable = ({ head, rows }: IAcTable) => {
  return (
    <Table>
      {head?.length !== 0 && (
        <TableHead>
          <TableRow>
            {head?.map(item => (
              <TableCell colSpan={typeof item === 'string' ? 1 : item.colSpan}>
                <AcTypography
                  color="green.main"
                  component="h3"
                  removePadding>
                  {typeof item === 'string' ? item : item.title}
                </AcTypography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>{rows}</TableBody>
    </Table>
  )
}

AcTable.Row = AcTableRow
AcTable.Cell = AcTableCell

export { AcTable }
