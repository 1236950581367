import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { KEYS, LABELS } from '@constants'
import { useStore } from '@hooks'
import { IRoleNameEnum } from '@typings'

import { Tab, Tabs } from './StyledRoleSwitcher'

const ROLES = [KEYS.LUNCHER_ROLE, KEYS.OFFICE_MANAGER_ROLE]

export const AcRoleSwitcher = observer(() => {
  const { user } = useStore()

  const handleChange = (e: any) => {
    const newRole: IRoleNameEnum = e.target?.dataset?.role
    user.setRole(newRole)
  }

  if (!user.watchUserRoles?.length) return null
  if (Array.isArray(user.watchUserRoles) && user.watchUserRoles.length === 1)
    return null

  const renderTabs = useMemo(() => {
    return (
      <Tabs
        value={user.watchUserRoles?.findIndex(
          role => role.name === user.watchActiveRoleName,
        )}
        onChange={handleChange}
        aria-label={LABELS.SWITCH_ROLE}>
        {user.watchUserRoles?.map(role => (
          <Tab
            data-role={role.name as IRoleNameEnum}
            label={LABELS[role.name.toUpperCase() as keyof typeof LABELS]}
          />
        ))}
      </Tabs>
    )
  }, [user.watchUserRoles, user.watchActiveRoleName])

  return renderTabs
})
