import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { AcContainer, AcTabs, AcTypography } from '@components'
import { LABELS } from '@constants'
import { getWeeks } from '@helpers'
import { useGreeting, useStore } from '@hooks'
import { AcDashboardWeekTab } from '@partials'

export const DashboardView = observer(() => {
  const greeting = useGreeting()

  const { user } = useStore()

  const tabs = useMemo(() => {
    return getWeeks(4).map(week => ({
      title: `${LABELS.WEEK} ${week.split('-')[0]}`,
      content: <AcDashboardWeekTab week={week} />,
    }))
  }, [])

  if (!user.current) {
    return null
  }

  return (
    <AcContainer wide>
      <AcTypography component="h1">
        {greeting} {user?.current?.first_name}
      </AcTypography>
      <AcTabs tabs={tabs} />
    </AcContainer>
  )
})
