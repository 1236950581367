// spacing={0} is 0px
// spacing={1} is 4px
// spacing={2} is 8px
// Etc..
export const SPACING = [0, 4, 8, 12, 16, 24, 32, 48, 64, 80, 96]

export const GAP_SPACING = {
  DESKTOP: {
    sm: 3,
    md: 4,
    lg: 5,
    xl: 6,
  },
  TABLET: {
    sm: 3,
    md: 4,
    lg: 5,
    xl: 6,
  },
  MOBILE: {
    sm: 3,
    md: 4,
    lg: 5,
    xl: 6,
  },
}

export const TYPOGRAPHY_SPACING = {
  DESKTOP: {
    sm: 2,
    md: 4,
    lg: 6,
  },
  TABLET: {
    sm: 2,
    md: 4,
    lg: 6,
  },
  MOBILE: {
    sm: 2,
    md: 3,
    lg: 6,
  },
}

export const CARD_PADDING = {
  DESKTOP: {
    sm: 2,
    md: 5,
    lg: 6,
  },
  TABLET: {
    sm: 2,
    md: 4,
    lg: 5,
  },
  MOBILE: {
    sm: 2,
    md: 4,
    lg: 4,
  },
}
