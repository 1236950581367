import { AcAnimatedFade, AcAnimatedSpring } from '@animations'
import { observer } from 'mobx-react-lite'

import { AcAttendanceSwiper, AcColumn, AcTypography } from '@components'
import { LABELS } from '@constants'
import { useGreeting, useStore } from '@hooks'

export const LuncherAttendanceView = observer(() => {
  const { user } = useStore()

  const greeting = useGreeting()

  if (!user.current) return null
  return (
    <AcColumn
      mb={8}
      sx={{ overflow: 'hidden' }}>
      <AcAnimatedFade>
        <AcColumn
          gap={0}
          textAlign={'center'}>
          <AcTypography
            component="h1"
            color="white.main"
            removePadding>
            {greeting} {user.current?.first_name}
          </AcTypography>
          <AcTypography
            color="white.main"
            size="lg">
            {LABELS.WHEN_WILL_YOU_ATTEND}
          </AcTypography>
        </AcColumn>
      </AcAnimatedFade>
      <AcAnimatedSpring>
        <AcAttendanceSwiper />
      </AcAnimatedSpring>
    </AcColumn>
  )
})
