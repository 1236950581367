import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { NAVIGATE_TO } from '@routes'
import { observer } from 'mobx-react-lite'

import {
  AcBox,
  AcButton,
  AcCheckboxGroup,
  AcColumn,
  AcFormInput,
  AcFormProvider,
  AcIcon,
  AcRow,
  AcTypography,
} from '@components'
import { ICONS, LABELS } from '@constants'
import { useStore } from '@hooks'
import { IErrorResponse } from '@typings'
import { IAllergy } from '@typings'

export interface IAcLuncherAllergiesForm {
  nextStep: boolean
}

const NO_ALLERGIES = [
  {
    id: 1,
    title: LABELS.ALLERGIES_NONE,
    value: LABELS.ALLERGIES_NONE,
  },
]

const FormInputs = observer(({ nextStep }: IAcLuncherAllergiesForm) => {
  const navigate = useNavigate()
  const { watch, setValue } = useFormContext()
  const { products, user, toaster } = useStore()

  const watchNoAllergy = watch('no_allergy')
  const watchAllergies = watch('allergies')

  useEffect(() => {
    if (watchNoAllergy?.length) {
      setValue('allergies', [])
    }
  }, [watchNoAllergy])

  useEffect(() => {
    if (watchAllergies?.length && watchNoAllergy.length) {
      setValue('no_allergy', [])
    }
  }, [watchAllergies])

  const getAllergies = async () => {
    try {
      await products.getAllergies()
    } catch (e) {
      toaster.error({
        content: (e as any).message,
      })
    }
  }

  useEffect(() => {
    getAllergies()
  }, [])

  const allergyOptions = useMemo(() => {
    if (products.current_allergies?.length) {
      return products.current_allergies.map(({ id, name }) => ({
        id,
        value: id.toString(),
        title: name,
        checked: user.current?.profile?.allergies?.some(
          (allergy: IAllergy) => allergy.id === id,
        ),
      }))
    }

    return []
  }, [products.current_allergies])

  const allergiesValues = useMemo(() => {
    return (
      user.current?.profile.allergies?.map(allergy => allergy.id.toString()) ||
      []
    )
  }, [user.current?.profile.allergies])

  if (!products.current_allergies?.length) {
    return null
  }

  return (
    <AcColumn>
      <AcFormInput
        lastChild
        register="no_allergy"
        Component={
          <AcCheckboxGroup
            fullWidth
            id="no_allergy"
            options={NO_ALLERGIES}
          />
        }
      />
      <AcTypography
        textAlign="center"
        bold
        color="green.main"
        size="sm"
        removePadding>
        {LABELS.OR}
      </AcTypography>
      <AcFormInput
        register="allergies"
        Component={
          <AcCheckboxGroup
            fullWidth
            id="allergies"
            options={allergyOptions}
            value={allergiesValues}
          />
        }
      />
      <AcBox>
        <AcTypography
          bold
          color="green.main"
          padding="sm">
          {LABELS.ALLERGIES_NOT_LISTED}
        </AcTypography>
        <AcTypography
          size="sm"
          color="black.main"
          padding="lg">
          {LABELS.CONTACT_OFFICE_MANAGER}
        </AcTypography>
      </AcBox>
      {!nextStep ? (
        <AcButton
          loading={user.loading}
          type="submit"
          fullWidth
          sx={{ marginTop: 'auto' }}>
          {LABELS.CONFIRM}
        </AcButton>
      ) : (
        <AcRow
          alignItems="center"
          justifyContent="space-between"
          marginTop="auto">
          <AcButton
            type="button"
            onClick={() => navigate(-1)}
            variant="text"
            startIcon={<AcIcon icon={ICONS.BACK} />}>
            {LABELS.BACK}
          </AcButton>
          <AcButton
            loading={user.loading}
            type="submit"
            endIcon={<AcIcon icon={ICONS.NEXT} />}>
            {LABELS.NEXT}
          </AcButton>
        </AcRow>
      )}
    </AcColumn>
  )
})

export const AcLuncherAllergiesForm = observer(
  ({ nextStep }: IAcLuncherAllergiesForm) => {
    const navigate = useNavigate()
    const { user, dialog, toaster } = useStore()
    const [errors, setErrors] = useState<any>(null)
    const allergiesValues = useMemo(() => {
      return (
        user.current?.profile.allergies?.map(allergy =>
          allergy.id.toString(),
        ) || []
      )
    }, [user.current?.profile])

    const initial = {
      allergies: allergiesValues,
      no_allergy: !allergiesValues?.length ? ['Geen allergie'] : [],
    }

    const onSubmit = async (data: any) => {
      try {
        await user.patchAllergies(data)
        if (nextStep) {
          navigate(NAVIGATE_TO.ONBOARDING_ATTENDANCE)
          return
        }
        toaster.success({ content: LABELS.SAVED_ALLERGIES })
        dialog.close()
      } catch (error) {
        const err = error as IErrorResponse
        if (err.type === 'axios') {
          setErrors(err.errorFields)
          toaster.error({ content: err.message })
        } else {
          toaster.error({ content: err.message })
        }
      }
    }

    return (
      <AcFormProvider
        initial={initial}
        onSubmit={onSubmit}
        errors={errors}
        flexGrow={1}>
        <FormInputs nextStep={nextStep} />
      </AcFormProvider>
    )
  },
)
