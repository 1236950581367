import { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { AcButton, AcColumn, AcRow, AcTypography } from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks'
import { ISinglePortalUser } from '@typings'

interface IAcDeleteUserModal {
  userId: ISinglePortalUser['id']
}

export const AcDeleteUserModal = observer(({ userId }: IAcDeleteUserModal) => {
  const { dialog, toaster, users } = useStore()
  const [loading, setLoading] = useState(false)

  const handleCancel = (e: any) => {
    e.preventDefault()
    dialog.close()
  }

  const handleConfirm = async (e: any) => {
    e.preventDefault()
    if (!userId) return null
    setLoading(true)

    try {
      await users.deleteSingleUser(userId)
      toaster.success({
        title: LABELS.USER_DELETED,
        content: LABELS.USER_DELETED_MESSAGE,
      })
      dialog.close()
      users.getUsersList()
    } catch (error: any) {
      toaster.error({
        title: 'Er is is mis geggaan',
        content: error?.message,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <AcColumn>
      <AcTypography>{LABELS.DELETE_USER_CONFIRM}</AcTypography>
      <AcRow
        justifyContent="space-between"
        alignItems={'center'}>
        <AcButton
          variant="text"
          disabled={loading}
          type="button"
          onClick={handleCancel}>
          {LABELS.CANCEL}
        </AcButton>
        <AcButton
          loading={loading}
          type="button"
          onClick={handleConfirm}>
          {LABELS.CONFIRM}
        </AcButton>
      </AcRow>
    </AcColumn>
  )
})
